import { Select, Spin, Layout, Card, Table, Space, Input, Modal, Drawer, PageHeader, Form, Button } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Addschedule from "./addschedule";
import AddSchema from "./addschema";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
  PlusCircleTwoTone,
  DeleteTwoTone,
  LoginOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Content } = Layout;
const { Column } = Table;
const { Search } = Input;

const doctypearray = ["SCHEDULE", "PLAY_OFF", "INSIGHTS"];
function Schedule() {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(true);
  const [rowInfo, setRowInfo] = useState("");
  const [StatusVisible, setStatusVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showAddSchema, setShowAddSchema] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [tempSchedule, setTempSchedule] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  const handleJson = () => {
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setVisible(true);
  };
  const addnewSchema = () => {
    setShowAddSchema(true);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const downloadocument = async (info) => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/schedule/get-doc/data/v2.0?appId=${currentTournamentDetails.shortCode}-ops&docId=${info.docId}`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        downloadFile(response.data.data.doc_data, info.docId);
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };

  const changeRowStatus = async () => {
    setStatusVisible(false);
    setLoaded(true);
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/schedule/toggle-status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&docId=${rowInfo.docId}`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      onClose();
      if (response.data.status === "SUCCESS") {
        Modal.success({
          content: response.data.data.message,
          onOk() {
            fetchSchedule();
          }
        });
      }
      else if (response.data.status === "FAIL") {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
      else {
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
        });
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
        });
      })
    setLoaded(false);
  }

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setShowAddSchema(false);
    setStatusVisible(false);
    setRowInfo("");
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchSchedule = async () => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/schedule/all-doc/list/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setTempSchedule(response.data.data.docList);
        setSchedule(response.data.data.docList);
        setLoaded(false);
      }
      else {
        setLoaded(false);
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
  };

  const onSearch = (e) => {
    setLoaded(true);
    if (e.target.value !== "") {
      let filteredData = tempSchedule.find(obj => `${obj.docId}` === e.target.value);
      let tempArr = [];
      tempArr.push(filteredData);
      if (tempArr[0].docId) {
        setSchedule(tempArr);
      }
      setLoaded(false);
  } else {
      fetchSchedule();
  }
  };

  const changeStatus = async (rowData) => {
    setRowInfo(rowData)
    setStatusVisible(true);
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchSchedule();
  }, [loaded]);


  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="schedule" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Schedule"
                  extra={
                    <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                      <Search placeholder="Search by Document Id" onChange={onSearch} enterButton />
                      {/* <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                      <a onClick={() => addnew()}>
                        <span className="addschedule">Add Schedule</span>
                      </a>
                      <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                      <a onClick={() => addnewSchema()}>
                        <span className="addschedule">Add Schema</span>
                      </a> */}
                    </Space>
                  }
                />
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  dataSource={schedule}
                  scroll={{ y: "340" }}
                >
                  <Column title="Document ID" dataIndex="docId" key="docId" />
                  <Column title="Doc Type" dataIndex="docType" key="docType" />
                  <Column title="Tournament" dataIndex="tournament" key="tournament" />
                  <Column title="Year" dataIndex="year" key="year" />
                  <Column title="Season Type" dataIndex="seasonType" key="seasonType" />
                  <Column title="Status" dataIndex="active" key="active"
                    render={(text, record) => (
                      <Space size="middle">
                        {text == true ? (
                          <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                        ) : (
                          <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                        )}
                      </Space>
                    )}
                  />
                  <Column
                    title="Quick Actions"
                    key="documentID"
                    render={(documentID) => (
                      <Space size="middle">
                        <LoginOutlined onClick={() => changeStatus(documentID)} title="Change Document Status" style={{ fontSize: "18px", color: "#08c" }} />
                        <a onClick={() => downloadocument(documentID)}>
                          <img src="images/download.svg"></img>{" "}
                        </a>
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>

            <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              Are you sure you want to Notification?
            </Modal>
            <Modal title="Json" visible={jsonviewer} onOk={handleJson} onCancel={jsoncancel}>
              <ReactJson src={jsondata} />
            </Modal>

            <Modal title="Status" onCancel={onClose} visible={StatusVisible}
              footer={[<>  <Button key="submit" type="primary" onClick={changeRowStatus}>
                Ok
              </Button>
                <Button key="submit" type="primary" onClick={onClose}>
                  Cancel
                </Button></>
              ]}
            >
              Are you sure you want to Change the Document Status?
            </Modal>

            <Drawer title="Add New Tournament" placement="right" onClose={onClose} visible={visible}>
              <Addschedule scheduledata={schedule} teams={teams} doctypearray={doctypearray} schedulereload={fetchSchedule} onClose={onClose} form={form} />
            </Drawer>
            <Drawer title="Add New Schema" placement="right" onClose={onClose} visible={showAddSchema}>
              <AddSchema scheduledata={schedule} teams={teams} doctypearray={doctypearray} schedulereload={fetchSchedule} onClose={onClose} form={form} />
            </Drawer>
            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Schedule;
