import { collection, getDocs, query, where } from "firebase/firestore";
import { Select, Layout, Card, Col, Input, Table, Row, Tag, Space, Drawer, PageHeader, Spin, List, Modal } from "antd";
import { db, currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import "antd/dist/antd.min.css";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

import { EyeOutlined } from "@ant-design/icons";
import localStore from "../utils/services";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function UserTransactions() {
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);

    //const [scheduleData, setScheduleData] = useState([]);
    const [transactionsData, setTransactionsData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [transactionsBet, setTransactionsBet] = useState([]);
    const [transactionsDataDup, setTransactionsDetailsDup] = useState([]);
    const [tournamentValue, setTournamentsValue] = useState(currentTournamentDetails.shortName);
    const [seasonyearValue, setSeasonyearValue] = useState(2023);
    const [seasontypeValue, setSeasontypeValue] = useState("REGULAR");

    //const [isModalVisible, setIsModalVisible] = useState(false);
    //const [modaltitle, setModaltitle] = useState("");

    const [dbTournamentList, setDbTournamentList] = useState([]);
    const [dbSeasonList, setDbSeasonList] = useState([]);
    const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

    const [playersSquad, setPlayersSquad] = useState([]);
    const [matchPlayers, setMatchPlayers] = useState([]);

    const [transactionsDetails, setTransactionsDetails] = useState([]);

    const sportConfigSetting = () => {
        let config11 = localStorage.getItem("sportsList")
        let config12 = config11.split(",");
        setDbTournamentList(config12);

        let config21 = localStorage.getItem("sportYearsList")
        let config22 = config21.split(",");
        setDbSeasonList(config22);

        let config31 = localStorage.getItem("sportSeasonsList")
        let config32 = config31.split(",");
        setDbSeasonTypeList(config32);
    }

    const showDrawer = async (matchid) => {

        const _transactions = query(collection(db, "schedule"), where("tournament", "==", matchid.tournament), where("docType", "==", "PLAY_OFF"),
            where("year", "==", matchid.year));
        const _snapshot = await getDocs(_transactions);

        const resultArray = [];
        const resultMatch = [];
        const result = _snapshot.docs.map((doc) => {
            resultArray.push(doc.data());
            resultMatch.push({ documentID: doc.id });
        });

        let data = [];
        let playOffGroupOrder = [];
        for (let i = 0; i < resultArray[0].stages.length; i++) {

            for (let k = 0; k < resultArray[0].stages[i].groups.length; k++) {
                let groupName = resultArray[0].stages[i].groups[k].group;
                let stageName = resultArray[0].stages[i].stageName;
                let filterResults = matchid.picks.filter(list => list.groupName === groupName && list.stageName === stageName);
                if (filterResults.length > 0)
                    data.push({ stage: stageName, group: groupName, groups: filterResults });
            }
            if (i === 0 && resultArray[0].stages[i].stageMatchOrder) {
                playOffGroupOrder = resultArray[0].stages[i].stageMatchOrder;
            }
        }
        for (let i = 0; i < playOffGroupOrder.length; i++) {
            let groupName = playOffGroupOrder[i];
            let stageName = "Play Off";
            let filterResults = matchid.picks.filter(list => list.groupName === groupName && list.stageName === stageName);
            if (filterResults.length > 0)
                data.push({ stage: stageName, group: groupName, groups: filterResults });
        }
        setDataFilter(data);
        setVisible(true);
    };

    const fetchTransactionsDetails = async () => {
        //     const _snapshot = await getDocs(_transactions);
        //     const _resultArray = [];
        //     let _tournaments = [];
        //     let _year = [];

        //     _snapshot.docs.forEach(function (item, index) {
        //         let _data = item.data();
        //         let _obj = { ..._data };

        //         if (_data && _data.gameEndTs) {
        //             let _time = String(_data.gameEndTs.seconds).padEnd(13, "0");
        //             _obj.gameEndTs = String(new Date(Number(_time)));
        //             _obj.key = index;
        //             _resultArray.push(_obj);

        //             if (_year.indexOf(_obj.year) === -1) _year.push(_obj.year);
        //             if (_tournaments.indexOf(_obj.tournament) === -1) _tournaments.push(_obj.tournament);
        //         }
        //     });
        //     if (_tournaments.length > 1) {
        //         _tournaments = ["All Tournaments", ..._tournaments];
        //     }
        //     setDbTournamentList(_tournaments);
        //     var filtered = _year.filter(function(x) {
        //         return x !== undefined;
        //    });
        //     setDbSeasonList(filtered);
        //     setTransactionsData(_resultArray);

        //     setTransactionsDetails(_resultArray);
        //     setTransactionsDetailsDup(_resultArray);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/quiz/trans-list/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tournamentValue}&season=${seasonyearValue}&seasonType=${seasontypeValue}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setTransactionsData(response.data.data.transList);
                setTransactionsDetails(response.data.data.transList);
                setTransactionsDetailsDup(response.data.data.transList);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };


    const handleTournament = (e) => {
        if (e) {
            setTournamentsValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == e;
            });
            setTransactionsDetails(_filteredData);
            // let _year = [];
            // _filteredData.forEach(function (item) {
            //     if (_year.indexOf(item.year) === -1) {
            //         _year.push(item.year);
            //     }
            // });
            // if (_year.length > 1) {
            //     _year = ["All Years", ..._year];
            // }
            // var filtered = _year.filter(function (x) {
            //     return x !== undefined;
            // });
            // setDbSeasonList(filtered);
            // setSeasonyearValue(_year[0]);
        }
    };
    const handleSeasonYear = (e) => {
        if (e) {
            setSeasonyearValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == tournamentValue && item.season == e && item.seasonType == seasonyearValue;
            });
            setTransactionsDetails(_filteredData);
        }
    };
    const handleSeasonType = (e) => {
        if (e) {
            setSeasontypeValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == tournamentValue && item.season == seasonyearValue && item.seasonType == e;
            });
            setTransactionsDetails(_filteredData);
        }
    };
    const frameMatchPlayers = (obj) => {
        let _playersList = [];
        for (let i = 0; i < obj.playerlist.length; i++) {
            let _playersLists = playersSquad.filter((list) => list.mIdPId == obj.playerlist[i].mIdPId)
            if (_playersLists.length > 0) {
                _playersList.push(_playersLists[0])
            }
        }
        setMatchPlayers(_playersList);
    }
    const sortArray = (type) => {
        const types = {
            matchId: "matchId",
        };
        const sortProperty = types[type];
        const sorted = [...transactionsDetails].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });
        setTransactionsDetails(sorted);
    };
    const handleTableChange = (pagination, filters, sorter) => {
        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setTransactionsDetails(transactionsDetails.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setTransactionsDetails(transactionsDetails.reverse());
        }
    };
    const fetchSearch = async () => {
        const filteredData = transactionsDataDup
            .filter(function (item) {
                return item.tournament == tournamentValue && item.season == seasonyearValue && item.seasonType == seasontypeValue;
            })
            .map(function (item) {
                return item;
            });
        setTransactionsDetails(filteredData);
    };
    const onSearch = (e) => {

        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = transactionsDataDup.filter((item) => {
                return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
            });
            setTransactionsDetails(filteredData);
        } else {
            fetchSearch();
        }
    };
    const onClose = () => {
        setVisible(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);

    useEffect(async () => {
        sportConfigSetting();
        let _config = await localStore.getItem();
        fetchTransactionsDetails();
        sportConfigSetting();
        // fetchSchedule();
    }, [loaded]);

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="user_transactions" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Quiz Transactions"
                                    extra={
                                        <Space key="p1" direction="horizontal">
                                            <Search placeholder="Search by UID" onChange={onSearch} enterButton />{" "}
                                            <Select size="default" value={tournamentValue} style={{ width: "200px" }} onChange={(e) => handleTournament(e)}>
                                                {dbTournamentList.map((item, index) => (
                                                    <Option key={index} value={item}>
                                                        {item}{" "}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select size="default" value={seasonyearValue} style={{ width: "200px" }} onChange={(e) => handleSeasonYear(e)}>
                                                {dbSeasonList.map((name, pos) => {
                                                    return (
                                                        <Option key={pos} value={name}>
                                                            {name}{" "}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            <Select size="default" value={seasontypeValue} style={{ width: "200px" }} onChange={(e) => handleSeasonType(e)}>
                                                {dbSeasonTypeList.map((name, pos) => {
                                                    return (
                                                        <Option key={pos} value={name}>
                                                            {name}{" "}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Space>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={transactionsDetails}
                                    onChange={handleTableChange}
                                    scroll={{ y: "250" }}
                                >
                                    <Column title="Game Type" dataIndex="gameType" key="gameType" sorter="true" />
                                    <Column title="Tournament" dataIndex="tournament" key="tournament" sorter="true" />
                                    <Column title="Season" dataIndex="season" key="season" sorter="true" />
                                    <Column title="Season Type" dataIndex="seasonType" key="seasonType" sorter="true" />
                                    <Column title="UID" dataIndex="uid" key="uid" sorter="true" />
                                    <Column
                                        title="Quick Actions"
                                        key="matchId"
                                        render={(matchId, index) => (
                                            <Space key={index} size="middle">
                                                {matchId !== undefined ? (
                                                    <a onClick={() => showDrawer(matchId)}>
                                                        <EyeOutlined style={{ fontSize: "18px", color: "#08c" }} /> View Picks
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>

                        <Drawer title="List" placement="right" onClose={onClose} visible={visible} size="large">
                            <h2> {dataFilter.length > 0 ? <> <div>Stage: {dataFilter[0].stage}</div>
                                <div>Group: {dataFilter[0].group}</div> </>
                                : ""}</h2>
                            <Row gutter={16}>
                                {dataFilter.length > 0 ? dataFilter[0].groups.length > 0 ? dataFilter[0].groups.map((list, index) => {

                                    return (
                                        <Col span={6}>
                                            <Card bordered={false} style={{ backgroundColor: "rgb(241, 241, 241)" }}>
                                                <div style={{ fontWeight: "500" }}>{list.team}</div>
                                            </Card>
                                        </Col>
                                    )

                                }) : "No list Found" : "No list Found"}
                            </Row>
                        </Drawer>

                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default UserTransactions;
