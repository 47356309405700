import * as Firebase from "firebase/app";
import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";

import { getAuth, signInWithEmailAndPassword, signOut, setPersistence, browserSessionPersistence } from "firebase/auth";

let currentTournamentName = "NFL";
let currentEnvironment = "PROD";
let currentTournamentDetails;
let firebaseConfig;
if (currentTournamentName == "NFL") {
  currentTournamentDetails = {
    fullName : "Fantasy Cafe",
    joinFullNameLowercase : "fantasycafe",
    joinFullNameCapitalize : "Fantasycafe",
    pathFullName : "FantasyCafe",
    halfNameCapitalize : "Fantasy",
    shortName : currentTournamentName,
    shortCode : "fc",
    shortCodeCapitalize: "Fc",
    logo : "images/fantasy_cafe.svg",
    sidebarLogo : "images/fantasycafeSmall.svg",
    majorUsedDocId : "fantasycafe"
  }
  if(currentEnvironment == "DEV") {
    currentTournamentDetails.version = "1.0.39.2";
    firebaseConfig = {
      apiKey: "AIzaSyCk19gSsUM4Cr6SoMIDmADHX2TR2hxGIE4",
      authDomain: "fantasy-cafe-dev.firebaseapp.com",
      projectId: "fantasy-cafe-dev",
      storageBucket: "fantasy-cafe-dev.appspot.com",
      messagingSenderId: "97081413506",
      appId: "1:97081413506:web:3276b1fbfd5dc193cf099b",
      measurementId: "G-KDXS3WR36Y"
    };
  } else {
    currentTournamentDetails.version = "1.0.40";
    firebaseConfig = {
      apiKey: "AIzaSyAxCeaYmTlkM0xKM-O6P_XmLFpAeSQE8z4",
        authDomain: "fantasy-cafe.firebaseapp.com",
          projectId: "fantasy-cafe",
            storageBucket: "fantasy-cafe.appspot.com",
              messagingSenderId: "799920299695",
                appId: "1:799920299695:android:6799804c262528051bbe5a"
      };
  }
} else if (currentTournamentName == "IPL") {
  currentTournamentDetails = {
    fullName : "Dream T20",
    joinFullNameLowercase : "dreamt20",
    joinFullNameCapitalize : "Dreamt20",
    pathFullName : "DreamT20",
    halfNameCapitalize : "Dreamt20",
    shortName : currentTournamentName,
    shortCode : "dt20",
    shortCodeCapitalize: "Dt20",
    logo : "images/dt20logo.svg",
    sidebarLogo : "images/menulogo.svg",
    majorUsedDocId : "dreamt20"  
  }
  if(currentEnvironment == "DEV") {
    currentTournamentDetails.version = "1.0.48.1";
    firebaseConfig = {
      apiKey: "AIzaSyAtLzX4zOq91DMBi_4hI50CtlqeLSx1_tQ",
      authDomain: "dream-t20-dev.firebaseapp.com",
      projectId: "dream-t20-dev",
      storageBucket: "dream-t20-dev.appspot.com",
      messagingSenderId: "566534048711",
      appId: "1:566534048711:web:e6298a391f0f79d6f9e7e2",
      measurementId: "G-LB6DCTBF7W",
    };
  } else {
    currentTournamentDetails.version = "1.0.49";
    firebaseConfig = {
      apiKey: "AIzaSyBDXX9kku2Tg4E9eSzGJA8iGZ4ETybtSXc",
      authDomain: "dream-t20.firebaseapp.com",
      projectId: "dream-t20",
      storageBucket: "dream-t20.appspot.com",
      messagingSenderId: "278768223117",
      appId: "1:278768223117:web:4caeb1c9a10f46d76933c4",
      measurementId: "G-HMYBD5KEG4"
    };
  }
} else {
  currentTournamentDetails = null;
}

const getDefaultConfig = async () => {
  let config = await fetch("/__/firebase/init.json")
    .then(function (res) {
      return res.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (err) {
      console.log(err, " error");
      return firebaseConfig;
    });

  return config;
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    let res = setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        return false;
      });

    return res;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const logout = async () => {
  localStorage.removeItem("aT");
  await signOut(auth);
  window.location.href = "/login";
  return true;
};

const config = await getDefaultConfig();
console.log('Firebase Config : ', config);
const firebaseApp = Firebase.initializeApp(config);
const db = getFirestore(firebaseApp);
const app = initializeApp(config);
const auth = getAuth(app);
export { auth, db, logInWithEmailAndPassword, logout, currentTournamentDetails };
