import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Card, Row, Col, Tag, Button, Modal, Form, Input, Divider, Tabs, Spin } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import { Validator } from "jsonschema";
import {
    TableOutlined,
    CodepenOutlined
} from "@ant-design/icons";
const { TabPane } = Tabs;
const SCHEDULE_SCHEMA = require("../Jsondata/schema/Fantasy_schedule.json");
function Editmatch(props) {
    const [loaded, setLoaded] = useState(false);
    const [valuess, setValuess] = useState(null)
    const [matchInfoFilter, setMatchInfoFilter] = useState(null)
    const [form] = Form.useForm();
    const [rawViews, setRawViews] = useState(false)
    const [buttonColor, setButtonColor] = useState("Text");
    const [tabData, setTabData] = useState("1");
    const [modelShowTxt, setModelShowTxt] = useState(false)
    const [modelShowRaw, setModelShowRaw] = useState(false)
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const rawView = (arg) => {
        setRawViews(arg)
        setButtonColor(arg === true ? "Raw" : "Text")
    }
    const handleChanges = (e) => {
        setMatchInfoFilter(JSON.parse(e.target.value))
    }
    const onFinish = (values) => {
        setModelShowTxt(true)
        setValuess(values)
    };
    const onFinishRaw = () => {
        setModelShowRaw(true)
    }
    const handleClose = () => {
        setModelShowTxt(false)
        setModelShowRaw(false)
    };
    const validateScheduleJson = (jsonObj) => {
        let jsonSchema = SCHEDULE_SCHEMA;

        try {
            let jsonValidator = new Validator();
            if (jsonSchema) {
                jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
                    if (typeof instance !== "string") return;
                    if (schema.contains.indexOf(instance) < 0) {
                        return "does not contain the string " + JSON.stringify(schema.contains);
                    }
                };
                let _data = jsonObj || {};
                console.log(_data)
                let validate = jsonValidator.validate(_data, jsonSchema);
                if (validate.errors.length == 0) {
                    return null;
                }
                else {
                    return validate.errors;
                }
            }
        } catch (err) {
            return null;
        }
        return null;
    };
    const submitTxtUpdate = async () => {
        setLoaded(true);
        setModelShowTxt(false);
        let mainarray = [];
        mainarray.push(props.matchInfo);
        mainarray.forEach(function (item, index) {
            item.ht.players.forEach(function (item2, index2) {
                if (item2.playerId == props.playerDetails.playerId) {
                    item2.pointsBestYH = valuess.pointsBestYH ? parseFloat(valuess.pointsBestYH) : 0;
                    item2.pointsBestFD = valuess.pointsBestFD ? parseFloat(valuess.pointsBestFD) : 0;
                    item2.pointsBestDK = valuess.pointsBestDK ? parseFloat(valuess.pointsBestDK) : 0;
                    item2.pointsActDK = valuess.pointsActDK ? parseFloat(valuess.pointsActDK) : 0;
                    item2.pointsActFD = valuess.pointsActFD ? parseFloat(valuess.pointsActFD) : 0;
                    item2.pointsActYH = valuess.pointsActYH ? parseFloat(valuess.pointsActYH) : 0;
                    item2.salaryYH = valuess.salaryYH ? parseFloat(valuess.salaryYH) : 0;
                    item2.salaryFD = valuess.salaryFD ? parseFloat(valuess.salaryFD) : 0;
                    item2.salaryDK = valuess.salaryDK ? parseFloat(valuess.salaryDK) : 0;
                    item2.pointsEstDK = valuess.pointsEstDK ? parseFloat(valuess.pointsEstDK) : 0;
                    item2.pointsEstYH = valuess.pointsEstYH ? parseFloat(valuess.pointsEstYH) : 0;
                    item2.pointsEstFD = valuess.pointsEstFD ? parseFloat(valuess.pointsEstFD) : 0;

                }
            });
            item.rt.players.forEach(function (item2, index2) {
                if (item2.playerId == props.playerDetails.playerId) {
                    item2.pointsBestYH = valuess.pointsBestYH ? parseFloat(valuess.pointsBestYH) : 0;
                    item2.pointsBestFD = valuess.pointsBestFD ? parseFloat(valuess.pointsBestFD) : 0;
                    item2.pointsBestDK = valuess.pointsBestDK ? parseFloat(valuess.pointsBestDK) : 0;
                    item2.pointsActDK = valuess.pointsActDK ? parseFloat(valuess.pointsActDK) : 0;
                    item2.pointsActFD = valuess.pointsActFD ? parseFloat(valuess.pointsActFD) : 0;
                    item2.pointsActYH = valuess.pointsActYH ? parseFloat(valuess.pointsActYH) : 0;
                    item2.salaryYH = valuess.salaryYH ? parseFloat(valuess.salaryYH) : 0;
                    item2.salaryFD = valuess.salaryFD ? parseFloat(valuess.salaryFD) : 0;
                    item2.salaryDK = valuess.salaryDK ? parseFloat(valuess.salaryDK) : 0;
                    item2.pointsEstDK = valuess.pointsEstDK ? parseFloat(valuess.pointsEstDK) : 0;
                    item2.pointsEstYH = valuess.pointsEstYH ? parseFloat(valuess.pointsEstYH) : 0;
                    item2.pointsEstFD = valuess.pointsEstFD ? parseFloat(valuess.pointsEstFD) : 0;
                }
            });
        });
        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });

        let jsonObject = obj.val;
        let errorObj = null;
        // if (jsonObject) errorObj = validateScheduleJson(obj.val);
        if (jsonObject) errorObj = null;
        if (!errorObj) {
            let payload = obj.val;
            payload.appId = `${currentTournamentDetails.shortCode}-ops`;
            const response = await axios({
                method: 'post',
                url: `${domain}/betops/fantasy/update/data/v2.0`,
                headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
                data: payload
            }).then((response) => {
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data.message,
                        onOk() {
                            props.handleBack();
                        },
                    });
                }
                else {
                    if (response.data.error.message == "Auth token is not valid") {
                        Modal.error({
                            content: response.data.error.message,
                            onOk() {
                                logout();
                            }
                        });
                    } else {
                        Modal.error({
                            content: response.data.error.message
                        });
                    }
                }
            })
                .catch((error) => {
                    Modal.error({
                        content: "Unable to process your Request, Please try again later."
                    });
                })
            setLoaded(false);
        }
        if (errorObj != null) {
            handleClose();
            setLoaded(false);
            Modal.error({
                title: " Json Schema Validation ",

                content: (
                    <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
                        <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
                        {errorObj.map((item, index) => {
                            let _type = item.path[item.path.length - 1];
                            let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
                            let _msg = item.message;
                            if (item.name == "required") {
                                _type = item.path.length > 0 ? item.path[0] : "";
                                _msg = item.argument + " " + "required";
                            }
                            _msg = _type + " " + _msg;

                            return (
                                <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                                    <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                                        </div>
                                    </span>
                                </Tag>
                            );
                        })}
                    </div>
                ),
                onOk() {
                    setLoaded(false);
                },
            });
            return;
        }
    }
    const submitRawUpdate = async () => {
        setLoaded(true);
        setModelShowRaw(false);
        let mainarray = [];
        mainarray.push(props.matchInfo);
        for (let i = 0; i < mainarray[0].ht.players.length; i++) {

            if (mainarray[0].ht.players[i].playerId == props.playerDetails.playerId) {
                mainarray[0].ht.players[i] = matchInfoFilter
            }
        }
        for (let i = 0; i < mainarray[0].rt.players.length; i++) {

            if (mainarray[0].rt.players[i].playerId == props.playerDetails.playerId) {
                mainarray[0].rt.players[i] = matchInfoFilter
            }
        }
        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });
        let jsonObject = obj.val;
        let errorObj = null;
        // if (jsonObject) errorObj = validateScheduleJson(obj.val);
        if (jsonObject) errorObj = null;
        if (!errorObj) {
            let payload = obj.val;
            payload.appId = `${currentTournamentDetails.shortCode}-ops`;
            const response = await axios({
                method: 'post',
                url: `${domain}/betops/fantasy/update/data/v2.0`,
                headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
                data: payload
            }).then((response) => {
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data.message,
                        onOk() {
                            props.handleBack();
                        },
                    });
                }
                else {
                    if (response.data.error.message == "Auth token is not valid") {
                        Modal.error({
                            content: response.data.error.message,
                            onOk() {
                                logout();
                            }
                        });
                    } else {
                        Modal.error({
                            content: response.data.error.message
                        });
                    }
                }
            })
                .catch((error) => {
                    Modal.error({
                        content: "Unable to process your Request, Please try again later."
                    });
                })
            setLoaded(false);
        }
        if (errorObj != null) {
            handleClose();
            setLoaded(false);
            Modal.error({
                title: " Json Schema Validation ",

                content: (
                    <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
                        <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
                        {errorObj.map((item, index) => {
                            let _type = item.path[item.path.length - 1];
                            let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
                            let _msg = item.message;
                            if (item.name == "required") {
                                _type = item.path.length > 0 ? item.path[0] : "";
                                _msg = item.argument + " " + "required";
                            }
                            _msg = _type + " " + _msg;

                            return (
                                <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                                    <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                                        </div>
                                    </span>
                                </Tag>
                            );
                        })}
                    </div>
                ),
                onOk() {
                    setLoaded(false);
                },
            });
            return;
        }
    }
    const loadProfile = () => {
        setMatchInfoFilter(props.playerDetails)

        form.setFieldsValue({
            pointsBestYH: props.playerDetails.pointsBestYH,
            pointsBestFD: props.playerDetails.pointsBestFD,
            pointsBestDK: props.playerDetails.pointsBestDK,
            pointsActDK: props.playerDetails.pointsActDK,
            pointsActYH: props.playerDetails.pointsActYH,
            pointsActFD: props.playerDetails.pointsActFD,
            htwickets: props.playerDetails.htwickets,
            salaryYH: props.playerDetails.salaryYH,
            salaryFD: props.playerDetails.salaryFD,
            salaryDK: props.playerDetails.salaryDK,
            pointsEstDK: props.playerDetails.pointsEstDK,
            pointsEstFD: props.playerDetails.pointsEstFD,
            pointsEstYH: props.playerDetails.pointsEstYH,


            pointsBestX1: props.playerDetails.pointsBestIPL,
            pointsAct: props.playerDetails.pointsActIPL,
            salaryRsCrore: props.playerDetails.salaryIPL,
            pointsEst: props.playerDetails.pointsEstIPL
        });
    };
    useEffect(() => {
        loadProfile({});
    }, [props]);
    const handleTabChange = (arg) => {

        setTabData(arg)
    }
    const title = props.globalInfo.tournamenttitle + " | Game " + props.globalInfo.matchno;
    return (
        <>
            <Card size="small" title={title} style={{ width: 300 }}>
                <Row gutter={24}>
                    <Col span={13} className="rtside">
                        <span>
                            <img src={props.rticon} width={"25px"} />
                        </span>
                        <span className="teamname">{props.matchid.rt} </span>
                    </Col>
                    <Col span={11}>
                        <span className="infolabel">{props.matchid.resRt}</span>
                    </Col>
                </Row>
                <Row gutter={24} className="mtop">
                    <Col span={13} className="htside">
                        <span>
                            <img src={props.hticon} width={"25px"} />
                        </span>
                        <span className="teamname">{props.matchid.ht} </span>
                    </Col>
                    <Col span={11}>
                        <span className="infolabel">{props.matchid.resHt}</span>
                    </Col>
                </Row>
                <Divider className="divider" />
                <Row gutter={24} className="mtop">
                    <Col span={24}>
                        <p className="matchsummary">{props.matchid.resultSummary}</p>
                    </Col>
                </Row>
            </Card>
            <Row gutter={12}>
                <Col span={20}>
                    <a href="#" style={buttonColor === "Text" ? { color: "blue", marginRight: "10px" } : { color: "gray", marginRight: "10px" }} onClick={() => rawView(false)}><TableOutlined /> <span>Text View</span></a>
                    {" "}
                    <a href="#" style={buttonColor !== "Text" ? { color: "blue" } : { color: "gray" }} onClick={() => rawView(true)}><CodepenOutlined /> <span>Raw View</span></a>
                </Col>
            </Row>
            <br />
            {rawViews ? <>   <Row gutter={24}>
                <Col span={20}>
                    <p>
                        <img src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${props.playerDetails.playerId}&tournament=${props.tournament}&team=${props.playerDetails.teamName}`} width={"25px"} /> <span className="teamname">{props.playerDetails.name} </span>
                    </p>
                </Col>
                <Col span={4}>
                    {/* <span>
                        {props.playerDetails.isForeign ? <img src="images/visitor.svg"></img> : <img src="images/host.svg"></img>}
                    </span> */}
                </Col>
            </Row>
                <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinishRaw}>
                    <Row gutter={24}>
                        <Col span={24}>
                        <textArea style={{ width: "60%", marginTop: "10px", marginBottom: "10px" }} onChange={handleChanges} rows={10}>{JSON.stringify(matchInfoFilter, null, 4)}</textArea>
                        </Col>
                    </Row>
                    <Row gutter={12} className="rtmp1">
                        <Col span={12}>
                            <Form.Item shouldUpdate {...tailLayout}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row> </Form></> :
                <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <p>
                                <img src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${props.playerDetails.playerId}&tournament=${props.tournament}&team=${props.playerDetails.teamName}`} width={"25px"} /> <span className="teamname">{props.playerDetails.name} </span>
                            </p>
                        </Col>
                        <Col span={4}>
                            {/* <span>
                                {props.playerDetails.isForeign ? <img src="images/visitor.svg"></img> : <img src="images/host.svg"></img>}
                            </span> */}
                        </Col>
                    </Row>
                    {currentTournamentDetails.shortName === "NFL" ? (
                    <>
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                        <TabPane tab="Yahoo" key="1">
                        </TabPane>
                        <TabPane tab="Draft King" key="2">
                        </TabPane>
                        <TabPane tab="Fan Duel" key="3">
                        </TabPane>
                    </Tabs>
                    <div className={tabData == "2" || tabData == "3" ? "d-none" : ""}>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsBestYH" label="Points Best X" rules={[{ required: true, message: "Please input your Points Best X!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsActYH" label="Points Act" rules={[{ required: true, message: "Please input your points Act!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="salaryYH" label="Salary $" rules={[{ required: true, message: "Please input your Salary $!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsEstYH" label="Points Est" rules={[{ required: true, message: "Please input your points Est!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                    </div>
                    <div className={tabData == "1" || tabData == "3" ? "d-none" : ""}>  <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="pointsBestDK" label="Points Best X" rules={[{ required: true, message: "Please input your Points Best X!" }]}>
                                <Input placeholder="points" />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsActDK" label="Points Act" rules={[{ required: true, message: "Please input your points Act!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="salaryDK" label="Salary $" rules={[{ required: true, message: "Please input your Salary $!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsEstDK" label="Points Est" rules={[{ required: true, message: "Please input your points Est!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                    </div>
                    <div className={tabData === "1" || tabData === "2" ? "d-none" : ""}>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsBestFD" label="Points Best X" rules={[{ required: true, message: "Please input your Points Best X!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsActFD" label="Points Act" rules={[{ required: true, message: "Please input your points Act!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="salaryFD" label="Salary $" rules={[{ required: true, message: "Please input your Salary $!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Form.Item name="pointsEstFD" label="Points Est" rules={[{ required: true, message: "Please input your points Est!" }]}>
                                    <Input placeholder="points" />
                                </Form.Item>{" "}
                            </Col>
                        </Row>
                    </div>
                    </>
                        ) : (
                 <>
                      <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="pointsBestX1" label="Points Best X1" rules={[{ required: true, message: "Please input your points BestX1!" }]}>
                                <Input placeholder="points" />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="pointsAct" label="Points Act" rules={[{ required: true, message: "Please input your points Act!" }]}>
                                <Input placeholder="points" />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="salaryRsCrore" label="Salary Rs Crore" rules={[{ required: true, message: "Please input your salary Rs Crore!" }]}>
                                <Input placeholder="points" />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="pointsEst" label="Points Est" rules={[{ required: true, message: "Please input your points Est!" }]}>
                                <Input placeholder="points" />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                 </>
                        )}
                    
                    <Row gutter={24} className="rtmp1">
                        <Col span={24}>
                            <Form.Item shouldUpdate {...tailLayout}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>}
            <Modal title="Update Player Points" onCancel={handleClose} visible={modelShowTxt}
                footer={[
                    // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" onClick={handleClose}>
                        No
                    </Button>,
                    <Button
                        type="primary"
                        onClick={submitTxtUpdate}
                    >
                        Yes
                    </Button>
                ]}

            >
                Would you like to save changes?
            </Modal>
            <Modal title="Update Player Points" onCancel={handleClose} visible={modelShowRaw}
                footer={[
                    // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" onClick={handleClose}>
                        No
                    </Button>,
                    <Button
                        type="primary"
                        onClick={submitRawUpdate}
                    >
                        Yes
                    </Button>
                ]}

            >
                Would you like to save changes?
            </Modal>
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default Editmatch;
