import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import DashboardMatchDetails from "./DasboardDetails/DashboardMatchDetails";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  SyncOutlined
} from "@ant-design/icons";
import { reload } from "firebase/auth";

const { Option } = Select;
const { Content } = Layout;
function Overview() {
  const [loaded, setLoaded] = useState(true);

  const [tournamentValue, setTournamentValue] = useState(currentTournamentDetails.shortName);
  // const [seasonValue, setSeasonValue] = useState(2023);
  // const [seasonTypeValue, setSeasonTypeValue] = useState("PRE");

  const [userAnalytics, setUserAnalytics] = useState(null);
  const [userChartDropdown, setUserChartDropdown] = useState("login");
  const [userChartValue, setUserChartValue] = useState(null);

  const [transAnalytics, setTransAnalytics] = useState(null);
  const [transChartValue, setTransChartValue] = useState(null);

  const [funnymoneyAnalytics, setFunnymoneyAnalytics] = useState(null);
  const [funnymoneyChartDropdown, setFunnymoneyChartDropdown] = useState(null);
  const [funnymoneyChartDropdownList, setFunnymoneyChartDropdownList] = useState(null);
  const [funnymoneyChartValue, setFunnymoneyChartValue] = useState(null);

  const [transTabKey, setTransTabKey] = useState(null);
  const [transWeekKey, setTransWeekKey] = useState(null);

  const [tabList, setTabList] = useState(null);
  const [weekList, setWeekList] = useState(null);

  const [currentSeasonTransCount, setCurrentSeasonTransCount] = useState(null);

  const [transactionDetail, setTransactionDetail] = useState(null);

  const { TabPane } = Tabs;

  useEffect(async () => {
    await sportsListAndInfoConfig();
    fetchUserAnalytics();
    fetchTransAnalytics();
    fetchFunnymoneyAnalytics();

    fetchTabandWeekList();
    handleTransTabChange();
  }, []);

  const sportsListAndInfoConfig = async (e) => {
    const response1 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sports/v1`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        localStorage.setItem("sportsList", response.data.data);
        await sportInfo(response.data.data[0]);
      }
      else {
      }
    }).catch((error) => {
    })
  }

  const sportInfo = async (currentSport) => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${currentSport}`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        localStorage.setItem("sportYearsList", response.data.data.yearsList);
        localStorage.setItem("sportSeasonsList", response.data.data.seasonsList);
      }
      else {
      }
    }).catch((error) => {
    })
  }

  const reloadCharts = async () => {
    fetchUserAnalytics();
    fetchTransAnalytics();
    fetchFunnymoneyAnalytics();
  }

  const reloadTransCharts = async () => {
    fetchTransInfo(transTabKey, transWeekKey);
  }

  const fetchTabandWeekList = async () => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/analytics/get/tab-list/v1?tournament=${tournamentValue}&appId=${currentTournamentDetails.shortCode}-ops`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setTabList(response.data.data.tabList);
        setTransTabKey(response.data.data.tabList[0].key);

        setWeekList(response.data.data.weekList);
        setTransWeekKey(response.data.data.weekList[0].key);

        fetchTransInfo(response.data.data.tabList[0].key, response.data.data.weekList[0].key);
      }
    }).catch((error) => {

    })
  }

  const handleTransTabChange = async (e) => {
    setTransTabKey(e);
    fetchTransInfo(e, transWeekKey);
  }

  const handleTransWeekChange = async (e) => {
    setTransWeekKey(e);
    fetchTransInfo(transTabKey, e);
  }

  const fetchUserAnalytics = async () => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/analytics/get/user-list/v1?appId=${currentTournamentDetails.shortCode}-ops`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setUserAnalytics(response.data.data.userDetails);
        setUserChartValue(response.data.data.userDetails.provider);
      }
    }).catch((error) => {

    })
  }

  const fetchTransAnalytics = async () => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/analytics/get/trans-list/v1?tournament=${tournamentValue}&appId=${currentTournamentDetails.shortCode}-ops`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setTransAnalytics(response.data.data);
        setTransChartValue(response.data.data.trans_list);
      }
    }).catch((error) => {
    })
  }

  const fetchFunnymoneyAnalytics = async () => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/analytics/get/funny-money-list/v1?tournament=${tournamentValue}&appId=${currentTournamentDetails.shortCode}-ops`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setFunnymoneyAnalytics(response.data.data);
        setFunnymoneyChartValue(response.data.data.summary);

        let allKeys = Object.keys(response.data.data);
        setFunnymoneyChartDropdownList(allKeys);
        let currentVal = allKeys.length - 1;
        setFunnymoneyChartDropdown(allKeys[currentVal]);
      }
    }).catch((error) => {
    })
  }

  const fetchTransInfo = async (game, week) => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/analytics/get/week-trans-list/v1?tournament=${tournamentValue}&appId=${currentTournamentDetails.shortCode}-ops&week=${week}&game=${game}`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        let resVal = [];
        let val = [];
        if(response.data.data.matchList) {
          setCurrentSeasonTransCount(response.data.data.season_trans_count);
          resVal = response.data.data.matchList;
          val = resVal.sort((a, b) => parseFloat(a.matchId) - parseFloat(b.matchId));
        } else if(response.data.data.seasonList) {
          setCurrentSeasonTransCount(response.data.data.season_trans_count);
          val = response.data.data.seasonList;
        }
       
        
        transactionDetails(val);
        setLoaded(false);
      }
      else {
        setLoaded(false);
      }
    }).catch((error) => {
      setLoaded(false);
    })
  }

  const transactionDetails = (data) => {
    setTransactionDetail(<Col span={24}>
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        options={{ legend: 'none', isStacked: true }}
        data={transBardiagramMapping(data)}
      />
    </Col>)
  }

  const userChartMapping = (items) => {
    if (items != null || items != undefined) {
      let allKeys = Object.keys(items);
      let allKeysVal = Object.values(items);
      let tempArr = [];
      for (const [key, value] of Object.entries(items)) {
        let test = {
          [key]: Object.values(value)[2]
        };
        tempArr.push(test);

      }
      items = tempArr.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
    }
    // items = {
    //   "apple": 2,
    //   "email": 13,
    //   "google": 1,
    //   "phone": 7
    // };
    const fieldingDismissalsSeasonWise = [["Element", "Element", { role: 'annotation' }]];
    let item = items
    for (var j in item) {
      if (j === "total") {

      }
      else {
        fieldingDismissalsSeasonWise.push([
          j,
          items[j],
          j,
        ]);
      }

    }
    return fieldingDismissalsSeasonWise;
  }

  const transChartMapping = (items) => {
    if (items != null || items != undefined) {
      let allKeys = Object.keys(items);
      let allKeysVal = Object.values(items);
      let tempArr = [];
      // for (const [key, value] of Object.entries(items)) {
      for (let i = 0; i < items.length; i++) {
        let test = {
          [items[i].type]: items[i].trans_count
        };
        tempArr.push(test);

      }
      items = tempArr.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
    }

    const fieldingDismissalsSeasonWise = [["Element", "Element", { role: 'annotation' }]];
    let item = items
    for (var j in item) {
      if (j === "total") {

      }
      else {
        fieldingDismissalsSeasonWise.push([
          j,
          items[j],
          j,
        ]);
      }

    }
    return fieldingDismissalsSeasonWise;
  }

  const funnymoneyChartMapping = (items) => {
    // if(items != null || items != undefined) {
    //   let allKeys = Object.keys(items);
    //   let allKeysVal = Object.values(items);
    //   let tempArr = [];
    //     for (const [key, value] of Object.entries(items)) {
    //     let test = { 
    //       [key] : Object.values(value)[2]
    //      };
    //      tempArr.push(test);

    //   }
    //   items = tempArr.reduce(function(result, current) {
    //     return Object.assign(result, current);
    //   }, {})
    // }

    const fieldingDismissalsSeasonWise = [["Element", "Element", { role: 'annotation' }]];
    let item = items
    for (var j in item) {
      if (j === "total") {

      }
      else {
        fieldingDismissalsSeasonWise.push([
          j,
          items[j],
          j,
        ]);
      }

    }
    return fieldingDismissalsSeasonWise;
  }

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const transBardiagramMapping = (data) => {
    if(data[0].matchId) {



      const fieldingDismissalsSeasonWise = [["Element", "Value", { role: 'annotation' }]];
      for (var j = 0; j < data.length; j++) {
          let val = data[j].matchId.toString();
          let finalVal = val.slice(4);
          fieldingDismissalsSeasonWise.push([
            finalVal,
            data[j].trans_count,
            data[j].trans_count,
          ]);
      }
      return fieldingDismissalsSeasonWise



    } else if(data[0].season) {



      const fieldingDismissalsSeasonWise = [["Element", "Value", { role: 'annotation' }]];
      for (var j = 0; j < data.length; j++) {
          // let val = data[j].matchId.toString();
          // let finalVal = val.slice(4);
          let finalVal = data[j].season.toString();
          fieldingDismissalsSeasonWise.push([
            finalVal,
            data[j].trans_count,
            data[j].trans_count,
          ]);
      }
      return fieldingDismissalsSeasonWise



    }

  }

  const handleUserChartDropdown = (e) => {
    setUserChartDropdown(e)
    if (e === "login")
      setUserChartValue(userAnalytics.provider)
    else
      setUserChartValue(userAnalytics.platform)
  }

  const handleFunnymoneyChartDropdown = (e) => {
    setFunnymoneyChartDropdown(e);
    if (e == "skillBet") {
      setFunnymoneyChartValue(funnymoneyAnalytics.skillBet);
    } else if (e == "fantasyMxM") {
      setFunnymoneyChartValue(funnymoneyAnalytics.fantasyMxM);
    } else if (e == "predictMatch") {
      setFunnymoneyChartValue(funnymoneyAnalytics.predictMatch);
    } else if (e == "quiz") {
      setFunnymoneyChartValue(funnymoneyAnalytics.quiz);
    } else if (e == "predictSeason") {
      setFunnymoneyChartValue(funnymoneyAnalytics.predictSeason);
    } else if (e == "summary") {
      setFunnymoneyChartValue(funnymoneyAnalytics.summary);
    }
  }

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="overview" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background site-layout-background1" style={{ margin: "0px 16px 0", minHeight: 280 }}>
            <Tabs defaultActiveKey="1" className="dashboard-tab" style={{ marginLeft: "-6px", marginRight: "-16px" }}>
              <TabPane tab="Overview" key="1">
                {userAnalytics ? <Row gutter={16}>
                  <Col span={8}>
                    <Card bordered={false}>
                      <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{userAnalytics.overAll.total_users}</h2></div>
                      <div style={{ fontWeight: "600" }}>Total Gamers</div>
                      <div id="copyright"></div>
                    </Card>
                  </Col>
                  {transAnalytics ?
                    <Col span={8}>
                      <Card bordered={false}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{transAnalytics["total_trans_count"]}</h2></div>
                        <div style={{ fontWeight: "600" }}>Total Transactions</div>
                        <div id="copyright"></div>
                      </Card>
                    </Col>
                    : ""}
                  {funnymoneyAnalytics ?
                    <Col span={8}>
                      <Card bordered={false}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{funnymoneyAnalytics.summary.fmWager}</h2></div>
                        <div style={{ fontWeight: "600" }}>Total Funny Money</div>
                        <div id="copyright"></div>
                      </Card>
                    </Col>
                    : ""}



                </Row> : ""}
                <br />
                <Row gutter={16}>
                  <Col span={24}>
                    {userAnalytics ? <Card bordered={false}>
                      <div><span style={{ fontWeight: "600" }}>Analytics Charts - <Moment format="DD/MM/YYYY">{new Date()}</Moment>- Now</span><span style={{ color: "#2196F3", float: "right" }}><span style={{ color: "rgba(0,0,0,.85)", fontWeight: "600", fontSize: "14px", marginRight: "10px" }}>Reload Charts </span><SyncOutlined onClick={reloadCharts} className="dashboard-refesh-icon" /></span></div>
                      <Divider style={{ borderBottom: "2px solid #2196F3", margin: "12px 0" }} />
                      <Row gutter={16} className="dashboard-chart-section">
                        <Col span={8} style={{ borderRight: "1px solid #bbbbbb61" }}>
                          <h5 style={{ textAlign: "center", color: "#0099f3", margin: "10px 0" }}>Gamers</h5>


                          <Select size="default" value={userChartDropdown} style={{ width: "200px", border: "none" }} onChange={(e) => handleUserChartDropdown(e)}>
                            <Option key={1} value="login">
                              user login type
                            </Option>
                            <Option key={2} value="Platform">
                              user platform type
                            </Option>
                          </Select>

                          <Chart
                            width="100%"
                            height="400px"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={userChartMapping(userChartValue)}
                            options={{
                              pieHole: 0.3,
                              is3D: true,
                            }}
                          />
                        </Col>



                        <Col span={8} style={{ borderRight: "1px solid #bbbbbb61" }}>
                          <h5 style={{ textAlign: "center", color: "#0099f3", margin: "10px 0" }}>Transactions</h5>
                          {/* <Select size="default" value={userChartDropdown} style={{ width: "200px", border: "none" }} onChange={(e) => handleUserChartDropdown(e)}>
                            <Option key={1} value="login">
                              User Login Type
                            </Option>
                            <Option key={2} value="Platform">
                              User Platform Type
                            </Option>
                          </Select> */}
                          <Chart
                            width="100%"
                            height="400px"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={transChartMapping(transChartValue)}
                            options={{
                              pieHole: 0.3,
                              is3D: true
                            }}
                          />
                        </Col>




                        <Col span={8}>
                          <h5 style={{ textAlign: "center", color: "#0099f3", margin: "10px 0" }}>Funny Money</h5>
                          {setFunnymoneyChartDropdownList && funnymoneyChartDropdown ?
                            <Select size="default" value={funnymoneyChartDropdown} style={{ width: "200px", border: "none" }} onChange={(e) => handleFunnymoneyChartDropdown(e)}>
                              {funnymoneyChartDropdownList.map((item, index) => (
                                <Option key={index} value={item}>
                                  {item}{" "}
                                </Option>
                              ))}
                            </Select>
                            : ""}
                          <Chart
                            width="100%"
                            height="400px"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={funnymoneyChartMapping(funnymoneyChartValue)}
                            options={{
                              pieHole: 0.3,
                              is3D: true,
                            }}
                          />
                        </Col>
                      </Row>
                    </Card> : ""}
                  </Col>

                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <div><span style={{ fontWeight: "600" }}>Transaction Charts - <Moment format="DD/MM/YYYY">{new Date()}</Moment>- Now</span><span style={{ color: "#2196F3", float: "right" }}><span style={{ color: "rgba(0,0,0,.85)", fontWeight: "600", fontSize: "14px", marginRight: "10px" }}>Reload Charts </span><SyncOutlined onClick={reloadTransCharts} className="dashboard-refesh-icon" /></span></div>
                      <Divider style={{ borderBottom: "2px solid #2196F3", margin: "12px 0" }} />
                      <div>
                        {tabList ?
                          <Tabs onChange={handleTransTabChange}>
                            {tabList.map((item, index) => (
                              <TabPane tab={item.label} key={item.key}>
                                {transWeekKey && weekList ?
                                <>
                                {item.key != "quiz" && item.key != "predictSeason" ?
                                <Select size="default" value={transWeekKey} style={{ width: "200px" }} onChange={handleTransWeekChange}>
                                  {weekList.map((item, index) => (
                                    <Option key={index} value={item.key}>
                                      {item.displayName}{" "}
                                    </Option>
                                  ))}
                                </Select>
                                : "" }
                                <span style={{ color: "rgba(0,0,0,.85)", float: "right", fontWeight: "600" }}>Current Season Transaction Count : <span style={{ color: "#2196F3", fontWeight: "normal" }}>{currentSeasonTransCount}</span></span>
                                </>
                              : ""}
                              </TabPane>
                            ))}
                          </Tabs>
                          : ""}
                      </div>
                      {transactionDetail}
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tab="Match Details" key="2">
                <DashboardMatchDetails />
              </TabPane> */}
            </Tabs>
          </Content>
          {
            loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )
          }
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Overview;
