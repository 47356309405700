import { collection, getDocs, doc, deleteDoc, getDoc } from "firebase/firestore";
import { Select, Spin, Layout, Input, Card, Popover, Table, Space, Modal, Drawer, PageHeader } from "antd";
import { db, currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Addalert from "./addalert";
import Editalert from "./editalert";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
  PlusOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
function Customalert() {
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [drawertype, setDrawertype] = useState("");
  const [title, setTitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleJson = () => {
    setJsonviewer(false);
  };
  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setMatchDetails([]);
    setTitle("Add Alert");
    setVisible(true);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    console.log(blob);
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadocument = async (documentID) => {
    // const matchDocRef = doc(db, "schedule", documentID.documentID);
    // const docSnap = await getDoc(matchDocRef);
    // const arraycheck = docSnap.data();
    // downloadFile(arraycheck, documentID.documentID);
  };
  const datesplit = (text) => {

    const res = text.split("_");
    var currentTimeInSeconds = Math.floor(res[1] / 1000);
    return currentTimeInSeconds;
  };
  const onClose = () => {
    setMatchDetails([]);
    setVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchNotification();
    }
  };
  const content = (notifId) => {
    return (
      <div>
        <p>{notifId.title}</p>
        <p>{notifId.desc}</p>
      </div>
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchNotification = async () => {
    // const scheduleCol = collection(db, "notification");
    // const scheduleSnapshot = await getDocs(scheduleCol);
    // const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    // let genericlist = scheduleList.filter(function (user) {
    //   return user.type === "GENERIC";
    // });
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/notification/notif-list/v2.0?appId=${currentTournamentDetails.shortCode}-ops&type=GENERIC`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setSchedule(response.data.data.notifList);
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    fetchNotification();
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    // if (loaded) {
    //   setTimeout(() => {
    //     setLoaded(false);
    //   }, 2000);
    // }
  }, [loaded]);

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="customalert" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Push Notification"
                  extra={
                    <>
                      {" "}
                      <Space direction="horizontal">
                        {" "}

                        <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} className=" mb-1" />
                        <a onClick={() => addnew()}>
                          <span className="addschedule">New Alert</span>
                        </a>
                        <Search placeholder="Search here" onChange={onSearch} enterButton />
                      </Space>{" "}
                    </>
                  }
                />
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  dataSource={schedule}
                  scroll={{ y: "340" }}
                >
                  <Column title="Alert ID" dataIndex="notifId" key="key" />
                  <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    render={(text, record) => (
                      <Space size="middle">
                        {text !== undefined ? <span className={text == "Sent" ? "sent" : "scheduled"}>{text}</span> : <span className="sent">Sent</span>}
                      </Space>
                    )}
                  />
                  <Column title="Type" dataIndex="type" key="type" />
                  <Column title="Title" dataIndex="title" key="title" />
                  <Column title="Topic" dataIndex="topic" key="topic" />
                  <Column title="Desc" dataIndex="desc" key="desc" />
                  <Column
                    title="Created Date"
                    dataIndex="createdDt"
                    key="createdDt"
                    render={(text, record) => (
                      <Space size="middle">
                        {text && text !== undefined ? (
                          <Moment unix format="DD-MM-YYYY hh:mm a">
                           {text._seconds}
                          </Moment>
                        ) : (
                          ""
                        )}
                      </Space>
                    )}
                  />
                  <Column
                    title="Quick Actions"
                    key="notifId"
                    render={(notifId) => (
                      <Space size="middle">
                        <Popover placement="left" content={content(notifId)} title={currentTournamentDetails.fullName}>
                          <EyeTwoTone style={{ fontSize: "18px", color: "#08c" }} onClick={() => downloadocument(notifId)} />
                        </Popover>
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>
            <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              Are you sure you want to Notification?
            </Modal>
            <Modal title="Json" visible={jsonviewer} onOk={handleJson} onCancel={jsoncancel}>
              <ReactJson src={jsondata} />
            </Modal>

            <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
              {drawertype == "Editalert" ? (
                <Editalert notificationdetails={matchdetails} onClose={onClose} notification={fetchNotification} />
              ) : (
                <Addalert onClose={onClose} notification={fetchNotification} />
              )}
            </Drawer>
            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Customalert;
