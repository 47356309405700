import { Spin, Layout, Input, InputNumber, Card, Tabs, Row, Col, Table, Space, Button, Modal, Select, Checkbox, PageHeader, Form, DatePicker, Drawer } from "antd";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails, logout } from "../../settings/config";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { domain } from "../../configuration/ApiConfiguration";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import axios from "axios";
import {
    PlusCircleTwoTone,
    MinusCircleOutlined,
    DeleteOutlined,
    EditTwoTone,
    CopyOutlined
} from "@ant-design/icons";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { Option } = Select;

function AddCampaign(props) {
    const [form] = Form.useForm();
    const [limitform] = Form.useForm();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [keyData, setKeyData] = useState(null);
    const [checked, setChecked] = useState(false);
    const [newCreateRoute, setNewCreateRoute] = useState(false);

    const [sDate, setSDate] = useState(null);
    const [eDate, setEDate] = useState(null);

    const [limitsArr, setLimitsArr] = useState([]);
    const [limitIndex, setLimitIndex] = useState(null);

    const [limitType, setLimitType] = useState("");

    const [visible, setVisible] = useState(false);
    const [drawertype, setDrawertype] = useState("");

    const [newKeys, setNewKeys] = useState(null);

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    useEffect(async () => {
        setLoaded(true);
        if (props.keydata) {
            setKeyData(props.keydata);
        } else {
            const response = await axios({
                method: 'get',
                url: `${domain}/betops/dl/campaign/get-all/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
                headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
            }).then((response) => {
                if (response.data.status === "SUCCESS") {
                    setKeyData(response.data.data.keyData);
                    setNewCreateRoute(true);
                }
                else {
                    if (response.data.error.message == "Auth token is not valid") {
                        Modal.error({
                            content: response.data.error.message,
                            onOk() {
                                logout();
                            }
                        });
                    } else {
                        Modal.error({
                            content: response.data.error.message
                        });
                    }
                }
            })
                .catch((error) => {
                    Modal.error({
                        content: "Unable to process your Request, Please try again later."
                    });
                })
        }

        loadProfile({});
        setLoaded(false);
    }, [])

    const onChangeLoginRequired = (e) => {
        setChecked(e.target.checked);
    };

    const loadProfile = () => {
        if (props.Flag == "Edit Campaign") {
            setNewKeys(props.Campaign);
            props.campaignform.setFieldsValue({
                code: props.Campaign.code,
                title: props.Campaign.title,
                desc: props.Campaign.desc,
                type: props.Campaign.type,
                supported_app: props.Campaign.supported_app,
                startDate: changeLocalTime(props.Campaign.startDate),
                endDate: changeLocalTime(props.Campaign.endDate),
                loginRequired: props.Campaign.loginRequired,
                rewardType: props.Campaign.reward.type,
                rewardValue: props.Campaign.reward.value
            });
            setChecked(props.Campaign.loginRequired);
            setLimitsArr(props.Campaign.reward.limits);
        }
    }

    function toDateTime(secs) {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    const changeLocalTime = (dateTime) => {
        let temp = new Date(dateTime._seconds * 1000);
        let final1 = new Date(temp).toISOString();
        return moment(final1);
    }

    const addLimit = () => {
        limitform.resetFields();
        setDrawertype("Add Limit");
        setVisible(true);
    };

    const editLimit = (rowInfo, index) => {
        setLimitType(rowInfo.type);
        limitform.setFieldsValue({
            type: rowInfo.type,
            value: rowInfo.value || ""
        });
        setLimitIndex(index);
        setDrawertype("Edit Limit");
        setVisible(true);
    };

    const deleteLimit = (rowInfo, index) => {
        setLimitsArr(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        });
    };

    const sDateOk = (value) => {
        let final = value.format("YYYY-MM-DD HH:mm:ss");
        let final1 = new Date(final).toISOString();
        setSDate(final1);
    };

    const eDateOk = (value) => {
        let final = value.format("YYYY-MM-DD HH:mm:ss");
        let final1 = new Date(final).toISOString();
        setEDate(final1);
    }

    const drawerClose = () => {
        // form.resetFields();
        setVisible(false);
    };

    const handlelimittype = (e) => {
        setLimitType(e);
    }

    const onFinish1 = async (values) => {
        if (drawertype == "Edit Limit") {
            limitsArr[limitIndex] = values;
            setLimitsArr(limitsArr);
        } else {
            limitsArr[limitsArr.length] = values;
            setLimitsArr(limitsArr);
        }
        drawerClose();
    }

    const onFinish = async (values) => {
        setLoaded(true);
        let sdate = values.startDate.format("YYYY-MM-DD HH:mm:ss");
        let finalsdate = new Date(sdate).toISOString();

        let edate = values.endDate.format("YYYY-MM-DD HH:mm:ss");
        let finaledate = new Date(edate).toISOString();

        let finalObj = null;
        let dataurl = null;
        if (props.Flag === "New Campaign" || newCreateRoute) {
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "reward": {
                    "type": values.rewardType,
                    "value": parseInt(values.rewardValue),
                    "limits": limitsArr
                },
                "endDate": finaledate,
                "type": values.type,
                "code": values.code,
                "loginRequired": values.loginRequired,
                "active": true,
                "supported_app": values.supported_app,
                "desc": values.desc,
                "title": values.title,
                "startDate": finalsdate
            };
            dataurl = `${domain}/betops/dl/campaign/create/v2.0`;
        } else {
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "reward": {
                    "type": values.rewardType,
                    "value": parseInt(values.rewardValue),
                    "limits": limitsArr
                },
                "endDate": finaledate,
                "type": values.type,
                "code": values.code,
                "loginRequired": values.loginRequired,
                "active": props.Campaign.active,
                "supported_app": values.supported_app,
                "desc": values.desc,
                "title": values.title,
                "startDate": finalsdate,
                "campId": props.Campaign.campId
            };
            dataurl = `${domain}/betops/dl/campaign/update/v2.0`;
        }

        const response = await axios({
            method: 'post',
            url: dataurl,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: finalObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        if (!newCreateRoute) {
                            props.onClose();
                            props.getList();
                        } else {
                            navigate('/campaigns');
                        }
                    },
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    }

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                {props.innerRoute ?
                    <></>
                    :
                    <Sidermenu menu="create_campaigns" />
                }
                <Layout className="site-layout">
                    {props.innerRoute ?
                        <></>
                        :
                        <Headersection />
                    }
                    <Content className="site-layout-background" style={{
                        margin: props.innerRoute ? "none" : "24px 16px 0",
                        minHeight: props.innerRoute ? "0" : 280
                    }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title={props.Flag === "Edit Campaign" ? "Edit Campaign" : "Add Campaign"}
                                />
                                {keyData ?
                                    <>
                                        <Form className="form-align-cls" form={props.campaignform} layout="vertical" onFinish={onFinish}>
                                            <Row gutter={24}>

                                                {props.Flag == "Edit Campaign" && newKeys != null ?
                                                    <>
                                                        <Col span={8}></Col>
                                                        <Col span={8} style={{ margin: "10px 0" }}>
                                                            <div style={{ padding: "0 12px 0 12px", border: "1px solid #e3e3e3", position: "relative" }}>
                                                                <h6 style={{ color: "rgb(0, 136, 204)", marginTop: "1rem" }}>Web Url</h6>
                                                                <p style={{ wordWrap: "break-word" }}>{newKeys.webUrl}</p>
                                                                <CopyToClipboard text={newKeys.webUrl}>
                                                                    <CopyOutlined style={{ cursor: "pointer", position: "absolute", top: "16px", right: "12px", fontSize: "20px", color: "rgb(0, 136, 204)" }} />
                                                                </CopyToClipboard>
                                                            </div>
                                                        </Col>
                                                        <Col span={8}></Col>
                                                        {/* <Col span={12} style={{ margin: "10px 0" }}>
                                                            <div style={{ padding: "0 12px 0 12px", border: "1px solid #e3e3e3", position: "relative" }}>
                                                                <h6 style={{ color: "rgb(0, 136, 204)", marginTop: "1rem" }}>Firebase Url</h6>
                                                                <p style={{ wordWrap: "break-word" }}>{newKeys.fireBaseUrl}</p>
                                                                <CopyToClipboard text={newKeys.fireBaseUrl}>
                                                                    <CopyOutlined style={{ cursor: "pointer", position: "absolute", top: "16px", right: "12px", fontSize: "20px", color: "rgb(0, 136, 204)" }} />
                                                                </CopyToClipboard>
                                                            </div>
                                                        </Col> */}
                                                    </>
                                                    : ""}
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item
                                                        name="code"
                                                        label="Code"
                                                        rules={[{ required: true, message: 'Please input your code!' }]}
                                                    >
                                                        <Input placeholder="Enter Code" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item
                                                        name="title"
                                                        label="Title"
                                                        rules={[{ required: true, message: 'Please input your title!' }]}
                                                    >
                                                        <Input placeholder="Enter Title" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} style={{ textAlign: 'left' }}>
                                                    <Form.Item
                                                        name="desc"
                                                        label="Description"
                                                        rules={[{ required: true, message: 'Please input your description!' }]}
                                                    >
                                                        <Input placeholder="Enter Description" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item name="type" label="Campaign Type" rules={[{ required: true, message: "Please select campaign type!" }]}>
                                                        <Select size="default" placeholder="Select Campaign Type" style={{ width: "100%" }}>
                                                            {keyData.campaign_type.map((item, index) => (
                                                                <Option key={index} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item name="supported_app" label="Supported App" rules={[{ required: true, message: "Please select supported app!" }]}>
                                                        <Select size="default" mode="multiple" placeholder="Select Supported App" style={{ width: "100%" }}>
                                                            {keyData.supported_app.map((item, index) => (
                                                                <Option key={index} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: "Please select start date!" }]}>
                                                        <DatePicker
                                                            showTime
                                                            format="DD-MM-YYYY hh:mm a"
                                                            onOk={sDateOk}
                                                        // defaultValue={props && props.Campaign && props.Campaign.startDate ? changeLocalTime(props.Campaign.startDate) : ""}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: "Please select end date!" }]}>
                                                        <DatePicker
                                                            showTime
                                                            format="DD-MM-YYYY hh:mm a"
                                                            onOk={eDateOk}
                                                        // defaultValue={props && props.Campaign && props.Campaign.endDate ? changeLocalTime(props.Campaign.endDate) : ""}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item label="Login Required" name="loginRequired" valuePropName="checked" rules={[{ required: true, message: 'Please provide your choice!' }]}>
                                                        <Checkbox onChange={onChangeLoginRequired}>
                                                            {checked ?
                                                                "Yes"
                                                                : "No"}
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item name="rewardType" label="Reward Type" rules={[{ required: true, message: "Please select reward type!" }]}>
                                                        <Select size="default" placeholder="Select Reward Type" style={{ width: "100%" }}>
                                                            {keyData.reward_type.map((item, index) => (
                                                                <Option key={index} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Form.Item
                                                        name="rewardValue"
                                                        label="Reward Value"
                                                        rules={[{ required: true, message: 'Please input your reward value!' }]}
                                                    >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Enter Reward Value" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24} style={{ textAlign: 'left' }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p style={{ fontSize: "20px", fontWeight: "600", marginBottom: "5px", color: "#444 !important" }}>Reward Limits</p>
                                                        </div>
                                                        <div className="col-md-6 text-end">
                                                            <a onClick={() => addLimit()}>
                                                                <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <Table
                                                        key={Date.now()}
                                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                                        dataSource={limitsArr}
                                                        scroll={{ y: "340" }}
                                                    >
                                                        <Column title="Type" dataIndex="type" key="type" />
                                                        <Column title="Value" dataIndex="value" key="value"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {record.type == "once_per_user" ? (
                                                                        <span>
                                                                            Not Applicable
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            {text}
                                                                        </>
                                                                    )}
                                                                </Space>
                                                            )}
                                                        />
                                                        <Column
                                                            title="Quick Actions"
                                                            key="rowInfo"
                                                            render={(rowInfo, value, index) => (
                                                                <Space size="middle">
                                                                    <EditTwoTone onClick={() => editLimit(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                                    <DeleteOutlined onClick={() => deleteLimit(rowInfo, index)} title="Delete Limit" style={{ fontSize: "18px", color: "red" }} />
                                                                </Space>
                                                            )}
                                                        />
                                                    </Table>

                                                </Col>
                                            </Row>
                                            {/* <Row gutter={24} className="rtmp1"> */}
                                            <br />

                                            {/* <Form.Item {...tailFormItemLayout}> */}
                                            <center>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    {props.Flag === "Edit Campaign" ? "Update" : "Add"}
                                                </Button>&nbsp;&nbsp;
                                                {!newCreateRoute ?
                                                    <Button type="danger" onClick={props.onClose}>Cancel</Button>
                                                    : ""}
                                            </center>
                                            {/* </Form.Item> */}
                                        </Form>
                                        <Drawer title={drawertype} placement="right" onClose={drawerClose} visible={visible} size="small">
                                            <Form style={{ marginTop: "10px" }} className="form-align-cls" form={limitform} layout="vertical" onFinish={onFinish1}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item name="type" label="Type" rules={[{ required: true, message: "Please select type!" }]}>
                                                            <Select onChange={handlelimittype} size="default" placeholder="Select Type" style={{ width: "100%" }}>
                                                                {keyData.limit_type.map((item, index) => (
                                                                    <Option key={index} value={item}>
                                                                        {item}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    {limitType != "once_per_user" ?
                                                        <Col span={24} style={{ textAlign: 'left' }}>
                                                            <Form.Item
                                                                name="value"
                                                                label="Value"
                                                                rules={[{ required: true, message: 'Please input your value!' }]}
                                                            >
                                                                <InputNumber style={{ width: "100%" }} placeholder="Enter Value" />
                                                            </Form.Item>
                                                        </Col>
                                                        : ""}

                                                    <Col span={24}>
                                                        <Form.Item>
                                                            <center><Button
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                Save
                                                            </Button></center>
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Drawer>
                                    </>
                                    : ""}
                            </Card>
                        </div>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}

                    </Content>
                    {props.innerRoute ?
                        <></>
                        :
                        <Footersection />
                    }
                </Layout>
            </>
        </Layout >
    )
}

export default AddCampaign;