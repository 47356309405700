import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, getDoc, updateDoc, query, where, orderBy, limit } from "firebase/firestore";
import { Select, Layout, Card, Checkbox, Row, Col, Popover, Table, Tag, Space, Button, Dropdown, Modal, Drawer, Divider, PageHeader, Spin } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Editbets from "./editbets";
import Addbets from "./addbets";
import BetsBulkupdate from "./betsBulkUpdate";
import Moment from "react-moment";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";

import {
  BellFilled,
  PlusOutlined,
  EditTwoTone,
  EditOutlined,
  DeleteTwoTone,
  DashOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option, OptGroup } = Select;
const { Header, Sider, Footer, Content } = Layout;
const { Column } = Table;
function Betlist() {
  const [loaded, setLoaded] = useState(true);
  const winningarray = ["WIN", "LOSS"];
  const betmode = ["GENERAL", "CUSTOM"];
  const [largeDrawervisible, setLargeDrawervisible] = useState(false);
  const [normalDrawervisible, setNormalDrawervisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [allMatchList, setAllMatchList] = useState([]);
  const [editmatchid, setEditmatchid] = useState([]);
  const [teaminfo, setTeaminfo] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [specificMatchBets, setSpecificMatchBets] = useState(null);
  const [tournamentValue, setTournamentValue] = useState(currentTournamentDetails.shortName);
  const [matchesValue, setMatchesValue] = useState("Select Matches");
  const [seasonType, setSeasonType] = useState("REGULAR");
  const [seasonyearValue, setSeasonyearValue] = useState(2023);
  const [modaltitle, setModaltitle] = useState("");
  const [matchrow, setMatchrow] = useState([]);
  const [title, setTitle] = useState("");
  const [largeDrawertype, setLargeDrawertype] = useState("");
  const [normalDrawertype, setNormalDrawertype] = useState("");
  const [open, setOpen] = useState(false);

  const [dbTournamentList, setDbTournamentList] = useState([]);
  const [dbSeasonList, setDbSeasonList] = useState([]);
  const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

  const showDrawer = (matchid) => {
    setLargeDrawertype("editbet");
    setEditmatchid(matchid);
    setTitle(matchid.matchtitle + " Bet - " + matchid.betNum);
    setLargeDrawervisible(true);
  };

  const showContent = () => {
    return (
      <div>
        <p>
          <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center", marginRight: "15px" }} />
          <a onClick={() => addnew()}>
            <span className="addbet">New Bet</span>
          </a>
        </p>
        <p>
          <EditOutlined style={{ fontSize: "16px", color: "#08c", textAlign: "center", marginRight: "15px" }} />
          <a onClick={() => bulkupdate()}>
            <span className="addbet">Bulk Update</span>
          </a>
        </p>
        {/* <ul>
          <li>
           
          </li>
        </ul> */}

      </div >

    );
  };

  const openPopover = () => {
    setOpen(true);
  }

  const handleVisibleChanges = () => {
    setOpen(!open);
  }

  // const moreDropdown = [
  //   {
  //     key: '1',
  //     label: (
  //       <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
  //         1st menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
  //         2nd menu item
  //       </a>
  //     ),
  //   }
  // ];

  const onClose = () => {
    setLoaded(false);
    setLargeDrawervisible(false);
    setNormalDrawervisible(false);
  };

  const addnew = () => {
    if (tournamentValue !== "Select Tournaments" && seasonyearValue !== "Select Year" && matchesValue !== "Select Matches" && seasonyearValue !== "" && matchesValue !== "") {
      setLargeDrawertype("addbet");
      setTitle(tournamentValue + " - " + seasonyearValue);
      setLargeDrawervisible(true);
    } else {
      Modal.error({
        content: "Please Select Tournaments and Matches",
      });
    }
    setOpen(false);
  };

  const bulkupdate = () => {
    setNormalDrawertype("bulkupdate");
    setTitle("Bulk Update");
    setNormalDrawervisible(true);
    setOpen(false);
  }

  const initialFunction = async (val, sYear, sType) => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${tournamentValue}`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        let config21 = response.data.data.yearsList;
        setDbSeasonList(config21);

        let config31 = response.data.data.seasonsList;
        setDbSeasonTypeList(config31);
      }
      else {
      }
    }).catch((error) => {
    })

    setMatchesValue("");
    setMatchDetails([]);
    const finaldata = val
      .filter(function (item) {
        return item.tournament == tournamentValue && item.year == sYear;
      })
      .map(function (item) {
        return item;
      });

    const tableData = [];
    const playoffData = [];
    finaldata.map(function (element, mid) {
      let i = 1;
      if (element.playOff !== undefined) {
        const list = element.playOff.map(function (playoff, mid3) {
          playoffData.push({
            startdate: element.begins,
            year: element.year,
            matchtitle: playoff.rt + " vs " + playoff.ht,
            tourID: element.tourID,
            matchno: playoff.matchId.slice(4, playoff.matchId.length),
            tournament: element.tournament,
            tournamenttitle: element.tournamentTitle,
            hticon: playoff.ic,
            bets: playoff.bets,
            ht: playoff.ht,
            rt: playoff.rt,
            matchid: playoff.matchId,
            matchTs: playoff?.matchTs?.seconds,
            // documentID: element.documentID,
            mtype: "playoff",
          });
        });
      }
      if (element.htl !== undefined) {
        const list = element.htl.map(function (element1, mid1) {
          const list2 = element1.rtl.map(function (element2, mid2) {
            tableData.push({
              startdate: element.begins,
              year: element.year,
              matchtitle: element2.rt + " vs " + element2.ht,
              tourID: element.tourID,
              matchno: element2.matchId.slice(4, element2.matchId.length),
              tournament: element.tournament,
              tournamenttitle: element.tournamentTitle,
              hticon: element1.ic,
              bets: element2.bets,
              ht: element2.ht,
              rt: element2.rt,
              matchid: element2.matchId,
              // matchTs: element2?.matchTs?.seconds,
              matchTs: element2.matchTs,
              // documentID: element.documentID,
              mtype: "schedule",
            });
          });
        });
      }
    });
    var array3 = tableData.concat(playoffData);
    let data1 = []
    for (let i = 0; i < array3.length; i++) {
      if (array3[i].startdate)
        data1.push(array3[i])
    }
    let finalteam = await SortandRemoveDuplicateObject(data1);
    setTeaminfo(finalteam);
    setMatchesValue(finalteam[0].matchid);
    await handleMatches(finalteam[0].matchid, finalteam, sYear, sType);
  }

  const SortandRemoveDuplicateObject = async (val) => {
    const sortByKey = (key) => (a, b) => a[key] - b[key];
    // const sortByMatchid = sortByKey("matchid");
    const sortByMatchid = sortByKey("matchno");
    const sortval = val.sort(sortByMatchid);
    const uniqueIds = [];
    const unique = sortval.filter(element => {
      const isDuplicate = uniqueIds.includes(element.matchid);
      if (!isDuplicate) {
        uniqueIds.push(element.matchid);
        return true;
      }
      return false;
    });
    return unique;
  }

  // const fetchTeams = async () => {
  //   const teamsCol = collection(db, "teams");
  //   const teamsSnapshot = await getDocs(teamsCol);
  //   const teamList = teamsSnapshot.docs.map((doc) => doc.data());
  //   setTeams(teamList);
  // };

  const handleTournament = async (e) => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        let config21 = response.data.data.yearsList;
        setDbSeasonList(config21);

        let config31 = response.data.data.seasonsList;
        setDbSeasonTypeList(config31);
      }
      else {
      }
    }).catch((error) => {
    })

    await fetchTournamentBasedSchedule(e, seasonyearValue, seasonType);
    setSeasonyearValue("");
    setSeasonType("");
    setMatchesValue("");
    setMatchDetails([]);
    setTournamentValue(e);
  };
  const handleSeason = async (e) => {
    await fetchTournamentBasedSchedule(tournamentValue, e, seasonType);
    setSeasonType("");
    setMatchesValue("");
    setMatchDetails([]);
    setSeasonyearValue(e);
  };

  const handleSeasonType = async (e) => {
    setSeasonType(e);
    setMatchesValue("");
    setMatchDetails([]);
    await fetchTournamentBasedSchedule(tournamentValue, seasonyearValue, e);
  };

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const sortArray = (type) => {
    const types = {
      matchid: "matchid",
      tournamenttitle: "tournamenttitle",
      matchno: "matchno",
      ht: "ht",
      rt: "rt",
    };
    const sortProperty = types[type];
    const sorted = [...matchdetails].sort((a, b) => {
      return b[sortProperty] - a[sortProperty];
    });

    setMatchDetails(sorted);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // 
    sortArray(sorter.field);
    if (sorter.order == undefined) {
      setMatchDetails(matchdetails.reverse());
    } else if (sorter.order == "descend") {
      sortArray(sorter.field);
    } else {

      setMatchDetails(matchdetails.reverse());
    }
  };

  const handleMatches = async (e, data1, sYear, sType) => {
    setLoaded(true);
    setMatchesValue(e);
      if (data1 == "") {
        e = teaminfo.filter(list => list.matchid == e)
      } else {
        e = data1.filter(list => list.matchid == e)
      }
      e = e[0]
      let finaldata = null;
      const response = await axios({
        method: 'get',
        url: `${domain}/betops/bet/schedule/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tournamentValue}&season=${sYear}&seasonType=${sType}&matchId=${e.matchid}`,
        headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
      }).then((response) => {
        if (response.data.status === "SUCCESS") {
          setSpecificMatchBets(response.data.data.betMatch);
          let temp = [];
          temp.push(response.data.data.betMatch);
          finaldata = temp;
          const tableData = [];
          const finaldata1 = finaldata.map(function (element, mid) {
            let i = 1;
            if (element.bets !== undefined) {
              const list2 = element.bets.betList.map(function (element2, mid2) {
    
                tableData.push({
                  startdate: e.startdate,
                  winnerActual: element2.winnerActual,
                  betLegend: element2.betLegend,
                  oddsVersion: element2.oddsVersion,
                  betMode: element2.betMode,
                  betType: element2.betType,
                  winnerActualFavOrDog: element2.winnerActualFavOrDog,
                  betNum: element2.betNum,
                  year: element.year,
                  matchtitle: e.matchtitle,
                  tourID: e.tourID,
                  matchno: e.matchno,
                  tournament: e.tournament,
                  tournamenttitle: e.tournamenttitle,
                  hticon: e.hticon,
                  ht: e.ht,
                  rt: e.rt,
                  matchid: e.matchid,
                  rticon: e.rticon,
                  matchTs: e.matchTs,
                  subLabel: element2.subLabel,
                  betinfo: element2.sideList,
                  mtype: e.mtype,
                });
              });
            }
          });
          setMatchDetails(tableData);
        }
        else {
          if (response.data.error.message == "Auth token is not valid") {
            Modal.error({
              content: response.data.error.message,
              onOk() {
                logout();
              }
            });
          } else {
            Modal.error({
              content: response.data.error.message
            });
          }
        }
      })
        .catch((error) => {
          Modal.error({
            content: "Unable to process your Request, Please try again later."
          });
        })
      setLoaded(false);
  };

  const onSearch = (e) => { };
  const toLower = (value) => {
    return value.toLowerCase();
  };

  const fetchTournamentBasedSchedule = async (val1, val2, val3) => {
    setLoaded(true);
    const response = await axios({
      method: 'get',
      // url: `${domain}/betops/bet/get/match-list/v2.0?tournament=${val1}&season=${val2}&seasonType=${val3}&appId=${currentTournamentDetails.shortCode}-ops`,
      url: `${domain}/betops/match/get/all-match-list/v1?tournament=${val1}&season=${val2}&seasonType=${val3}`,
      // headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setAllMatchList(response.data.data.matchList);
        let temp = [];
        temp.push(response.data.data.schedule);
        await initialFunction(temp, val2, val3);
      } else {
        setLoaded(false);
      }
    })
      .catch((error) => {
        setLoaded(false);
      })
  }

  const sportConfigSetting = () => {
    let config11 = localStorage.getItem("sportsList")
    let config12 = config11.split(",");
    setDbTournamentList(config12);

    let config21 = localStorage.getItem("sportYearsList")
    let config22 = config21.split(",");
    setDbSeasonList(config22);

    let config31 = localStorage.getItem("sportSeasonsList")
    let config32 = config31.split(",");
    setDbSeasonTypeList(config32);
  }

  const createOrUpdateBet = async (infoObj) => {
    setLoaded(true);
    infoObj.appId = `${currentTournamentDetails.shortCode}-ops`;
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/bet/update/v2.0`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
      data: infoObj
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        Modal.success({
          content: response.data.data.message,
          onOk() {
            onClose();
            fetchTournamentBasedSchedule(tournamentValue, seasonyearValue, seasonType);
          }
        });
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  }

  const updateMatchdetails = async () => { 
    await fetchTournamentBasedSchedule(tournamentValue, seasonyearValue, seasonType);
  }

  const deleterecord = async (matchid) => {
    setLoaded(true);
    let mainarray = [];
    mainarray.push(specificMatchBets);
    const final = mainarray[0].bets.betList.filter((number) => {
      return number.betNum !== matchid.betNum;
    });
    mainarray[0].bets.betList = final
    let obj = {};
    mainarray.forEach(function (val, i) {
      obj = {
        val,
      };
    });
    createOrUpdateBet(obj.val);
  };

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(async () => {
    await fetchTournamentBasedSchedule(tournamentValue, seasonyearValue, seasonType);

    sportConfigSetting();
    if (loading) return;
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    // if (loaded) {
    //   setTimeout(() => {
    //     setLoaded(false);
    //   }, 2000);
    // }
    // fetchTeams();
  }, [loaded]);
  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="betlist" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Bets"
                  extra={[
                    //  onChange={(e) => handleTournament(e)}
                    <Select value={tournamentValue} size="default" onChange={handleTournament} placeholder="Select Tournaments" style={{ width: "200px" }}>
                      {dbTournamentList.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}{" "}
                        </Option>
                      ))}
                    </Select>,
                    <>
                      <Select value={seasonyearValue} onChange={(e) => handleSeason(e)} size="default" placeholder="Select Season" style={{ width: "200px" }}>
                        {dbSeasonList.map((item1, index1) => (
                          <Option key={index1} value={item1}>
                            {item1}{" "}
                          </Option>
                        ))}
                      </Select>

                      <Select value={seasonType} size="default" placeholder="Select SeasonType" style={{ width: "200px" }} onChange={(e) => handleSeasonType(e)}>
                        {dbSeasonTypeList.map((item2, index2) => (
                          <Option key={index2} value={item2}>
                            {item2}{" "}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        size="default"
                        value={matchesValue}
                        placeholder="Select Matches"
                        style={{ width: "200px" }}
                        showSearch
                        filterOption={(input, option) => {

                          if (option.props.value !== null) {
                            return (
                              (option.props.label !== null && toLower(option.props.label).includes(toLower(input))) ||
                              (option.props.key !== null && toLower(option.props.key).includes(toLower(input)))
                            );
                          } else {
                            return false;
                          }
                        }}
                        onSearch={(e) => onSearch(e)}
                        onChange={(e) => handleMatches(e, "", seasonyearValue, seasonType)}
                      >
                        {teaminfo.map((info, i) => {
                          return (
                            <Option key={i} value={info.matchid} label={info.matchtitle}>
                              {info.matchno} - {info.matchtitle}{" "}
                            </Option>
                          );
                        })}
                        {/* <OptGroup label="Schedule">
                          {teaminfo.map((info, i) => {
                            if (info.mtype == "schedule") {
                              return (
                                <Option key={i} value={info.matchid} label={info.matchtitle}>
                                  {info.matchtitle}{" "}
                                </Option>
                              );
                            }
                          })}
                        </OptGroup>
                        <OptGroup label="Playoff">
                          {teaminfo.map((infop, k) => {
                            if (infop.mtype == "playoff") {
                              return (
                                <Option key={k} value={infop.matchid} label={infop.matchtitle}>
                                  {infop.matchtitle}{" "}
                                </Option>
                              );
                            }
                          })}
                        </OptGroup> */}
                      </Select>


                      {/* <Dropdown
                        menu={{
                          moreDropdown,
                        }}
                        placement="bottom"
                        arrow
                      >
                        <DashOutlined />
                      </Dropdown> */}

                      <Popover
                        content={() => showContent()}
                        trigger="click"
                        placement="bottomRight"
                        onOpenChange={handleVisibleChanges}
                        open={open}
                      >
                        <DashOutlined onClick={() => openPopover()} />
                      </Popover>




                    </>,
                  ]}
                />
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  dataSource={matchdetails}
                  onChange={handleTableChange}
                  scroll={{ y: "250" }}
                >
                  <Column title="Match No" dataIndex="matchno" key="matchno" />
                  <Column title="Bet No" dataIndex="betNum" key="betNum" sorter="true" />
                  <Column title="Bet Title" dataIndex="subLabel" key="subLabel" style={{ width: "200px" }} />
                  <Column title="Match Title" dataIndex="matchtitle" key="matchtitle" />
                  <Column
                    title="Match Date"
                    dataIndex="matchTs"
                    key="matchTs"
                    render={(text, recod) => (
                      <Space size="middle">
                        {text !== "Invalid date" ? (
                          <Moment unix format="DD-MM-YYYY hh:mm a">
                            {text._seconds}
                          </Moment>
                        ) : (
                          ""
                        )}
                      </Space>
                    )}
                  />
                  <Column title="Bet Mode" dataIndex="betMode" key="betMode" />

                  <Column
                    title="Quick Actions"
                    key="matchId"
                    render={(matchId) => (
                      <Space size="middle">
                        <EditTwoTone onClick={() => showDrawer(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        <DeleteTwoTone onClick={() => deleterecord(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        {/* <a onClick={() => notify(matchId)}>
                          <BellFilled style={{ fontSize: "18px", color: "#08c" }} />
                        </a> */}
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>

            <Drawer title={title} placement="right" onClose={onClose} visible={largeDrawervisible} size="large">
              {largeDrawertype == "editbet" ? (
                <Editbets
                  matchid={editmatchid}
                  winningarray={winningarray}
                  betmode={betmode}
                  onClose={onClose}
                  updateMatchdetails={updateMatchdetails}
                  specificMatchBets={specificMatchBets}
                />
              ) : (
                <Addbets
                  // teaminfo={teams}
                  teams={tournamentValue}
                  matchtitle={seasonyearValue}
                  matchdetails={matchdetails}
                  winningarray={winningarray}
                  betmode={betmode}
                  onClose={onClose}
                  updateMatchdetails={updateMatchdetails}
                  specificMatchBets={specificMatchBets}
                />
              )}
            </Drawer>


            {normalDrawertype == "bulkupdate" ? (
              <Drawer title={title} placement="right" onClose={onClose} visible={normalDrawervisible}>
                <BetsBulkupdate doctype="BETODDS" schedulereload={updateMatchdetails} onClose={onClose} />
              </Drawer>
            ) : (
              <></>
            )}


            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}

            {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Betlist;
