import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin, Modal, DatePicker, Table, Space, Form, Input } from "antd";
import { db, currentTournamentDetails, logout } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  SyncOutlined
} from "@ant-design/icons";
import { reload } from "firebase/auth";

const { Option } = Select;
const { Content } = Layout;
const { Column } = Table;
function CampaignAnalytics() {
  const [loaded, setLoaded] = useState(true);

  const [typeSelectedValue, setTypeSelectedValue] = useState("year_wise");
  const [sdateSelectedValue, setSdateSelectedValue] = useState(null);
  const [edateSelectedValue, setEdateSelectedValue] = useState(null);
  const [campIdSelectedValue, setCampIdSelectedValue] = useState(null);

  const [analyticsInfoArr, setAnalyticsInfoArr] = useState(null);

  const [tournamentValue, setTournamentValue] = useState(currentTournamentDetails.shortName);
  // const [seasonValue, setSeasonValue] = useState(2023);
  // const [seasonTypeValue, setSeasonTypeValue] = useState("PRE");

  const [campaignConfigs, setCampaignConfigs] = useState(null);
  const [campaignAnalytics, setCampaignAnalytics] = useState(null);
  const [userChartDropdown, setUserChartDropdown] = useState("login");
  const [userChartValue, setUserChartValue] = useState(null);

  const [transChartValue, setTransChartValue] = useState(null);

  const [funnymoneyChartDropdown, setFunnymoneyChartDropdown] = useState(null);
  const [funnymoneyChartDropdownList, setFunnymoneyChartDropdownList] = useState(null);
  const [funnymoneyChartValue, setFunnymoneyChartValue] = useState(null);

  const [transTabKey, setTransTabKey] = useState(null);
  const [transWeekKey, setTransWeekKey] = useState(null);

  const [tabList, setTabList] = useState(null);
  const [weekList, setWeekList] = useState(null);

  const [currentSeasonTransCount, setCurrentSeasonTransCount] = useState(null);

  const [transactionDetail, setTransactionDetail] = useState(null);

  const { TabPane } = Tabs;

  useEffect(async () => {
    localStorage.setItem("durationType", typeSelectedValue);
    await fetchCampaignConfig();
    await fetchCampaignAnalytics();
    await getAnalyticsInfo(typeSelectedValue, sdateSelectedValue, edateSelectedValue, campIdSelectedValue);
  }, []);

  const reloadCharts = async () => {
    fetchCampaignAnalytics();
    getAnalyticsInfo(typeSelectedValue, sdateSelectedValue, edateSelectedValue, campIdSelectedValue);
  }

  const fetchCampaignConfig = async () => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/dl/campaign/get-data/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tournamentValue}`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setCampaignConfigs(response.data.data);
        // setLoaded(false);
      }
      else {
        setLoaded(false);
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
  }

  const fetchCampaignAnalytics = async () => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/dl/campaign/get-overall-analytics/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setCampaignAnalytics(response.data.data);
      }
      else {
        setLoaded(false);
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
  }


  const transactionDetails = (data) => {
    setTransactionDetail(<Col span={24}>
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        options={{ legend: 'none', isStacked: true }}
        data={transBardiagramMapping(data)}
      />
    </Col>)
  }

  const userChartMapping = (data) => {
    let items = {};
    if (data && data.length > 0) {
      for (var j = 0; j < data.length; j++) {
        let tempLine = null;

        let startInfo = new Date(data[j].start);
        // let startInfo = data[j].start.toString();
        // let startData1 = startInfo.split("-");
        // let startData2 = startData1[2].split("T");
        // let startData3 = startData2[1].split(":");


        if (localStorage.getItem("durationType") == "year_wise") {
          tempLine = startInfo.getFullYear();
        } else if (localStorage.getItem("durationType") == "month_wise") {
          tempLine = startInfo.getMonth()+1;
        } else if (localStorage.getItem("durationType") == "date_wise") {
          tempLine = startInfo.getDate();
        } else if (localStorage.getItem("durationType") == "time_wise") {
          if (data[j].end) {
            let endInfo = new Date(data[j].end);
            // let endInfo = data[j].end.toString();
            // let endData1 = endInfo.split("-");
            // let endData2 = endData1[2].split("T");
            // let endData3 = endData2[1].split(":");
            // tempLine = `${startData3[0]}:${startData3[1]}-${endData3[0]}:${endData3[1]}`;
            let ampm = startInfo.getHours() >= 12 ? 'pm' : 'am';
            tempLine = `${startInfo.getHours() % 12 || 12}:${startInfo.getMinutes()} ${ampm} - ${endInfo.getHours() % 12 || 12}:${endInfo.getMinutes()} ${ampm}`;
          }
        }

        items[`${tempLine}`] = {
          totalCount: data[j].totalCount
        }
      }

      if (items != null || items != undefined) {
        let allKeys = Object.keys(items);
        let allKeysVal = Object.values(items);
        let tempArr = [];
        for (const [key, value] of Object.entries(items)) {
          let test = {
            [key]: Object.values(value)[0]
          };
          tempArr.push(test);

        }
        items = tempArr.reduce(function (result, current) {
          return Object.assign(result, current);
        }, {})
      }

      const fieldingDismissalsSeasonWise = [["Element", "Element", { role: 'annotation' }]];
      let item = items
      for (var j in item) {
        let typestore = localStorage.getItem("durationType");
        let temp = typestore.split("_");
        let label = `${temp[0]} - ${j}`;
        if (j === "total") {

        }
        else {
          // fieldingDismissalsSeasonWise.push([
          //   j,
          //   items[j],
          //   j,
          // ]);
          fieldingDismissalsSeasonWise.push([
            label,
            items[j],
            j,
          ]);
        }

      }
      return fieldingDismissalsSeasonWise;
    }
  }

  const transChartMapping = (items) => {
    if (items != null || items != undefined) {
      let allKeys = Object.keys(items);
      let allKeysVal = Object.values(items);
      let tempArr = [];
      // for (const [key, value] of Object.entries(items)) {
      for (let i = 0; i < items.length; i++) {
        let test = {
          [items[i].type]: items[i].trans_count
        };
        tempArr.push(test);

      }
      items = tempArr.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
    }

    const fieldingDismissalsSeasonWise = [["Element", "Element", { role: 'annotation' }]];
    let item = items
    for (var j in item) {
      if (j === "total") {

      }
      else {
        fieldingDismissalsSeasonWise.push([
          j,
          items[j],
          j,
        ]);
      }

    }
    return fieldingDismissalsSeasonWise;
  }

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const transBardiagramMapping = (data) => {
    if (data && data.length > 0) {
      // setTimeout(() => {   
      // const fieldingDismissalsSeasonWise = [["Element", "Value", { role: 'annotation' }]];
      const fieldingDismissalsSeasonWise = [];
      fieldingDismissalsSeasonWise.push(["Element", "Value", { role: 'annotation' }]);
      for (var j = 0; j < data.length; j++) {

        let tempLine = null;

        let startInfo = new Date(data[j].start);
        // let startInfo = data[j].start.toString();
        // let startData1 = startInfo.split("-");
        // let startData2 = startData1[2].split("T");
        // let startData3 = startData2[1].split(":");

        if (localStorage.getItem("durationType") == "year_wise") {
          tempLine = startInfo.getFullYear();
        } else if (localStorage.getItem("durationType") == "month_wise") {
          tempLine = startInfo.getMonth()+1;
        } else if (localStorage.getItem("durationType") == "date_wise") {
          tempLine = startInfo.getDate();
        } else if (localStorage.getItem("durationType") == "time_wise") {
          if (data[j].end) {
            let endInfo = new Date(data[j].end);
            // let endInfo = data[j].end.toString();
            // let endData1 = endInfo.split("-");
            // let endData2 = endData1[2].split("T");
            // let endData3 = endData2[1].split(":");
            // tempLine = `${startData3[0]}:${startData3[1]}-${endData3[0]}:${endData3[1]}`;
            let ampm = startInfo.getHours() >= 12 ? 'pm' : 'am';
            tempLine = `${startInfo.getHours() % 12 || 12}:${startInfo.getMinutes()} ${ampm} - ${endInfo.getHours() % 12 || 12}:${endInfo.getMinutes()} ${ampm}`;
          }
        }

        let finalVal = tempLine;

        let typestore = localStorage.getItem("durationType");
        let temp = typestore.split("_");
        let label = `${temp[0]} - ${finalVal}`;

        // fieldingDismissalsSeasonWise.push([
        //   finalVal,
        //   data[j].totalCount,
        //   data[j].totalCount,
        // ]);

        fieldingDismissalsSeasonWise.push([
          label,
          data[j].totalCount,
          data[j].totalCount,
        ]);
      }
      return fieldingDismissalsSeasonWise
      // }, 2000);
    } else {
      const fieldingDismissalsSeasonWise = [["Element", "Value", { role: 'annotation' }]];
      fieldingDismissalsSeasonWise.push([
        0,
        0,
        0,
      ]);
      return fieldingDismissalsSeasonWise
    }
  }

  const handleType = (e) => {
    setTypeSelectedValue(e);
    // setSdateSelectedValue(null);
    // setEdateSelectedValue(null); 
    localStorage.setItem("durationType", e);
    getAnalyticsInfo(e, sdateSelectedValue, edateSelectedValue, null);
  }

  const handleSdate = (e) => {
    setSdateSelectedValue(e);
    getAnalyticsInfo(typeSelectedValue, e, edateSelectedValue, null);
  }

  const handleEdate = (e) => {
    setEdateSelectedValue(e);
    getAnalyticsInfo(typeSelectedValue, sdateSelectedValue, e, null);
  }

  const handleCampId = (e) => {
    setCampIdSelectedValue(e);
    getAnalyticsInfo(typeSelectedValue, sdateSelectedValue, edateSelectedValue, e);
  }

  const makeBargraphTitle = () => {
    let line = null;
    if (typeSelectedValue == "year_wise") {
      line = "Year Based Bar Graph";
    } else if (typeSelectedValue == "month_wise") {
      line = "Month Based Bar Graph";
    } else if (typeSelectedValue == "date_wise") {
      line = "Date Based Bar Graph";
    } else if (typeSelectedValue == "time_wise") {
      line = "Time Based Bar Graph";
    }
    return line;
  }

  const makePiechartTitle = () => {
    let line = null;
    if (typeSelectedValue == "year_wise") {
      line = "Year Based Pie Chart";
    } else if (typeSelectedValue == "month_wise") {
      line = "Month Based Pie Chart";
    } else if (typeSelectedValue == "date_wise") {
      line = "Date Based Pie Chart";
    } else if (typeSelectedValue == "time_wise") {
      line = "Time Based Pie Chart";
    }
    return line;
  }

  const getAnalyticsInfo = async (type, sDate, eDate, campId) => {
    let finalObj = {};
    if (type) {
      finalObj.type = type;
    }

    if (sDate) {
      let sdate = sDate.format("YYYY-MM-DD HH:mm:ss");
      let finalsdate = new Date(sdate).toISOString();
      finalObj.startDate = finalsdate;
    } else {
      // let temp = campaignConfigs.year_list;
      // let sdate = `${temp.min()}-01-01 01:00:00`;
      let sdate = `2021-01-01`;
      let finalsdate = new Date(sdate).toISOString();
      finalObj.startDate = finalsdate;
    }

    if (eDate) {
      let edate = eDate.format("YYYY-MM-DD HH:mm:ss");
      let finaledate = new Date(edate).toISOString();
      finalObj.endDate = finaledate;
    } else {
      // let temp = campaignConfigs.year_list;
      // let edate = `${temp.max()}-01-01 01:00:00`;
      let edate = `2023-01-01`;
      let finaledate = new Date(edate).toISOString();
      finalObj.endDate = finaledate;
    }

    if (campId) {
      finalObj.campId = campId;
    }
    // finalObj.startDate = "2023-10-27T05:14:15.200Z";
    // finalObj.endDate = "2023-10-27T05:14:15.200Z";
    setLoaded(true);
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/dl/campaign/get-analytics-data/v2.0`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
      data: finalObj
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setAnalyticsInfoArr(response.data.data.dataList);
        setUserChartValue(response.data.data.dataList);
        transactionDetails(response.data.data.dataList);
        setLoaded(false);
      }
      else {
        setLoaded(false);
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          setAnalyticsInfoArr(null);
          setUserChartValue(null);
          transactionDetails(null);
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        setAnalyticsInfoArr(null);
        setUserChartValue(null);
        transactionDetails(null);
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
  }

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="campaign_analytics" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background site-layout-background1" style={{ margin: "0px 16px 0", minHeight: 280 }}>
            <Tabs defaultActiveKey="1" className="dashboard-tab" style={{ marginLeft: "-6px", marginRight: "-16px" }}>
              <TabPane tab="Campaign Analytics" key="1">
                {campaignAnalytics ? <Row gutter={16} className="mb-3">
                  <Col span={6}>
                    <Card bordered={false}>
                      <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{campaignAnalytics.total_active_campaign}</h2></div>
                      <div style={{ fontWeight: "600" }}>Total Active Campaigns</div>
                      <div id="copyright"></div>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card bordered={false}>
                      <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{campaignAnalytics.total_yesterday_hit}</h2></div>
                      <div style={{ fontWeight: "600" }}>Yesterday Hits</div>
                      <div id="copyright"></div>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card bordered={false}>
                      <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{campaignAnalytics.total_current_month_hit}</h2></div>
                      <div style={{ fontWeight: "600" }}>Total Hits For Current Month</div>
                      <div id="copyright"></div>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card bordered={false}>
                      <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>{campaignAnalytics.total_current_month_reward}</h2></div>
                      <div style={{ fontWeight: "600" }}>Total Rewards For Current Month</div>
                      <div id="copyright"></div>
                    </Card>
                  </Col>
                </Row> : ""}
                {campaignAnalytics && campaignAnalytics.active_camp_list ?
                  <Table dataSource={campaignAnalytics.active_camp_list} pagination={{ pageSize: 10 }} scroll={{ y: "250" }}>
                    <Column title="Campaign Id" dataIndex="campId" key="campId" sorter="true" />
                    <Column title="Campaign Name" dataIndex="campName" key="campName" sorter="true" />
                    <Column title="Total Hits" dataIndex="total_hit" key="total_hit" sorter="true" />
                    <Column title="Yesterday Hits" dataIndex="total_yesterday_hit" key="total_yesterday_hit" sorter="true" />
                    <Column
                      title="Expire Date"
                      dataIndex="expDate"
                      key="expDate"
                      render={(text, recod) => (
                        <Space size="middle">
                          {text !== "Invalid date" ? (
                            <Moment unix format="DD-MM-YYYY hh:mm a">
                              {text._seconds}
                            </Moment>
                          ) : (
                            ""
                          )}
                        </Space>
                      )}
                    />
                  </Table>
                  : ""}
                {campaignConfigs ?
                  <Form
                    layout="horizontal"
                    className="form-align-cls"
                  >
                    <Row gutter={24}>
                      <Col span={6}>
                        <Form.Item label="Type">
                          <Select size="default" value={typeSelectedValue} onChange={handleType} placeholder="Select Type">
                            {campaignConfigs.dropdown_list.map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Start Date">
                          <DatePicker
                            showTime
                            format="DD-MM-YYYY hh:mm a"
                            onOk={handleSdate}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="End Date">
                          <DatePicker
                            showTime
                            format="DD-MM-YYYY hh:mm a"
                            onOk={handleEdate}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Campaign Id">
                          <Select size="default" onChange={handleCampId} placeholder="Select Campaign Id">
                            {campaignConfigs.campList.map((item, index) => (
                              <Option key={index} value={item.campId}>
                                {item.code}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  : ""}
                <Row gutter={16} className="mb-3">
                  <Col span={24}>
                    {userChartValue != null ? <Card bordered={false}>
                      <div><span style={{ fontWeight: "600" }}>Chart Representation - <Moment format="DD/MM/YYYY">{new Date()}</Moment>- Now</span><span style={{ color: "#2196F3", float: "right" }}><span style={{ color: "rgba(0,0,0,.85)", fontWeight: "600", fontSize: "14px", marginRight: "10px" }}>Reload Chart </span><SyncOutlined onClick={reloadCharts} className="dashboard-refesh-icon" /></span></div>
                      <Divider style={{ borderBottom: "2px solid #2196F3", margin: "12px 0" }} />
                      <Row gutter={16} className="dashboard-chart-section">
                        <Col span={24} style={{ borderRight: "1px solid #bbbbbb61" }}>
                          <h5 style={{ textAlign: "center", color: "#0099f3", margin: "10px 0" }}>{makePiechartTitle()}</h5>
                          <Chart
                            width="100%"
                            height="400px"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={userChartMapping(userChartValue)}
                            options={{
                              pieHole: 0.3,
                              is3D: true
                            }}
                          />
                        </Col>
                      </Row>
                    </Card> : ""}
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <div><span style={{ fontWeight: "600" }}>Graph Representation - <Moment format="DD/MM/YYYY">{new Date()}</Moment>- Now</span><span style={{ color: "#2196F3", float: "right" }}><span style={{ color: "rgba(0,0,0,.85)", fontWeight: "600", fontSize: "14px", marginRight: "10px" }}>Reload Graph </span><SyncOutlined onClick={reloadCharts} className="dashboard-refesh-icon" /></span></div>
                      <Divider style={{ borderBottom: "2px solid #2196F3", margin: "12px 0" }} />
                      <h5 style={{ textAlign: "center", color: "#0099f3", margin: "20px 0 0 0" }}>{makeBargraphTitle()}</h5>
                      {/* <div>
                        {tabList ?
                          <Tabs onChange={handleTransTabChange}>
                            {tabList.map((item, index) => (
                              <TabPane tab={item.label} key={item.key}>
                                {transWeekKey && weekList ?
                                  <>
                                    {item.key != "quiz" && item.key != "predictSeason" ?
                                      <Select size="default" value={transWeekKey} style={{ width: "200px" }} onChange={handleTransWeekChange}>
                                        {weekList.map((item, index) => (
                                          <Option key={index} value={item.key}>
                                            {item.displayName}{" "}
                                          </Option>
                                        ))}
                                      </Select>
                                      : ""}
                                    <span style={{ color: "rgba(0,0,0,.85)", float: "right", fontWeight: "600" }}>Current Season Transaction Count : <span style={{ color: "#2196F3", fontWeight: "normal" }}>{currentSeasonTransCount}</span></span>
                                  </>
                                  : ""}
                              </TabPane>
                            ))}
                          </Tabs>
                          : ""}
                      </div> */}
                      {transactionDetail}
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Content>
          {
            loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )
          }
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default CampaignAnalytics;
