import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { Select, Spin, Layout, Input, Menu, Form, Card, Tabs, Table, Switch, Space, Button, Modal, Drawer, Row, Col, Divider, PageHeader } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import ReactJson from "react-json-view";
import axios from "axios";
import {
    PlusCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    CloseOutlined
} from "@ant-design/icons";
import { domain } from "../../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { TabPane } = Tabs;
function TeamList() {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [matchValue, setMatchValue] = useState(null);
    const [searchValue, setsearchValue] = useState(null);
    const [TournamentList, setTournamentList] = useState([]);
    const [Status, setStatus] = useState(true);
    const [TeamsList, setTeamsList] = useState([]);
    const [TeamsListFilter, setTeamsListFilter] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [drawertype, setDrawertype] = useState("");
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [DeleteBet, setDeleteBet] = useState("");
    const [TeamLogo, setTeamLogo] = useState(null);
    const [imgsrc, setimgsrc] = useState(null);

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const handleCancel = () => {
        setDeleteModalVisible(false)
    };
    const addnew = () => {

        setsearchValue(null)
        setDrawertype("New Team");
        form.resetFields();
        setTeamLogo(null)
        setImageFile(null)
        setimgsrc(null)
        setVisible(true);
    };
    const deleterecordModel = (matchid) => {
        setDeleteModalVisible(true)
        setDeleteBet(matchid)
    }
    const showDrawer = async (matchid) => {

        setsearchValue(null)
        setTeamLogo(null)
        setImageFile(null)
        setimgsrc(null)
        form.resetFields();
        form.setFieldsValue({
            fullName: matchid.fullName,
            name: matchid.name,
            tournament: matchValue,
            active: matchid.active,
        });
        setTeamLogo(`${domain}/betops/asset/get-by-name/v1?appId=${currentTournamentDetails.shortCode}-com&assetType=team&assetName=${matchid.name}`)        
        setStatus(currentStatus(matchid.active))
        setDrawertype("Edit Team");
        setVisible(true);
    };
    const deleterecord = async () => {
        setLoaded(true);
        setsearchValue(null)
        setDeleteModalVisible(false)

        let dataPayload = {
            "appId": `${currentTournamentDetails.shortCode}-ops`,
            "tournament": matchValue,
            "name": DeleteBet.name
        }
        const response = await axios.post(
            `${domain}/betops/teams/delete/v1`, dataPayload)
            .then((response) => {


                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.status,
                        onOk() {
                            fetchDashboard();
                        }
                    });
                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.status,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    };
    const onClose = () => {
        setsearchValue(null)
        setVisible(false);
    };
    const onSearch = (e) => {

        setsearchValue(e.target.value)
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = TeamsList.filter((item) => {
                return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
            });
            setTeamsList(filteredData);
        } else {
            let data = TeamsListFilter.filter(list => list.tournament === matchValue)
            if (data.length > 0) {
                setTeamsList(data[0].teams)
            }
            else {
                setTeamsList([])
            }
        }
    }
    const fetchDashboard = async () => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/teams/list/v1`
        }).then((response) => {
            setLoaded(false);
            if (response.data.status === "SUCCESS") {

                setTeamsListFilter(response.data.data)
                let TourList = []
                for (let i = 0; i < response.data.data.length; i++) {
                    TourList.push(response.data.data[i].tournament)
                    if (i === 0) {
                        setMatchValue(response.data.data[i].tournament)
                        setTeamsList(response.data.data[i].teams)
                    }
                }
                setTournamentList(TourList)
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: response.data.message,
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    };
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const handleChange = (e) => {
        form.setFieldsValue({
            tournament: e,
        });
    }

    const teamStatusList = (status) => {
        if (status == "true") {
            return "Active";
        } else {
            return "Inactive";
        }
    }

    const currentStatus = (status) => {
        if (status == "true") {
            return true;
        } else {
            return false;
        }
    }

    const onFinish = async (values) => {

        setLoaded(true)
        if (drawertype === "Edit Team") {
            const SendFiles = new FormData();
            SendFiles.append('file', imageFile);
            SendFiles.append('tournament', values.tournament);
            SendFiles.append('name', values.name);
            SendFiles.append('fullName', values.fullName);
            SendFiles.append('imageUploaded', imageFile ? true : false);
            SendFiles.append('appId', `${currentTournamentDetails.shortCode}-com`);
            SendFiles.append('active', Status);
            const response = await axios.post(
                `${domain}/betops/teams/update/v2`, SendFiles, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    setVisible(false);
                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        // Modal.success({
                        //     content: response.data.status,
                        // });
                        // setTimeout(() => {
                            fetchDashboard()
                        // }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.status,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
        else {
            const SendFiles = new FormData();
            SendFiles.append('file', imageFile);
            SendFiles.append('tournament', values.tournament);
            SendFiles.append('name', values.name);
            SendFiles.append('fullName', values.fullName);
            SendFiles.append('imageUploaded', imageFile ? true : false);
            SendFiles.append('appId', `${currentTournamentDetails.shortCode}-com`);

            const response = await axios.post(
                `${domain}/betops/teams/add/v2`, SendFiles, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    setVisible(false);
                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        Modal.success({
                            content: response.data.status,
                        });
                        setTimeout(() => {
                            fetchDashboard()
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.status,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
    }
    const ImageChange = (event) => {

        const { files } = event.target;
        if (files[0])
            setTeamLogo(null)
        setImageFile(files[0])
        setimgsrc(URL.createObjectURL(files[0]));
    }
    const handleTournament = (event) => {

        setMatchValue(event);
        let data = TeamsListFilter.filter(list => list.tournament === event)
        if (data.length > 0) {
            setTeamsList(data[0].teams)
        }
        else {
            setTeamsList([])
        }

    }
    const onChangeStatus = (event) => {
        setStatus(event)
    }
    const removeImg = () => {
        document.getElementById("previewFile").value = "";
        setimgsrc(null)
        setImageFile(null)
    }
    useEffect(() => {
        if (loading) return;
        // // if (!user) return navigate("/login");
    }, [user, loading]);
    useEffect(() => {
        fetchDashboard();
    }, []);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                <Sidermenu menu="team_list" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>

                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Team List"
                                    extra={
                                        <>
                                            {" "}
                                            <Space direction="horizontal">
                                                {" "}
                                                <Search placeholder="Search here" value={searchValue} onChange={onSearch} enterButton />
                                                <Select size="default" value={matchValue} style={{ width: "200px" }} onChange={(e) => handleTournament(e)}>
                                                    {TournamentList.map((name, pos) => {
                                                        if (name == TournamentList[pos - 1]) {
                                                            return ""
                                                        }
                                                        return (
                                                            <Option key={pos} value={name}>
                                                                {name}{" "}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                <a onClick={() => addnew()}>
                                                    <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                </a>
                                            </Space>{" "}
                                        </>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={TeamsList}
                                    scroll={{ y: "340" }}
                                >
                                    <Column title="Full Name" dataIndex="fullName" key="fullName" />
                                    <Column title="Name" dataIndex="name" key="name" />
                                    <Column title="Logo" dataIndex="name" key="name" align="center" render={(name, value) => (
                                        <Space size="middle">
                                            <img src={`${domain}/betops/asset/get-by-name/v1?appId=${currentTournamentDetails.shortCode}-com&assetType=team&assetName=${name}`} width={100} height={100} />

                                        </Space>
                                    )} />
                                    {/* <Column title="Status" dataIndex="active" key="active" render={(name, value) => (
                                        <Space size="middle">
                                            {name ? "Active" : "Inactive"}
                                        </Space>
                                    )} /> */}

                                    <Column title="Status" dataIndex="active" key="active"
                                        render={((name, value) => {
                                            return (
                                                teamStatusList(name)
                                            )
                                        }

                                        )}
                                    />


                                    <Column
                                        title="Quick Actions"
                                        key="name"
                                        render={(name, value) => (
                                            <Space size="middle">
                                                <DeleteTwoTone onClick={() => deleterecordModel(name)} style={{ fontSize: "18px", color: "#08c" }} />
                                                <EditTwoTone onClick={() => showDrawer(name)} style={{ fontSize: "18px", color: "#08c" }} />
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>
                        <Drawer title={drawertype} placement="right" onClose={onClose} visible={visible} size="middle">
                            <br />
                            <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item name="tournament" label="Tournament" rules={[{ required: true, message: "Please input Tournament!" }]}>
                                            <Select style={{ width: "100%" }} value={matchValue} onChange={(e) => handleChange(e)}>
                                                {TournamentList.map((name, pos) => {

                                                    if (name == TournamentList[pos - 1]) {
                                                        return ""
                                                    }
                                                    return (
                                                        <Option key={pos} value={name}>
                                                            {name}{" "}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="fullName" label="Full Name" rules={[{ required: true, message: "Please input Full Name!" }]}>
                                            <Input placeholder="Full Name" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input Name!" }]}>
                                            <Input disabled={drawertype === "Edit Team" ? true : false} placeholder="Name" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                {drawertype === "Edit Team" ? <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        Status <Switch checked={Status} onChange={onChangeStatus} />
                                    </Col>
                                </Row> : ""}
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="logo" label="Logo" rules={[{ required: drawertype === "Edit Team" ? false : true, message: "Please input Logo!" }]}>
                                            <Input type="file" accept="image/*" value="" onChange={ImageChange} id="previewFile" placeholder="Logo" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                {TeamLogo ? <img className="preview" src={TeamLogo} width={100} height={100} /> : imgsrc ?
                                    <><img id="preview" src={imgsrc} width={100} height={100} />
                                        {/* <CloseOutlined onClick={removeImg} style={{ position: "relative", top: "-40px", fontSize: "20px", color: "red" }} /> */}
                                    </> : ""}
                                <Row gutter={24} className="rtmp1">
                                    <Col span={24}>
                                        <Form.Item shouldUpdate {...tailLayout}>
                                            {() => (
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    {drawertype === "Edit Team" ? "Update" : "Add"}

                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Drawer>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        <Modal title="Delete Team" onCancel={handleCancel} visible={deleteModalVisible}
                            footer={[
                                <Button key="submit" type="primary" onClick={handleCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    type="primary"
                                    onClick={deleterecord}
                                >
                                    Delete
                                </Button>
                            ]}
                        >
                        </Modal>
                        {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default TeamList;
