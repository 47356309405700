import { doc, addDoc, updateDoc, collection } from "firebase/firestore";
import { Select, Row, Col, Button, Modal, Form, Input, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
const { Option } = Select;

function AddVersion(props) {
    const [loaded, setLoaded] = useState(false);
    const [infoform] = Form.useForm();

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const loadVersion = async () => {
        if (props.drawertype == "Edit" && props.currObj) {
            infoform.setFieldsValue({
                platform: props.currObj.platform,
                name: props.currObj.name,
                type: props.currObj.type,
                code: props.currObj.code,
                packageName: props.currObj.packageName || "",
                url: props.currObj.url || "",
                active: props.currObj.active,
                createdDate: props.currObj.createdDate,
                modifiedDate: props.currObj.modifiedDate,
                state: props.currObj.state
            });
        } else {
            infoform.resetFields();
        }
    };

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setLoaded(false);
            }, 2000);
        }
        loadVersion();
        setLoaded(false);
    }, [loaded, props]);

    const onFinish = async (values) => {
        setLoaded(true);

        let finalObj = null;
        let dataurl = null;
        let temp = null;
        if (props.drawertype === "Edit") {
            temp = {
                "url": values.url,
                "name": values.name,
                "type": values.type,
                "packageName": values.packageName,
                "code": values.code,
                "platform": values.platform,
                "active": props.currObj.active
            };
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "appVersionsConfig": temp,
                "id": props.currObj.id,
                "aid": props.currObj.aid,
                "state": values.state
            };
            dataurl = `${domain}/betops/config/version/update/v2.0`;
        } else {
            temp = {
                "url": values.url,
                "name": values.name,
                "type": values.type,
                "packageName": values.packageName,
                "code": values.code,
                "platform": values.platform,
                "active": true
            };
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "appVersionsConfig": temp,
                "state": values.state
            };
            dataurl = `${domain}/betops/config/version/create/v2.0`;
        }

        const response = await axios({
            method: 'post',
            url: dataurl,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: finalObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                props.onClose();
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        props.getList();
                    },
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };

    return (
        <>
            <Form form={infoform} name="version" layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="platform" label="Select Platform" rules={[{ required: true, message: "Please Select Platform!" }]}>
                            <Select name="platform" size="default" placeholder="Select Platform" style={{ width: "100%" }}>
                                <Option key="ANDROID">{"ANDROID"}</Option>
                                <Option key="IOS">{"IOS"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="name" label="Version" rules={[{ required: true, message: "Please input Version!" }]}>
                            <Input placeholder="0.0.0.0" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                            <Form.Item name="code" label="Version Code" rules={[{ required: true, message: "Please input Version code!" }]}>
                                <Input placeholder="0" />
                            </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="type" label="Installation Type" rules={[{ required: true, message: "Please select Installation Type!" }]}>
                            <Select name="type" size="default" placeholder="Select Type" style={{ width: "100%" }}>
                                <Option key="FORCED">{"FORCED"}</Option>
                                <Option key="OPTIONAL">{"OPTIONAL"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="url" label="Domain Url" rules={[{ required: true, message: "Please input domain url" }]}>
                            <Input placeholder="Url here" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="packageName" label="Package Name" rules={[{ required: true, message: "Please input Package Name!" }]}>
                            <Input placeholder="Package Name" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="state" label="Select State" rules={[{ required: true, message: "Please Select State!" }]}>
                            <Select name="state" size="default" placeholder="Select State" style={{ width: "100%" }}>
                                <Option key="DRAFT">{"DRAFT"}</Option>
                                <Option key="PUBLISHED">{"PUBLISHED"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24} className="rtmp1">
                    <Col span={24}>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                {props.drawertype === "Add New" ? "Add" : "Update"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default AddVersion;
