import { Select, Spin, Tooltip, Layout, Menu, Card, Row, Col, Table, Tag, Popover, Space, Input, Button, Modal, Drawer, Divider, PageHeader, Form } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import axios from "axios";
import {
    SmallDashOutlined
} from "@ant-design/icons";
import { domain } from "../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const { Search } = Input;

function Gamers() {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [gamers, setgamers] = useState([]);
    const [gamersDup, setgamersDup] = useState([]);
    const [filterKey, setFilterKey] = useState("name");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [visibles, setVisibles] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [status, setStatus] = useState(null);

    const hide = () => {
        setVisibles(false);
    };
    const ModelView = (notifId, status) => {

        setVisibles(true);
        // notifId.status = status
        setUserDetails(notifId);
        setStatus(status)
    };

    const showContent = (notifId) => {
        if (notifId.status) {
            let arrayList = []
            let arrayListDetails = ["ACTIVE", "SUSPEND", "DELETE"]
            for (let i = 0; i < arrayListDetails.length; i++) {
                if (arrayListDetails[i].toLowerCase() === notifId.status.toLowerCase()) {
                }
                else {
                    arrayList.push(arrayListDetails[i])
                }
            }
            return (
                <div>
                    <p><a href="#" onClick={() => ModelView(notifId, arrayList[0])}>{arrayList[0]}</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, arrayList[1])}>{arrayList[1]}</a></p>
                </div >

            );
        }
        else {
            return (
                <div>
                    <p><a href="#" onClick={() => ModelView(notifId, "ACTIVE")}>ACTIVE</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, "SUSPEND")}>SUSPEND</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, "DELETE")}>DELETE</a></p>
                </div >

            );
        }
    };

    const submitStatus = async () => {
        setLoaded(true);
        hide();
        let dataurl = ""
        if (status === "DELETE")
            dataurl = `${domain}/betops/auth/user/delete/v2.0`
        if (status === "ACTIVE")
            dataurl = `${domain}/betops/auth/user/activate/v2.0`
        if (status === "SUSPEND")
            dataurl = `${domain}/betops/auth/user/suspend/v2.0`
        let finalObj = {
            "appId": `${currentTournamentDetails.shortCode}-ops`,
            "uid": userDetails.uid,
        };
        const response = await axios({
            method: 'post',
            url: dataurl,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: finalObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        fetchGamers();
                    },
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    }

    const downloadCsv = (objArray) => {
        var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str =
            `${Object.keys(arr[0])
                .map((value) => `"${value}"`)
                .join(',')
            }` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
            st +=
                `${Object.values(next)
                    .map((value) => `"${value}"`)
                    .join(',')
                }` + '\r\n';
            return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'GamersList.csv';
        element.click();
    }
    
    const downloaddata = async () => {
        let gamersArray = [];
        let i = 1;
        gamers.map(function (element, i) {
            gamersArray.push({
                key: i++,
                Name: element.name,
                UID: element.uid,
                Logintype: element.login_typ,
                Emailid: element.email,
                Mobileno: element.mob ? element.mob : "-",
                Status: element.status
            });
        });
        downloadCsv(gamersArray);
    };

    const fetchGamers = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/auth/user/list/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setgamers(response.data.data.gamersList);
                setgamersDup(response.data.data.gamersList);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
        // const gamersList = gamersSnapshot.docs.map((doc) => doc.data());
        // const resultArray = [];
        // const resultMatch = [];
        // const result = gamersSnapshot.docs.map((doc) => {
        //     let documentArray = doc.data();
        //     resultArray.push(doc.data());
        //     resultMatch.push({ documentID: doc.id });
        // });
        // let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
        // let gamersArray = [];
        // let i = 1;
        // arr3.map(function (element, i) {
        //     gamersArray.push({
        //         key: i++,
        //         Name: element.name,
        //         loginType: element.login_typ,
        //         emailID: element.email,
        //         status: element.status,
        //         mobileNo: element.mob,
        //         UID: element.uid,
        //     });
        // });
        // setgamers(gamersArray);
        // setgamersDup(gamersArray);
    };

    const makeShortUId = (uid) => {
        var value = uid;
        var firstFour = value.substr(0, 4);
        var lastFour = value.substr(value.length - 4);
        return (
            <>
                <span style={{ color: "rgb(0, 136, 204)" }}>{firstFour}</span>xx-xx<span style={{ color: "rgb(0, 136, 204)" }}>{lastFour}</span>
            </>
        );
    }

    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();

            const filteredData = gamersDup.filter((item) => item[filterKey] ? item[filterKey].toString().toLowerCase().includes(lowercasedFilter) : "");
            setgamers(filteredData);
        } else {
            setgamers(gamersDup)
        }
    }
    useEffect(() => {

        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);
    useEffect(() => {

        // if (loaded) {
        //     setTimeout(() => {
        //         setLoaded(false);
        //     }, 2000);
        // }
        fetchGamers();
    }, [loaded]);
    const changeFilter = (e) => {
        setFilterKey(e)
    }
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                <Sidermenu menu="gamers" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Profile"
                                    extra={
                                        <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                                            <label>Filter Type</label>
                                            <Select size="default" value={filterKey} style={{ width: "200px" }} onChange={changeFilter}>
                                                <Option value="name">Name </Option>
                                                <Option value="login_typ">Login Type </Option>
                                                <Option value="email">Email ID </Option>
                                                <Option value="mob">Mobile No </Option>
                                                <Option value="uid">UID </Option>
                                            </Select>
                                            <Search placeholder="Filter based search here" onChange={onSearch} enterButton />
                                            <Button onClick={downloaddata}>Download CSV{" "} <img src="images/download.svg"></img></Button>
                                        </Space>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={gamers}
                                    scroll={{ y: "340" }}
                                >
                                    <Column title="Name" dataIndex="name" key="name" />
                                    <Column title="UID" dataIndex="uid" key="uid"
                                                render={(text, record) => (
                                                    <>
                                                        <CopyToClipboard text={text}>
                                                            <div className="clonediv">
                                                                <Tooltip title="click to copy">
                                                                    <span style={{ cursor: "pointer" }}>
                                                                        {makeShortUId(text)}
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </CopyToClipboard>
                                                    </>
                                                )}
                                            />
                                    <Column title="Login Type" dataIndex="login_typ" key="login_typ" />
                                    <Column title="Email ID" dataIndex="email" key="email" />                                 
                                    <Column title="Mobile No" dataIndex="mob" key="mob" align="center"
                                      render={(text, record) => (
                                        <Space size="middle">
                                            {text ? (
                                               <p>{ text }</p>
                                            ) : (
                                                "-"
                                            )}
                                        </Space>
                                    )}
                                    />
                                    <Column title="Status" dataIndex="status" key="status" />
                                    <Column
                                        title="Quick Actions"
                                        key="documentID"
                                        align="center"
                                        render={(documentID) => (

                                            <Space size="middle">
                                                <Popover
                                                    content={() => showContent(documentID)}
                                                    trigger="click"
                                                >
                                                    <SmallDashOutlined />
                                                </Popover>
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>
                        <Modal title="Change Status" onCancel={hide} visible={visibles}
                            footer={[
                                <Button key="submit" type="primary" onClick={hide}>
                                    No
                                </Button>,
                                <Button
                                    type="primary"
                                    onClick={submitStatus}
                                >
                                    Yes
                                </Button>
                            ]}

                        >
                            Would you like to save changes?
                        </Modal>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>
        </Layout>
    );
}

export default Gamers;
