
import { collection, getDocs, query, where, doc, getDoc, setDoc, updateDoc, limit } from "firebase/firestore";
import { Select, Spin, Layout, Row, Collapse, Col, Table, Space, Tag, Drawer, PageHeader, Button, Modal, Form, Input, Divider } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import React, { useState, useEffect } from "react";
import Addbets from "./addbets";
import Editbets from "./editbets";
import Moment from "react-moment";
import {
    EditTwoTone,
    PlusOutlined,
    DeleteTwoTone,
    CodepenOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.min.css"
const { Column } = Table;
function BetsInfo(props) {
    const [modalVisible, setModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteBet, setDeleteBet] = useState(null)
    const [valuess, setValuess] = useState(null)
    const [modelShowTxt, setModelShowTxt] = useState(false)
    const [specificMatchBets, setSpecificMatchBets] = useState(null);
    const [modelShowRaw, setModelShowRaw] = useState(false)
    const [matchInfoFilter, setMatchInfoFilter] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [loaded, setLoaded] = useState(true);

    const [teams, setTeams] = useState([]);
    const winningarray = ["WIN", "LOSS"];
    const betmode = ["GENERAL", "CUSTOM"];
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("Select Tournaments");
    const [matchValue, setMatchValue] = useState("Select Year");
    const [docid, setDocid] = useState();
    const [title, setTitle] = useState("");
    const [drawertype, setDrawertype] = useState("");
    const [editmatchid, setEditmatchid] = useState([]);
    const [teaminfo, setTeaminfo] = useState([]);
    const showDrawer = (matchid) => {

        setDrawertype("editbet");
        setEditmatchid(matchid);
        setTitle(matchid.matchtitle + " Bet - " + matchid.betNum);
        setVisible(true);
    };

    const addnew = () => {
        setDrawertype("addbet");
        setTitle(props.globalInfo.tournament + " - " + props.season);
        setVisible(true);
    };

    const initialFunction = async (val, sYear, sType) => {
        const finaldata = val
            .filter(function (item) {
                return item.tournament == props.tournament && item.year == sYear;
            })
            .map(function (item) {
                return item;
            });

        const tableData = [];
        const playoffData = [];
        finaldata.map(function (element, mid) {
            let i = 1;
            if (element.playOff !== undefined) {
                const list = element.playOff.map(function (playoff, mid3) {
                    playoffData.push({
                        startdate: element.begins,
                        year: element.year,
                        matchtitle: playoff.rt + " vs " + playoff.ht,
                        tourID: element.tourID,
                        matchno: playoff.matchId.slice(4, playoff.matchId.length),
                        tournament: element.tournament,
                        tournamenttitle: element.tournamentTitle,
                        hticon: playoff.ic,
                        bets: playoff.bets,
                        ht: playoff.ht,
                        rt: playoff.rt,
                        matchid: playoff.matchId,
                        matchTs: playoff?.matchTs?.seconds,
                        // documentID: element.documentID,
                        mtype: "playoff",
                    });
                });
            }
            if (element.htl !== undefined) {
                const list = element.htl.map(function (element1, mid1) {
                    const list2 = element1.rtl.map(function (element2, mid2) {
                        tableData.push({
                            startdate: element.begins,
                            year: element.year,
                            matchtitle: element2.rt + " vs " + element2.ht,
                            tourID: element.tourID,
                            matchno: element2.matchId.slice(4, element2.matchId.length),
                            tournament: element.tournament,
                            tournamenttitle: element.tournamentTitle,
                            hticon: element1.ic,
                            bets: element2.bets,
                            ht: element2.ht,
                            rt: element2.rt,
                            matchid: element2.matchId,
                            // matchTs: element2?.matchTs?.seconds,
                            matchTs: element2.matchTs,
                            // documentID: element.documentID,
                            mtype: "schedule",
                        });
                    });
                });
            }
        });
        var array3 = tableData.concat(playoffData);
        let data1 = []
        for (let i = 0; i < array3.length; i++) {
            if (array3[i].startdate)
                data1.push(array3[i])
        }
        let finalteam = await SortandRemoveDuplicateObject(data1);
        setTeaminfo(finalteam);
        await betDetails(props.matchid.matchId, finalteam);
    }

    const SortandRemoveDuplicateObject = async (val) => {
        const sortByKey = (key) => (a, b) => a[key] - b[key];
        // const sortByMatchid = sortByKey("matchid");
        const sortByMatchid = sortByKey("matchno");
        const sortval = val.sort(sortByMatchid);
        const uniqueIds = [];
        const unique = sortval.filter(element => {
            const isDuplicate = uniqueIds.includes(element.matchid);
            if (!isDuplicate) {
                uniqueIds.push(element.matchid);
                return true;
            }
            return false;
        });
        return unique;
    }

    const fetchTournamentBasedSchedule = async (val1, val2, val3) => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            // url: `${domain}/betops/bet/get/match-list/v2.0?tournament=${val1}&season=${val2}&seasonType=${val3}&appId=${currentTournamentDetails.shortCode}-ops`,
            url: `${domain}/betops/match/get/all-match-list/v1?tournament=${val1}&season=${val2}&seasonType=${val3}`,
            // headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let temp = [];
                temp.push(response.data.data.schedule);
                await initialFunction(temp, val2, val3);
            } else {
                setLoaded(false);
            }
        })
            .catch((error) => {
                setLoaded(false);
            })
    }

    useEffect(async () => {
        await fetchTournamentBasedSchedule(props.tournament, props.season, props.seasonType);
    }, []);
    const betDetails = async (e, data1) => {
        if (data1 == "") {
            e = teaminfo.filter(list => list.matchid == e)
        } else {
            e = data1.filter(list => list.matchid == e)
        }
        e = e[0]
        setLoaded(true);
        let finaldata = null;
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/bet/schedule/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${props.tournament}&season=${props.season}&seasonType=${props.seasonType}&matchId=${props.matchid.matchId}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setSpecificMatchBets(response.data.data.betMatch);
                let temp = [];
                temp.push(response.data.data.betMatch);
                finaldata = temp;
                const tableData = [];
                const finaldata1 = finaldata.map(function (element, mid) {
                    let i = 1;
                    if (element.bets !== undefined) {
                        const list2 = element.bets.betList.map(function (element2, mid2) {

                            tableData.push({
                                startdate: props.globalInfo.startdate,
                                winnerActual: element2.winnerActual,
                                betLegend: element2.betLegend,
                                oddsVersion: element2.oddsVersion,
                                betMode: element2.betMode,
                                betType: element2.betType,
                                winnerActualFavOrDog: element2.winnerActualFavOrDog,
                                betNum: element2.betNum,
                                year: element.year,
                                matchtitle: props.globalInfo.matchtitle,
                                //   tourID: props.matchid.tourID,
                                matchno: props.globalInfo.matchno,
                                tournament: props.globalInfo.tournament,
                                tournamenttitle: props.globalInfo.tournamenttitle,
                                hticon: props.globalInfo.hticon,
                                ht: props.matchid.ht,
                                rt: props.matchid.rt,
                                matchid: props.matchid.matchId,
                                rticon: props.globalInfo.rticon,
                                matchTs: props.matchid.matchTs,
                                subLabel: element2.subLabel,
                                betinfo: element2.sideList,
                                mtype: e.mtype,
                            });
                        });
                    }
                });
                setSchedule(tableData);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    }
    const deleterecordModel = (matchid) => {
        setDeleteModalVisible(true)
        setDeleteBet(matchid)
    }
    const deleterecord = async () => {
        setLoaded(true);
        setDeleteModalVisible(false);
        let mainarray = [];
        mainarray.push(specificMatchBets);
        const final = mainarray[0].bets.betList.filter((number) => {
            return number.betNum !== deleteBet.betNum;
        });
        mainarray[0].bets.betList = final
        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });
        let infoObj = obj.val;
        infoObj.appId = `${currentTournamentDetails.shortCode}-ops`;
        const response = await axios({
            method: 'post',
            url: `${domain}/betops/bet/update/v2.0`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: infoObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        betDetails(props.matchid.matchId, "");
                    }
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };
    const onClose = () => {
        setLoaded(false);
        setVisible(false);
        setModelShowTxt(false)
        setModelShowRaw(false)
    };
    const updateMatchdetails = async () => {
        betDetails(props.matchid.matchId, "");
        // props.handleBack();
    };
    const handleCancel = () => {
        setModalVisible(false);
        setDeleteModalVisible(false)
    };
    const handleOk = (arg) => {

        setMatchInfoFilter(arg)
        setModalVisible(true);
    };
    const handleBetRawUpdate = async (values) => {
        setModalVisible(false);
        setLoaded(true);
        let mainarray = [];
        mainarray.push(specificMatchBets);
            mainarray.forEach(function (item, index) {
        if (item.matchId == props.matchid.matchId) {
            item.bets.betList.forEach(function (item3, index3) {
                if (item3.betNum == matchInfoFilter.betNum) {
                    item3.betLegend = matchInfoFilter.betLegend;
                    item3.betMode = matchInfoFilter.betMode;
                    item3.subLabel = matchInfoFilter.subLabel;
                    item3.betNum = matchInfoFilter.betNum;
                    item3.sideList = matchInfoFilter.betinfo;
                }
            });
        }
    });
        // mainarray.forEach(function (item, index) {
        //     if (props.matchid.mtype == "schedule") {
        //         item.htl.forEach(function (item1, index1) {
        //             item1.rtl.forEach(function (item2, index2) {
        //                 if (item2.matchId == props.matchid.matchid) {
        //                     item2.bets.betList.forEach(function (item3, index3) {
        //                         if (item3.betNum == matchInfoFilter.betNum) {
        //                             item3.betLegend = matchInfoFilter.betLegend;
        //                             item3.betMode = matchInfoFilter.betMode;
        //                             item3.subLabel = matchInfoFilter.subLabel;
        //                             item3.betNum = matchInfoFilter.betNum;
        //                             item3.sideList = matchInfoFilter.betinfo;
        //                         }
        //                     });
        //                 }
        //             });
        //         });
        //     } else {
        //         item.playOff.forEach(function (itemplayoff, playoffindex1) {
        //             if (itemplayoff.matchId == props.matchid.matchid) {
        //                 itemplayoff.bets.betList.forEach(function (playoff, index5) {
        //                     if (playoff.betNum == matchInfoFilter.betNum) {
        //                         playoff.betLegend = matchInfoFilter.betLegend;
        //                         playoff.betMode = matchInfoFilter.betMode;
        //                         playoff.subLabel = matchInfoFilter.subLabel;
        //                         playoff.betNum = matchInfoFilter.betNum;
        //                         playoff.sideList = matchInfoFilter.betinfo;
        //                     }
        //                 });
        //             }
        //         });
        //     }
        // });

        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });
        let infoObj = obj.val;
        infoObj.appId = `${currentTournamentDetails.shortCode}-ops`;
        const response = await axios({
            method: 'post',
            url: `${domain}/betops/bet/update/v2.0`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: infoObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        onClose();
                        updateMatchdetails();
                    }
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };
    const handleChanges = (e) => {
        setMatchInfoFilter(JSON.parse(e.target.value))
    }
    return <div>
        <PageHeader
            className="site-page-header"
            title="Bets"
            extra={[
                <>
                    <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                    <a onClick={() => addnew()}>
                        <span className="addbet">New Bet</span>
                    </a>
                </>,
            ]}
        />
        <Table
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            dataSource={schedule}
            scroll={{ y: "250" }}
        >
            <Column title="Match No" dataIndex="matchno" key="matchno" />
            <Column title="Bet No" dataIndex="betNum" key="betNum" sorter="true" />
            <Column title="Bet Title" dataIndex="subLabel" key="subLabel" style={{ width: "200px" }} />
            <Column title="Match Title" dataIndex="matchtitle" key="matchtitle" />
            {/* <Column
                title="Match Date"
                dataIndex="matchTs"
                key="matchTs"
                render={(text, recod) => (
                    <Space size="middle">
                        {text !== "Invalid date" ? (
                            <Moment unix format="DD-MM-YYYY">
                                {text}
                            </Moment>
                        ) : (
                            ""
                        )}
                    </Space>
                )}
            /> */}


            <Column
                title="Match Date"
                dataIndex="matchTs"
                key="matchTs"
                render={(text, record) => (
                    <Space size="middle">
                        {text !== undefined ? (
                            <Moment format="DD-MM-YYYY hh:mm a">
                                {text}
                            </Moment>
                        ) : (
                            ""
                        )}
                    </Space>
                )}
            />
            <Column title="Bet Mode" dataIndex="betMode" key="betMode" />
            <Column
                title="Quick Actions"
                key="matchId"
                render={(matchId) => (
                    <Space size="middle">
                        <EditTwoTone title="Edit" onClick={() => showDrawer(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        <CodepenOutlined onClick={() => handleOk(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        <DeleteTwoTone onClick={() => deleterecordModel(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                    </Space>
                )}
            />
        </Table>
        <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="large">
            {drawertype == "editbet" ? (
                <Editbets updateMatchdetails={updateMatchdetails} matchid={editmatchid} visible={visible} winningarray={winningarray} betmode={betmode} onClose={onClose} />
            ) : (
                <Addbets
                    teaminfo={teams}
                    teams={searchValue}
                    matchtitle={matchValue}
                    matchdetails={schedule}
                    winningarray={winningarray}
                    betmode={betmode}
                    onClose={onClose}
                    updateMatchdetails={updateMatchdetails}
                />
            )}
        </Drawer>
        <Modal title="Raw Update" onCancel={handleCancel} visible={modalVisible}
            footer={[
                <Button key="submit" type="primary" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={handleBetRawUpdate}
                >
                    Update
                </Button>
            ]}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <textArea style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }} onChange={handleChanges} rows={20}>{JSON.stringify(matchInfoFilter, null, 4)}</textArea>
                </Col>
            </Row>
        </Modal>
        <Modal title="Delete Bet" onCancel={handleCancel} visible={deleteModalVisible}
            footer={[
                <Button key="submit" type="primary" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={deleterecord}
                >
                    Delete
                </Button>
            ]}
        >
        </Modal>
        {
            loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )
        }
    </div >
}
export default BetsInfo;