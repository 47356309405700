import React from "react"
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  menuClasses
} from "react-pro-sidebar"
import { Switch } from "./components/Switch"
// import { SidebarHeader } from "./components/SidebarHeader"
// import { SidebarFooter } from "./components/SidebarFooter"
// import { Badge } from "./components/Badge"
import { Typography } from "./components/Typography"
import { Link } from 'react-router-dom';
import { currentTournamentDetails } from "../settings/config";
import "./sidebar.css";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489"
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e"
      },
      disabled: {
        color: "#9fb6cf"
      }
    }
  },
  dark: {
    sidebar: {
      backgroundColor: "#2196F3",
      color: "#ffffff"
    },
    menu: {
      menuContent: "#2196F3",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#4CAAF5",
        color: "#ffffff"
      },
      active: {
        backgroundColor: "#204F9D",
        color: "#ffffff"
      },
      disabled: {
        color: "#3e5e7e"
      }
    }
  }
}

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const tempCollapseSidebar = (val) => {
  // localStorage.setItem("oldCollapseValue", e.target.checked);
}

function Sidermenu(props) {
  const { toggleSidebar, collapseSidebar, broken, collapsed, toggled } = useProSidebar()

  const [isRTL, setIsRTL] = React.useState(false)
  const [hasImage, setHasImage] = React.useState(false)
  const [theme, setTheme] = React.useState("dark")
  // const [collapseVal, setCollapseVal] = React.useState(true)

  const checkCollapseState = () => {
    if (localStorage.getItem("oldCollapseValue")) {
      return localStorage.getItem("oldCollapseValue") === 'true';
    } else {
      return true;
    }
  }

  const setCollapseBar = (e) => {
    localStorage.setItem("oldCollapseValue", e.target.checked);
    collapseSidebar();
  }

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      }
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9"
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
            themes[theme].menu.menuContent,
            hasImage && !collapsed ? 0.4 : 1
          )
          : "transparent"
    }),



    // button: {
    //   [`&.${menuClasses.disabled}`]: {
    //     color: themes[theme].menu.disabled.color
    //   },
    //   "&:hover": {
    //     backgroundColor: hexToRgba(
    //       themes[theme].menu.hover.backgroundColor,
    //       hasImage ? 0.8 : 1
    //     ),
    //     color: themes[theme].menu.hover.color
    //   }
    // },

    button: ({ active }) => {
      return {
        backgroundColor: active ? hexToRgba(
          themes[theme].menu.active.backgroundColor,
          hasImage ? 0.8 : 1
        ) : undefined,
        [`&.${menuClasses.disabled}`]: {
          color: themes[theme].menu.disabled.color
        },
        "&:hover": {
          backgroundColor: hexToRgba(
            themes[theme].menu.hover.backgroundColor,
            hasImage ? 0.8 : 1
          ),
          color: themes[theme].menu.hover.color
        }
      };
    },

    //   button: ({ active }) => {
    //     return {
    //       backgroundColor: active ? '#eecef9' : undefined,
    //     };
    // },



    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined
    })
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: isRTL ? "rtl" : "ltr"
      }}
      className="sticky-top"
    >

      <Sidebar
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={isRTL}
        breakPoint="lg"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color
        }}
        defaultCollapsed={checkCollapseState()}
        style={{
          overflow: "auto",
          height: "100vh"
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ padding: "0 8px", position: "relative", left: "0", right: "0", margin: "auto" }}>
            <div style={{ margin: "16px 0" }}>
              <Switch
                id="collapse"
                checked={collapsed}
                onChange={(e) => setCollapseBar(e)}
              // onClick={tempCollapseSidebar(!collapsed)}
              // onChange={e => { tempCollapseSidebar(e); collapseSidebar() }}
              />
            </div>
          </div>

          <div className="text-center" style={{ marginTop: "16px" }}>
            <img src={currentTournamentDetails.sidebarLogo}></img>
          </div>

          <center><hr style={{ width: "160px", margin: "20px 0 9px 0" }}></hr></center>

          {/* <SidebarHeader style={{ marginBottom: "24px", marginTop: "16px" }} /> */}
          <div style={{ flex: 1, margin: "0 0 32px 0" }}>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem active={props.menu === "overview"} component={<Link to="/overview" />} icon={<img src="images/Overview.svg"></img>}>Dashboard</MenuItem>

              <SubMenu active={props.menu === "gamers" || props.menu === "certificate" || props.menu === "leader_board" || props.menu === "user_wallet"} label="Gamers" icon={<img src="images/group1.png"></img>}>
                <MenuItem active={props.menu === "gamers"} component={<Link to="/gamers" />}>Profile</MenuItem>
                <MenuItem active={props.menu === "certificate"} component={<Link to="/certificate" />}>Certificate</MenuItem>
                <MenuItem active={props.menu === "leader_board"} component={<Link to="/leader_board" />}>Leader Board</MenuItem>
                <MenuItem active={props.menu === "user_wallet"} component={<Link to="/user_wallet" />}>Wallet Transaction</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "package_manager" || props.menu === "purchase_transactions"} label="In App Purchase" icon={<img src="images/booking.png"></img>}>
                <MenuItem active={props.menu === "package_manager"} component={<Link to="/package_manager" />}>Package List</MenuItem>
                <MenuItem active={props.menu === "purchase_transactions"} component={<Link to="/purchase_transactions" />}>Purchase Transactions</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "team_list" || props.menu === "matchdetails" || props.menu === "betlist" || props.menu === "playofflist" || props.menu === "insightlist" || props.menu === "fantasylist" || props.menu === "bet_transactions" || props.menu === "fantasy_transactions" || props.menu === "user_transactions"} label="Game" icon={currentTournamentDetails.shortName == "NFL" ? <img src="images/football.png"></img> : <img src="images/matches.svg"></img>}>
                <MenuItem active={props.menu === "team_list"} component={<Link to="/team_list" />}>Teams</MenuItem>
                <MenuItem active={props.menu === "matchdetails"} component={<Link to="/matchdetails" />}>Matches</MenuItem>
                <MenuItem active={props.menu === "betlist"} component={<Link to="/betlist" />}>Bets</MenuItem>
                <MenuItem active={props.menu === "playofflist"} component={<Link to="/playofflist" />}>Playoff</MenuItem>
                <MenuItem active={props.menu === "insightlist"} component={<Link to="/insightlist" />}>Insights</MenuItem>
                <MenuItem active={props.menu === "fantasylist"} component={<Link to="/fantasylist" />}>Fantasy</MenuItem>
                <SubMenu active={props.menu === "bet_transactions" || props.menu === "fantasy_transactions" || props.menu === "user_transactions"} label="Transactions">
                  <MenuItem active={props.menu === "bet_transactions"} component={<Link to="/bet_transactions" />}>Bet</MenuItem>
                  <MenuItem active={props.menu === "fantasy_transactions"} component={<Link to="/fantasy_transactions" />}>Fantasy</MenuItem>
                  <MenuItem active={props.menu === "user_transactions"} component={<Link to="/user_transactions" />}>Quiz</MenuItem>
                </SubMenu>
              </SubMenu>

              <SubMenu active={props.menu === "send_email" || props.menu === "email_status" || props.menu === "customalert"} label="Notifications" icon={<img src="images/bell.svg"></img>}>
                <MenuItem active={props.menu === "send_email"} component={<Link to="/send_email" />}>Send Bulk Email</MenuItem>
                <MenuItem active={props.menu === "email_status"} component={<Link to="/email_status" />}>Email Status</MenuItem>
                <MenuItem active={props.menu === "customalert"} component={<Link to="/customalert" />}>Push Notification</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "feed_back" || props.menu === "location_services" || props.menu === "schedule"} label="Services" icon={<img src="images/services.png"></img>}>
                <MenuItem active={props.menu === "feed_back"} component={<Link to="/feed_back" />}>Website Feedback</MenuItem>
                <MenuItem active={props.menu === "location_services"} component={<Link to="/location_services" />}>Location Services</MenuItem>
                <MenuItem active={props.menu === "schedule"} component={<Link to="/schedule" />}>Schedule</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "dashboard" || props.menu === "versions" || props.menu === "landing_page"} label="App Config" icon={<img src="images/appconfig.png"></img>}>
                <MenuItem active={props.menu === "dashboard"} component={<Link to="/dashboard" />}>App Dashboard</MenuItem>
                <MenuItem active={props.menu === "versions"} component={<Link to="/versions" />}>Version Control</MenuItem>
                <MenuItem active={props.menu === "landing_page"} component={<Link to="/landing_page" />}>Landing Page</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "tournament" || props.menu === currentTournamentDetails.majorUsedDocId} label="System Config" icon={<img src="images/sysconfig.png"></img>}>
                <MenuItem active={props.menu === "tournament"} component={<Link to="/tournament" />}>Tournament</MenuItem>
                <MenuItem active={props.menu === currentTournamentDetails.majorUsedDocId} component={<Link to={"/" + currentTournamentDetails.majorUsedDocId} />}>Season Config</MenuItem>
              </SubMenu>

              <SubMenu active={props.menu === "campaign_analytics" || props.menu === "view_campaigns" || props.menu === "create_campaigns"} label="Campaigns" icon={<img src="images/campaign.png"></img>}>
              <MenuItem active={props.menu === "campaign_analytics"} component={<Link to="/campaign_analytics" />}>Analytics</MenuItem>
              <MenuItem active={props.menu === "view_campaigns"} component={<Link to="/campaigns" />}>View Campaigns</MenuItem>
              <MenuItem active={props.menu === "create_campaigns"} component={<Link to="/create_campaign" />}>Create Campaign</MenuItem>
            </SubMenu>

            <SubMenu active={props.menu === "api_service"} label="Api" icon={<img src="images/api.png"></img>}>
              <MenuItem active={props.menu === "api_service"} component={<Link to="/api_service" />}>Service</MenuItem>
            </SubMenu>
            </Menu>
          </div>
        </div>
      </Sidebar>

      <main>
        <div style={{ color: "#44596e" }}>
          <div style={{ marginBottom: "16px" }}>
            {broken && (
              <button className="sb-button" onClick={() => toggleSidebar()}>
                Toggle
              </button>
            )}
          </div>



        </div>
      </main>
    </div>
  )
}

export default Sidermenu;