import { collection, getDocs, query, doc, updateDoc, deleteDoc, limit } from "firebase/firestore";
import { Space, Spin, Layout, Card, Tabs, Table, Modal, PageHeader, Drawer, Button, Input } from "antd";
import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import Firebase from "firebase/compat/app";
import { PlusCircleTwoTone, DeleteTwoTone, EditTwoTone, LoginOutlined } from "@ant-design/icons";
import moment from "moment";
import { auth, currentTournamentDetails, logout } from "../settings/config";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import AddVersion from "./addVersion";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Column } = Table;
const { Search } = Input;
function VersionsControl() {
    const [loaded, setLoaded] = useState(false);
    const [title, setTitle] = useState("");
    const [schedule, setSchedule] = useState([]);
    const [iosList, setIosList] = useState([]);
    const [androidList, setAndroidList] = useState([]);
    const [user, loading] = useAuthState(auth);
    const [visible, setVisible] = useState(false);
    const [VisibleStatus, setVisibleStatus] = useState(false);
    const [currApp, setCurrentObj] = useState({});
    const [Type, setType] = useState("android");

    const [rowInfo, setRowInfo] = useState("");
    const [StatusVisible, setStatusVisible] = useState(false);

    const navigate = useNavigate();
    const { confirm } = Modal;

    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);

    const callback = (key) => {
        //fetchVersionData();
        setType(key)
    };

    const addnew = () => {
        setCurrentObj({});
        setTitle("Add New");
        setVisible(true);
    };

    const editVersion = (obj, index) => {
        if(obj.state == "DRAFT") {
            setCurrentObj(obj);
            setTitle("Edit");
            setVisible(true);
        }
    };

    const changeStatus = async (rowData) => {
        setRowInfo(rowData)
        setStatusVisible(true);
    };

    const onClose = () => {
        setLoaded(false);
        setVisible(false);
        setVisibleStatus(false);
        // fetchVersionInfo();
        setStatusVisible(false);
    };

    const statusTheVersion = async () => {
        setStatusVisible(false);
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/config/version/toggle-status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&docId=${rowInfo.docId}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            onClose();
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        fetchVersionInfo();
                    }
                });
            }
            else if (response.data.status === "FAIL") {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
            else {
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
        setLoaded(false);
    }

    const datesplit = (text) => {
        var timeset = moment(text.toDate()).format("DD-MM-YYYY HH:MM:SS");
        return timeset;
    };

    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            let data = schedule.filter(list => list.platform.toLowerCase() === Type);
            let filteredData = data.find(obj => obj.name === lowercasedFilter);
            if (filteredData != undefined) {
                let tempArr = [];
                tempArr.push(filteredData);
                if (Type === "android")
                    setAndroidList(tempArr)
                else
                    setIosList(tempArr)
            } else {
                setAndroidList([]);
                setIosList([]);
            }
        } else {
            fetchVersionInfo();
        }
    };


    const fetchVersionInfo = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/config/version/get-all/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                let _iosArr = [];
                let _androidArr = [];
                let _schedule = [];
                let res = response.data.data.versionList;
                let _versionList = res.sort(function (a, b) {
                    return a.appVersionsConfig.code - b.appVersionsConfig.code;
                });
                _versionList = _versionList.reverse();
                for (let i in _versionList) {
                    _versionList[i].appVersionsConfig.aid = _versionList[i].aid;
                    _versionList[i].appVersionsConfig.id = _versionList[i].id;
                    _versionList[i].appVersionsConfig.state = _versionList[i].state;
                    let obj = _versionList[i].appVersionsConfig;
                    obj.docId = _versionList[i].id;
                    _schedule.push(obj);
                    if (obj.platform.toLowerCase() == "ios") {
                        _iosArr.push(obj);
                    } else if (obj.platform.toLowerCase() == "android") {
                        _androidArr.push(obj);
                    }
                }
                setSchedule(_schedule);
                setAndroidList(_androidArr);
                setIosList(_iosArr);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setLoaded(false);
            }, 2000);
        }
        fetchVersionInfo();
    }, [loaded]);

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <Sidermenu menu="versions" />
            <Layout className="site-layout">
                <Headersection />
                <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                    <div className="site-card-border-less-wrapper">
                        <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                            <PageHeader
                                className="site-page-header"
                                title="Version Control"
                                extra={
                                    <>
                                        {" "}
                                        <Space direction="horizontal">
                                            {" "}
                                            <Search placeholder="Search Version" onChange={onSearch} enterButton />
                                            <a onClick={() => addnew()}>
                                                <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                            </a>
                                        </Space>{" "}
                                    </>
                                }
                            />
                            <Tabs tabPosition="left" defaultActiveKey="android" onChange={callback}>
                                <TabPane tab="Android App" key="android">
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={androidList}
                                        scroll={{ y: "340" }}
                                    >
                                        <Column title="Version" dataIndex="name" key="name" />
                                        <Column title="Code" dataIndex="code" key="code" />
                                        <Column title="State" dataIndex="state" key="state" />
                                        <Column title="Version Type" dataIndex="type" key="type" />
                                        <Column title="Status" dataIndex="active" key="active"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text == true ? (
                                                        <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                                    ) : (
                                                        <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        {/* <Column title="Created Date" dataIndex="createdDate" key="createdDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} /> */}
                                        {/* <Column title="Modified Date" dataIndex="modifiedDate" key="modifiedDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} /> */}
                                        <Column
                                            title="Quick Actions"
                                            key="notifId"
                                            render={(notifId, record, index) => (
                                                <Space key={index} size="middle">
                                                    <LoginOutlined onClick={() => changeStatus(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    {notifId.state == "DRAFT" ?
                                                        <EditTwoTone onClick={() => editVersion(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                        :
                                                        <EditTwoTone onClick={() => editVersion(notifId, index)} style={{ fontSize: "18px", cursor: "not-allowed" }} />
                                                    }
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </TabPane>
                                <TabPane tab="IOS App" key="ios">
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={iosList}
                                        scroll={{ y: "340" }}
                                    >
                                        <Column title="Version" dataIndex="name" key="name" />
                                        <Column title="Code" dataIndex="code" key="code" />
                                        <Column title="State" dataIndex="state" key="state" />
                                        <Column title="Version Type" dataIndex="type" key="type" />
                                        <Column title="Status" dataIndex="active" key="active"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text == true ? (
                                                        <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                                    ) : (
                                                        <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        {/* <Column title="Created Date" dataIndex="createdDate" key="createdDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} /> */}
                                        {/* <Column title="Modified Date" dataIndex="modifiedDate" key="modifiedDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} /> */}
                                        <Column
                                            title="Quick Actions"
                                            key="actions"
                                            render={(notifId, record, index) => (
                                                <Space key={index} size="middle">
                                                    <LoginOutlined onClick={() => changeStatus(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    {notifId.state == "DRAFT" ?
                                                        <EditTwoTone onClick={() => editVersion(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                        :
                                                        <EditTwoTone onClick={() => editVersion(notifId, index)} style={{ fontSize: "18px", cursor: "not-allowed" }} />
                                                    }
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </TabPane>
                            </Tabs>
                        </Card>

                        <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={statusTheVersion}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>
                            ]}
                        >
                            Are you sure you want to Change the Version Status?
                        </Modal>


                        <Drawer title={title + " Version"} placement="right" onClose={onClose} visible={visible} size="small">
                            <AddVersion drawertype={title} onClose={onClose} currObj={currApp} getList={fetchVersionInfo} />
                        </Drawer>
                    </div>

                    {loaded == true ? (
                        <div className="loading-overlay">
                            <Spin size="large" />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                </Content>
                <Footersection />
            </Layout>
        </Layout>
    );
}

export default VersionsControl;
