import { Select, Spin, Layout, Row, Collapse, Col, Table, Tag, Button, Modal, Form, Input, Divider, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
    TableOutlined,
    CodepenOutlined
} from "@ant-design/icons";
import "antd/dist/antd.min.css"
import { Validator } from "jsonschema";
import { currentTournamentDetails, logout } from "../settings/config";

const { Option } = Select;
const SCHEDULE_SCHEMA = require("../Jsondata/schema/schedule.json");
function MatchTabView(props) {
    const [matchInfo, setMatchInfo] = useState(null)
    const [valuess, setValuess] = useState(null)
    const [modelShowTxt, setModelShowTxt] = useState(false)
    const [modelShowRaw, setModelShowRaw] = useState(false)
    const [matchInfoFilter, setMatchInfoFilter] = useState(null)
    const [loaded, setLoaded] = useState(true);
    const [rawViews, setRawViews] = useState(false);

    const [rtTeamLogo, setRtTeamLogo] = useState(null);
    const [htTeamLogo, setHtTeamLogo] = useState(null);

    const [selectedOption, setSelectedOption] = useState();
    const [winResult, setWinResult] = useState();

    const [gameresult, setGameresult] = useState(props.matchid.gameResult);
    const [winner, setWinner] = useState("");
    const [form] = Form.useForm();
    const [buttonColor, setButtonColor] = useState("Text");

    const [sDate, setSDate] = useState(null);
    const [eDate, setEDate] = useState(null);

    const [res, setRes] = useState(props.matchid.res);

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const loadProfile = async (details) => {
        await getTeamLogo(props.matchid.rt, true);
        await getTeamLogo(props.matchid.ht, false);

        if(details.winner == "PRE") {
            details.winner = "NOT YET MATCH STARTED";
        }

        let winnerMap = `${details.winner} (${details.res})`;

        setSelectedOption(winnerMap);

        let temp;
        if (details.res == 0) {
            temp = "Match not completed";
            setWinResult(temp);
        } else if (details.res == 1) {
            temp = "WIN";
            setWinResult(temp);
        } else if (details.res == -1) {
            temp = "LOSS";
            setWinResult(temp);
        } else {
            temp = "DRAW";
            setWinResult(temp);
        }

        form.setFieldsValue({
            infoHt: props.matchid.resHt,
            infoRt: props.matchid.resRt,
            resultSummary: details.resultSummary === "None" ? null : details.resultSummary,
            winProbHt: details.winProbHt,
            winProbRt: details.winProbRt,
            winMetric: details.winMetric
        });
    };
    useEffect(async () => {
        setLoaded(true)
        let Details = props.globalInfo;

        await setMatchInfo(Details)

        loadProfile(props.matchid);
        localStorage.setItem("matchInfo", JSON.stringify(Details));
        let singleMatchInfo = JSON.parse(localStorage.getItem("matchInfo"));

        let tempMatchInfo = singleMatchInfo;
        delete tempMatchInfo.startdate;
        delete tempMatchInfo.tyear;
        delete tempMatchInfo.matchtitle;
        delete tempMatchInfo.matchno;
        delete tempMatchInfo.tournament;
        delete tempMatchInfo.tournamenttitle;
        delete tempMatchInfo.hticon;
        delete tempMatchInfo.rticon;
        delete tempMatchInfo.mtype;

        delete tempMatchInfo.startTs;
        delete tempMatchInfo.endTs;
        delete tempMatchInfo.matchTs;

        await setMatchInfoFilter(props.matchid)
        // for (let k = 0; k < Details.htl.length; k++) {
        //     for (let i = 0; i < Details.htl[k].rtl.length; i++) {
        //         if (parseInt(Details.htl[k].rtl[i].matchId) === parseInt(props.matchid.matchid)) {
        //             loadProfile(Details.htl[k].rtl[i]);
        //             await setMatchInfoFilter(Details.htl[k].rtl[i])
        //             break;
        //         }
        //     }
        // }
        // for (let k = 0; k < Details.playOff.length; k++) {
        //     if (parseInt(Details.playOff[k].matchId) === parseInt(props.matchid.matchid)) {
        //         loadProfile(Details.playOff[k]);
        //         await setMatchInfoFilter(Details.playOff[k])
        //         break;
        //     }
        // }
        // }
        // });
        setLoaded(false)
    }, []);
    const onFinish = (values) => {
        setModelShowTxt(true)
        setValuess(values)
    };
    const onFinishRaw = () => {
        setModelShowRaw(true)
    }
    const handleClose = () => {
        setModelShowTxt(false)
        setModelShowRaw(false)
    };
    const validateScheduleJson = (jsonObj) => {

        let jsonSchema = SCHEDULE_SCHEMA;
        try {
            let jsonValidator = new Validator();
            if (jsonSchema) {
                jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
                    if (typeof instance !== "string") return;
                    if (schema.contains.indexOf(instance) < 0) {
                        return "does not contain the string " + JSON.stringify(schema.contains);
                    }
                };
                let _data = jsonObj || {};
                console.log(_data)
                let validate = jsonValidator.validate(_data, jsonSchema);
                if (validate.errors.length == 0) {
                    return null;
                }
                else {
                    return validate.errors;
                }
            }
        } catch (err) {
            return null;
        }
        return null;
    };

    const matchUpdate = async (editedObj) => {
        let finalObj = {
            "appId": `${currentTournamentDetails.shortCode}-ops`,
            "tournament": props.tournament,
            "season": props.season,
            "seasonType": props.seasonType,
            "data": editedObj
        };
        const response = await axios({
            method: 'post',
            url: `${domain}/betops/match/update-match/v2.0`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: finalObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: "Updated Successfully",
                    onOk() {
                        props.handleBack();
                        props.getMatches(props.tabType, props.tournament, props.season, props.seasonType);
                    },
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    }

    const submitTxtUpdate = async () => {
        setLoaded(true);
        setModelShowTxt(false);
        props.matchid.winProbHt = parseFloat(valuess.winProbHt);
        props.matchid.winProbRt = parseFloat(valuess.winProbRt);
        props.matchid.winMetric = valuess.winMetric;
        props.matchid.resRt = valuess.infoRt;
        props.matchid.resHt = valuess.infoHt;
        props.matchid.resultSummary = valuess.resultSummary;
        props.matchid.gameResult = gameresult;
        props.matchid.winner = winner;
        props.matchid.res = res;

        if (sDate) {
            props.matchid.startTs = sDate;
            props.matchid.matchTs = sDate;
        }

        if (eDate) {
            props.matchid.endTs = eDate;
        }

        let editedObj = props.matchid;

        matchUpdate(editedObj);
    }

    const changeLocalTime = (dateTime) => {
        return moment(dateTime);
    }

    const submitRawUpdate = async () => {
        setLoaded(true);
        setModelShowRaw(false)

        // let temp = matchInfoFilter;

        // temp.startdate = props.matchid.startdate;
        // temp.tyear = props.matchid.tyear;
        // temp.matchtitle = props.matchid.matchtitle;
        // temp.matchno = props.matchid.matchno;
        // temp.tournament = props.matchid.tournament;
        // temp.tournamenttitle = props.matchid.tournamenttitle;
        // temp.hticon = props.matchid.hticon;
        // temp.rticon = props.matchid.rticon;
        // temp.mtype = props.matchid.mtype;

        // temp.startTs = props.matchid.startTs;
        // temp.endTs = props.matchid.endTs;
        // temp.matchTs = props.matchid.matchTs;

        // let editedObj = props.matchid;

        // await setMatchInfoFilter(editedObj);
        matchUpdate(matchInfoFilter);

    }

    const handleChange = (e) => {
        let tempWinner = null;
        let tempRes = null;
        if (e == "ht") {
            setWinner(props.matchid.ht);
            setGameresult("WIN");
            tempWinner = props.matchid.ht;
            tempRes = 1;
        } else if (e == "rt") {
            setWinner(props.matchid.rt);
            setGameresult("LOSS");
            tempWinner = props.matchid.rt;
            tempRes = -1;
        } else if (e == "NOT YET MATCH STARTED") {
            setWinner("NOT YET MATCH STARTED");
            setGameresult("NOT YET MATCH STARTED");
            tempRes = 0;
        } else if (e == "TIE") {
            setWinner("TIE");
            setGameresult("TIE");
            tempRes = 2;
        } else if (e == "CANCELLED") {
            setWinner("CANCELLED");
            setGameresult("CANCELLED");
            tempRes = -2;
        } else if (e == "NOT YET RESULT UPDATED") {
            setWinner("NOT YET RESULT UPDATED");
            setGameresult("NOT YET RESULT UPDATED");
            tempRes = 0;
        }

        let map = null;
        if(e == "ht" || e == "rt") {
            map = `${tempWinner} (${tempRes})`;
        } else {
            map = `${e} (${tempRes})`;
        }
        setSelectedOption(map);
        setRes(tempRes);
    };

    const rawView = (arg) => {
        setRawViews(arg)
        setButtonColor(arg === true ? "Raw" : "Text")
        form.resetFields();
        loadProfile(matchInfoFilter);
    }

    const handleChanges = (e) => {
        setMatchInfoFilter(JSON.parse(e.target.value))
    }

    const sDateOk = (value) => {
        let final = value.format("YYYY-MM-DD HH:mm:ss");
        let final1 = new Date(final).toISOString();
        setSDate(final1);
    };

    const eDateOk = (value) => {
        let final = value.format("YYYY-MM-DD HH:mm:ss");
        let final1 = new Date(final).toISOString();
        setEDate(final1);
    }

    const getTeamLogo = async (val, roadTeam) => {
        let appId = null;
        if (currentTournamentDetails.shortName == 'NFL') {
            appId = "fc-web";
        } else {
            appId = "dt20-web";
        }
        // return `https://dream-t20-dev-api.web.app/betops/asset/get-by-name/v1?appId=dt20-web&assetType=team&assetName=LSG`;
        // return `${domain}/betops/asset/get-by-name/v1?appId=fc-web&assetType=team&assetName=${val}`;
        fetch(`${domain}/betops/asset/get-by-name/v1?appId=${appId}&assetType=team&assetName=${val}`, {
            method: "get"
        })
            // .then(response => response.blob())
            .then(data => {

                if (roadTeam) {
                    setRtTeamLogo(data.url);
                } else {
                    setHtTeamLogo(data.url);
                }


                // console.log(data.url);
                // return data.url;
                // var objectURL = URL.createObjectURL(data);
                // return objectURL;
            }).catch(error => {
            });
    }


    return <div>{matchInfo ? <>
        <table>
            <tr>
                <td>Tournament : {matchInfo.tournamenttitle}</td>
            </tr>
            <tr>
                <td>Match Date : {<Moment format="DD-MM-YYYY">
                    {props.matchid.matchTs}
                </Moment>}</td>
            </tr>
            <tr>
                <td>Match Number : {props.globalInfo.matchno}</td>
            </tr>
        </table>
        <Row gutter={12}>
            <Col span={12} offset={20}>
                <a href="#" style={buttonColor === "Text" ? { color: "blue", marginRight: "10px" } : { color: "gray", marginRight: "10px" }} onClick={() => rawView(false)}><TableOutlined /> <span>Text View</span></a>
                {" "}
                <a href="#" style={buttonColor !== "Text" ? { color: "blue" } : { color: "gray" }} onClick={() => rawView(true)}><CodepenOutlined /> <span>Raw View</span></a>
            </Col>
        </Row>
        {rawViews ? <> <Row gutter={24}>
            <Col span={24}>
                {/* <Form.Item> */}
                {/* <Input.TextArea value={JSON.stringify(matchInfoFilter, null, 4)} onChange={handleChanges} rows={20} /> */}
                <textArea style={{ width: "60%", marginTop: "10px", marginBottom: "10px" }} onChange={handleChanges} rows={20}>{JSON.stringify(matchInfoFilter, null, 4)}</textArea>
                {/* </Form.Item> */}
            </Col>
        </Row>
            {/* <Row gutter={12} className="rtmp1"> */}
                {/* <Col span={12}> */}
                    {/* <Form.Item shouldUpdate {...tailLayout}> */}
                        {/* {() => ( */}
                            <center><Button
                                onClick={onFinishRaw}
                                type="primary"
                                htmlType="submit"
                            >
                                Update
                            </Button></center>
                        {/* )} */}
                    {/* </Form.Item> */}
                {/* </Col> */}
            {/* </Row> */}
            </> : <Form form={form} className="form-align-cls" name="horizontal_login" layout="vertical" onFinish={onFinish}>
            <Row gutter={12}>
                <Col span={12}>
                    <Col span={7}>
                        <p>
                            <img src={rtTeamLogo} width={"25px"} /> <span className="teamname">{props.matchid.rt} </span> {" "}  <img src="images/visitor.svg"></img>
                        </p>
                    </Col>

                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="infoRt" label="Info" rules={[{ required: true, message: "Please input your Runs!" }]}>
                            <Input placeholder="Runs" />
                        </Form.Item>{" "}
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="winProbRt" label="Winning Probability" rules={[{ required: true, message: "Please input your Winning Probability!" }]}>
                            <Input placeholder="Probability" />
                        </Form.Item>{" "}
                    </Col>
                </Col>
                <Col span={12}>
                    <Col span={7}>
                        <p>
                            <img src={htTeamLogo} width={"25px"} /> <span className="teamname"> {props.matchid.ht}</span> {" "}  <img src="images/host.svg"></img>
                        </p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="infoHt" label="Info" rules={[{ required: true, message: "Please input your Runs!" }]}>
                            <Input type="text" />
                        </Form.Item>{" "}
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="winProbHt" label="Winning Probability" rules={[{ required: true, message: "Please input your Winning Probability!" }]}>
                            <Input placeholder="Probability" />
                        </Form.Item>{" "}
                    </Col>
                </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0" className="dividertext">
                <h4>Date Range</h4>
            </Divider>
            <Row gutter={12}>
                <Col span={12}>
                    <Col span={12}>
                        <Form.Item name="date-time-picker" label="Start Date">
                            <DatePicker
                                showTime
                                format="DD-MM-YYYY hh:mm a"
                                onOk={sDateOk}
                                defaultValue={changeLocalTime(props.matchid.startTs)}
                            />
                        </Form.Item>
                    </Col>
                </Col>
                <Col span={12}>
                    <Col span={12}>
                        <Form.Item name="date-time-picker1" label="End Date">
                            <DatePicker
                                showTime
                                format="DD-MM-YYYY hh:mm a"
                                onOk={eDateOk}
                                defaultValue={changeLocalTime(props.matchid.endTs)}
                            // defaultValue={props.matchid.endTs === "" ? undefined : moment(props.matchid.endTs, "YYYY-MM-DD HH:mm")}
                            />
                        </Form.Item>
                    </Col>
                </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0" className="dividertext">
                <h4>Match Results</h4>
            </Divider>
            <Row gutter={12}>
                <Col span={12}>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="winMetric" label="Winning Metric" rules={[{ required: true, message: "Please input your Metric!" }]}>
                            <Input placeholder="Metric" />
                        </Form.Item>{" "}
                    </Col>
                </Col>
                <Col span={12}>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item name="resultSummary" label="Results Summary" rules={[{ required: true, message: "Please input your Summary!" }]}>
                            <Input placeholder="Summary" />
                        </Form.Item>{" "}
                    </Col>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Col span={12}>
                        <p className="mainlabel">Win Result</p>
                        <Select value={selectedOption} style={{ width: "100%", marginBottom: "24px" }} onChange={(e) => handleChange(e)}>
                            <Option key="1" value="rt">
                                {props.matchid.rt} (-1)
                            </Option>
                            <Option key="2" value="ht">
                                {props.matchid.ht} (1)
                            </Option>
                            <Option key="3" value="NOT YET MATCH STARTED">
                                NOT YET MATCH STARTED (0)
                            </Option>
                            <Option key="4" value="TIE">
                                TIE (2)
                            </Option>
                            <Option key="5" value="CANCELLED">
                                CANCELLED (-2)
                            </Option>
                            <Option key="6" value="NOT YET RESULT UPDATED">
                                NOT YET RESULT UPDATED (0)
                            </Option>
                        </Select>{" "}
                    </Col>
                </Col>
            </Row>
            {/* <Row gutter={24} className="rtmp1"> */}
            {/* <Col span={24}> */}
            {/* <Form.Item shouldUpdate {...tailLayout}> */}
            {/* {() => ( */}
            <center><Button
                type="primary"
                htmlType="submit"
            >
                Update
            </Button></center>
            {/* )} */}
            {/* </Form.Item> */}
            {/* </Col> */}
            {/* </Row> */}
        </Form>}
        <Modal title="Update Match Info" onCancel={handleClose} visible={modelShowTxt}
            footer={[
                // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                //   Notify All Players
                // </Checkbox>,
                <Button key="submit" type="primary" onClick={handleClose}>
                    No
                </Button>,
                <Button
                    type="primary"
                    onClick={submitTxtUpdate}
                >
                    Yes
                </Button>
            ]}

        >
            Would you like to save changes?
        </Modal>
        <Modal title="Update Match Info" onCancel={handleClose} visible={modelShowRaw}
            footer={[
                // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                //   Notify All Players
                // </Checkbox>,
                <Button key="submit" type="primary" onClick={handleClose}>
                    No
                </Button>,
                <Button
                    type="primary"
                    onClick={submitRawUpdate}
                >
                    Yes
                </Button>
            ]}

        >
            Would you like to save changes?
        </Modal>
    </> : ""
    }
        {
            loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )
        }

    </div >
}
export default MatchTabView;