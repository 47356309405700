import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { Select, Spin, Switch, Layout, Checkbox, Menu, Card, Row, Col, Table, Tag, Space, Button, Modal, Drawer, Form, Input, Divider } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
  DeleteTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
function Addbets(props) {
  const [loaded, setLoaded] = useState(false);
  const [addform] = Form.useForm();
  const [form] = Form.useForm();
  const [result, setResult] = useState([]);
  const [hedgebet, setHedgebet] = useState(false);
  const [betvalue, setBetvalue] = useState();
  const [matchid, setMatchid] = useState();
  const [matchinfo, setMatchinfo] = useState();
  const [drawertitle, setDrawertitle] = useState();
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [tournament, setTournament] = useState();
  const [notify, setNotify] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [season, setSeason] = useState();
  const [formvalues, setFormvalues] = useState();
  const onSave = async (values) => {
    setLoaded(true);

    const sideList = [];
    console.log(result.length + 1);
    console.log(result);
    var sidenumcheck = Number(result.length) + 1;
    let probcheck = {};
    if (sidenumcheck == 1) {
      probcheck = { winProbRt: "" };
    } else {
      probcheck.winProbHt = "";
    }

    var newbetID = tournament + ":" + matchid + ":" + betvalue + ":" + sidenumcheck + ":" + 1;
    let checkarray = {
      betId: newbetID,
      bet: Number(values.bet),
      win: Number(values.win),
      sideNum: sidenumcheck,
      odds: Number(values.odds),
      betResult: values.winresult == "WIN" ? 1 : -1,
      sideLabel: values.sideLabel,
      hedgeBet: hedgebet,
    };
    sideList.push(...result);
    sideList.push(checkarray);
    setResult(sideList);
    setLoaded(false);
    setChildrenDrawer(false);
  };
  const showDrawer = () => {
    addform.setFieldsValue({
      sideLabel: "",
      odds: "",
      bet: "",
      win: "",
    });
    setChildrenDrawer(true);
  };
  const notifycheck = (e) => {
    setNotify(e.target.checked);
  };
  const onHedge = (checked) => {
    console.log(checked);
    setHedgebet(checked);
  };
  const onClose = () => {
    addform.setFieldsValue({
      sideLabel: "",
      odds: "",
      bet: "",
      win: "",
    });
    setChildrenDrawer(false);
  };
  const numbercheck = (e) => {
    console.log(e);
  };
  const handleOk = async () => {
    // const matchdata = props.teaminfo
    //   .filter(function (item) {
    //     return item.tournament == tournament;
    //   })
    //   .map(function (item) {
    //     return item;
    //   });
    // var rticon = "";
    // var hticon = "";
    // matchdata.map(function (item) {
    //   item.teams.map(function (itemcheck) {
    //     if (itemcheck.name == props.matchdetails[0].rt) {
    //       rticon = itemcheck.ic;
    //     }
    //     if (itemcheck.name == props.matchdetails[0].ht) {
    //       hticon = itemcheck.ic;
    //     }
    //   });
    // });
    setIsModalVisible(false);
    const title = "Are you sure to resolve bets of " + tournament + " (" + matchid + ") ?";
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    var currentTimeInMilliseconds = Date.now();
    if (notify == true) {

      // const res = await setDoc(doc(db, "notification", tournament + "_" + matchid + "_MAKE_BET_" + currentTimeInMilliseconds), {
      //   tournament: tournament,
      //   notifId: tournament + "_" + matchid + "_MAKE_BET_" + currentTimeInMilliseconds,
      //   matchId: matchid,
      //   cards: [
      //     {
      //       title: props.matchdetails[0].ht,
      //       icon: hticon,
      //       status: "LOSS",
      //     },
      //     {
      //       title: props.matchdetails[0].rt,
      //       icon: rticon,
      //       status: "WIN",
      //     },
      //   ],
      //   channelId: `com.sherpaanalytics.${currentTournamentDetails.majorUsedDocId}.match.bet`,
      //   season: season,
      //   title: "Checkout the custom bets available for Match " + props.matchdetails[0].matchno,
      //   channelName: "Match Bet",
      //   type: "MAKE_BET",
      //   betMode: formvalues.betMode,
      //   model: "",
      //   desc: "New CustomBets available",
      //   topic: "match-bet",
      // });
    }

    let mainarray = [];
    mainarray.push(props.specificMatchBets);
    mainarray[0].bets.betList.push({
      betLegend: formvalues.betLegend,
      subLabel: formvalues.subLabel,
      betMode: formvalues.betMode,
      betNum: formvalues.betNum,
      winnerActual: "",
      winnerActualFavOrDog: "",
      betType: "PreGame",
      oddsVersion: 1,
      sideList: result,
    });
    let obj = {};
    mainarray.forEach(function (val, i) {
      obj = {
        val,
      };
    });
    setLoaded(true);
    let infoObj = obj.val;
    infoObj.appId = `${currentTournamentDetails.shortCode}-ops`;
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/bet/update/v2.0`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
      data: infoObj
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        Modal.success({
          content: response.data.data.message,
          onOk() {
            setResult([]);
            form.resetFields();
            addform.resetFields();
            props.onClose();
            props.updateMatchdetails();
          }
        });
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };
  const deleterecord = (betid) => {
    console.log(betid);
    const final = result.filter((number) => {
      return number.betId !== betid.betId;
    });
    setResult(final);
  };
  const handleCancel = () => {
    setLoaded(false);
    setIsModalVisible(false);
    setLoaded(false);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  const loadProfile = () => {
    var tournament = [];
    var tyear = [];
    var tmatchid = [];
    var mtitle = [];

    props.matchdetails.map((item, i) => {
      tournament.push(item.tournament);
      tyear.push(item.year);
      tmatchid.push(item.matchid);
      mtitle.push(item.matchtitle);
    });
    const arr1 = getUniqueListBy(tournament);
    const arr2 = getUniqueListBy(tyear);
    const arr3 = getUniqueListBy(tmatchid);
    const arr5 = getUniqueListBy(mtitle);
    setTournament(arr1[0]);
    setSeason(arr2[0]);
    setMatchid(arr3[0]);
    setMatchinfo(arr5[0]);
    setBetvalue(props.matchdetails.length > 0 ? Number(props.matchdetails.at(-1).betNum) + 1 : 1);
    form.setFieldsValue({
      betLegend: "",
      subLabel: "",
      tournament: "",
      season: "",
      betNum: props.matchdetails.length > 0 ? Number(props.matchdetails.at(-1).betNum) + 1 : 1,
    });
  };
  useEffect(() => {
    loadProfile();
  }, []);

  const onFinish = async (values) => {
    setFormvalues(values);
    setLoaded(true);
    setIsModalVisible(true);
  };

  return (
    <>
      <Divider orientation="left" orientationMargin="0" className="dividertext">
        <h4>Bet Info - Main</h4>
      </Divider>
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Number</p>
            <Form.Item name="betNum" label="" rules={[{ required: true, message: "Please input your betno!" }]}>
              <Input type="text" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Tournament</p>
            <Form.Item name="tournament" label="">
              <Input placeholder={tournament} disabled={true} />
            </Form.Item>{" "}
          </Col>

          <Col span={12} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Season</p>
            <Form.Item name="season" label="">
              <Input placeholder={season} disabled={true} />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={10} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Label</p>
            <Form.Item name="subLabel" label="" rules={[{ required: true, message: "Please input your Bet Label!" }]}>
              <Input />
            </Form.Item>{" "}
          </Col>

          <Col span={6} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Legend</p>
            <Form.Item name="betLegend" label="" rules={[{ required: true, message: "Please input your Bet Legend!" }]}>
              <Input />
            </Form.Item>{" "}
          </Col>
          <Col span={8} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Mode</p>
            <Form.Item name="betMode" label="" rules={[{ required: true, message: "Please Select Bet Mode!" }]}>
              <Select defaultValue="Select Bet Mode" style={{ width: "100%" }}>
                {props.betmode.map((bvalue, j) => (
                  <Option key={j} value={bvalue}>
                    {bvalue}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <div>
          <span className="addsidebet">
            <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
            <a onClick={() => showDrawer()}>
              <span className="addschedules">Add Side</span>
            </a>
          </span>
          <Table locale="No Data" dataSource={result} pagination={{ pageSize: 10 }} scroll={{ y: "250" }}>
            <Column title="Bet ID" dataIndex="betId" key="betId" sorter="true" />
            <Column title="Side Label" dataIndex="sideLabel" key="sideLabel" />
            <Column
              title="Bet Results"
              key="betResult"
              dataIndex="betResult"
              render={(text, record) => (
                <Space size="middle">
                  <span className={text == 1 ? "winlabel" : "losslabel"}>{text == 1 ? "WIN" : "LOSS"}</span>
                </Space>
              )}
            />
            <Column
              title="Quick Actions"
              key="betId"
              render={(record) => (
                <Space size="middle">
                  <DeleteTwoTone onClick={() => deleterecord(record)} style={{ fontSize: "18px", color: "#08c" }} />
                </Space>
              )}
            />
          </Table>
        </div>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item shouldUpdate {...tailLayout}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                >
                  Save
                </Button>
              )}
            </Form.Item>
          </Col>
          {/* <Col span={14}>
            <Checkbox onChange={notifycheck} checked={notify}>
              Notify All Players
            </Checkbox>
          </Col> */}
        </Row>
      </Form>
      <Drawer title="Add Side" width={320} closable={true} onClose={onClose} visible={childrenDrawer}>
        <Form form={addform} name="horizontal_login" layout="vertical" onFinish={onSave}>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left", paddingTop: "20px" }}>
              <p className="mainlabel"> Side Label</p>
              <Form.Item name="sideLabel" label="" rules={[{ required: true, message: "Please input your Side label!" }]}>
                <Input placeholder="Side label" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <p className="mainlabel"> Bet Odds</p>
              <Form.Item
                name="odds"
                label=""
                rules={[
                  { required: true, message: "Please input your Bet odds!" },
                  {
                    pattern: "^(-?[1-9]+\\d*([.]\\d+)?)$|^(-?0[.]\\d*[1-9]+)$|^0$",
                    message: "Please enter only number",
                  },
                ]}
              >
                <Input placeholder="Bet odds" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <p className="mainlabel"> Bet</p>
              <Form.Item
                name="bet"
                label=""
                rules={[
                  { required: true, message: "Please input your Bet!" },
                  {
                    pattern: "^(-?[1-9]+\\d*([.]\\d+)?)$|^(-?0[.]\\d*[1-9]+)$|^0$",
                    message: "Please enter only number",
                  },
                ]}
              >
                <Input placeholder="Bet" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <p className="mainlabel"> Win</p>
              <Form.Item
                name="win"
                label=""
                rules={[
                  { required: true, message: "Please input your Win!" },
                  {
                    pattern: "^(-?[1-9]+\\d*([.]\\d+)?)$|^(-?0[.]\\d*[1-9]+)$|^0$",
                    message: "Please enter only number",
                  },
                ]}
              >
                <Input placeholder="Win" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="winresult" label="Select Winning Result" rules={[{ required: true, message: "Please Select Winning Result!" }]}>
                <Select style={{ width: "100%" }}>
                  {props.winningarray.map((item1, j) => (
                    <Option key={j} value={item1}>
                      {item1}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="rtmp1">
            <Col span={24} style={{ textAlign: "left" }}>
              <p className="mainlabel">Hedge Bet</p>
              <Switch defaultChecked={hedgebet} onChange={onHedge} />
            </Col>
          </Row>
          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Add Side
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        Would you like to Add New Bet of {tournament} - {season} {matchinfo}
      </Modal>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Addbets;
