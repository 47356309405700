import { Spin, Layout, Input, Card, Table, Space, Button, Modal, PageHeader, Form, Tooltip, Col, Row, Drawer, InputNumber, DatePicker, Popover } from "antd";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails, logout } from "../../settings/config";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
//import "antd/dist/antd.min.css"
import "antd/dist/antd.min.css";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import axios from "axios";
import moment from "moment";
import {
    PlusCircleTwoTone,
    DeleteTwoTone,
    LoginOutlined,
    DeleteOutlined,
    EditTwoTone,
    CopyOutlined,
    EyeOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../../configuration/ApiConfiguration";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
function Service() {
    const [form] = Form.useForm();
    const [ID, setID] = useState("");
    const [rowInfo, setRowInfo] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [StatusVisible, setStatusVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [DeleteVisible, setDeleteVisible] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [keyData, setKeyData] = useState(null);
    const [sportsConfig, setSportsConfig] = useState(null);
    const [tempServiceList, setTempServiceList] = useState([]);
    const [rowDetails, setRowDetails] = useState([]);
    const [newKeys, setNewKeys] = useState(null);
    const [drawertype, setDrawertype] = useState("");
    const addnew = () => {
        form.resetFields();
        setDrawertype("New Service");
        setVisible(true);
    };
    const showDrawer = (rowInfo, index1) => {
        setNewKeys(rowInfo);
        form.setFieldsValue({
            name: rowInfo.name,
            limit: rowInfo.limit,
            expire: changeLocalTime(rowInfo.expire)
        });

        setRowDetails(rowInfo);
        setDrawertype("Edit Service");
        setVisible(true);
    };

    const changeLocalTime = (dateTime) => {
        let temp = new Date(dateTime._seconds * 1000);
        let final1 = new Date(temp).toISOString();
        return moment(final1);
    }

    const statusTheService = async () => {
        setStatusVisible(false);
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/service/toggle_status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&externalId=${rowInfo.externalId}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            onClose();
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        fetchDashboard();
                    }
                });
            }
            else if (response.data.status === "FAIL") {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
            else {
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
        setLoaded(false);
    }

    const changeStatus = async (rowData) => {
        setRowInfo(rowData)
        setStatusVisible(true);
    };

    const onClose = () => {
        setDrawertype("");
        setID("")
        setDeleteVisible(false);
        setStatusVisible(false);
        setRowInfo("")
        form.resetFields();
        setVisible(false);
        setNewKeys(null);
    };

    const onSearch = (e) => {
        if (e.target.value !== "") {
            let filteredData = tempServiceList.find(obj => `${obj.externalId}` === e.target.value || `${obj.name}` === e.target.value);
            let tempArr = [];
            tempArr.push(filteredData);
            if (tempArr[0].externalId) {
                setServiceList(tempArr);
            }
        } else {
            fetchDashboard();
        }
    };

    const makeShortServiceId = (serviceId) => {
        var value = serviceId;
        var firstFour = value.substr(0, 4);
        var lastFour = value.substr(value.length - 4);
        return (
            <>
                <span style={{ color: "rgb(0, 136, 204)" }}>{firstFour}</span>xx-xx<span style={{ color: "rgb(0, 136, 204)" }}>{lastFour}</span>
            </>
        );
    }

    const showContent = (notifId) => {

    };

    const onFinish = async (values) => {
        setLoaded(true);
        let expiredate = values.expire.format("YYYY-MM-DD HH:mm:ss");
        let finaldate = new Date(expiredate).toISOString();

        let finalObj = null;
        let dataurl = null;
        if (drawertype === "New Service") {
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "name": values.name,
                "limit": parseInt(values.limit),
                "expire": finaldate
            };
            dataurl = `${domain}/betops/service/create/v2.0`;
        } else {
            finalObj = {
                "appId": `${currentTournamentDetails.shortCode}-ops`,
                "name": values.name,
                "limit": parseInt(values.limit),
                "expire": finaldate,
                "externalId": rowDetails.externalId
            };
            dataurl = `${domain}/betops/service/update/v2.0`;
        }

        const response = await axios({
            method: 'post',
            url: dataurl,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
            data: finalObj
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data.message,
                    onOk() {
                        fetchDashboard();
                        if (drawertype == "Edit Service") {
                            onClose();
                            fetchDashboard();
                        } else {
                            setNewKeys(response.data.data);
                            fetchDashboard();
                        }
                    },
                });
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    }

    const fetchDashboard = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/service/get-all/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setTempServiceList(response.data.data.service_list);
                setServiceList(response.data.data.service_list);
                // setKeyData(response.data.data.keyData);
                // setSportsConfig(response.data.data.sportConfig);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        fetchDashboard();
    }, [user, loading]);

    useEffect(async () => {
    }, [loaded]);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="api_service" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>

                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header servicepageheader"
                                    title="Api Service Management"
                                    extra={
                                        <>
                                            <Space direction="horizontal">
                                                <Search placeholder="Search by Name or External Id" onChange={onSearch} enterButton />
                                                <a onClick={() => addnew()}>
                                                    <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                </a>
                                            </Space>
                                        </>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={serviceList}
                                    scroll={{ y: "340" }}
                                >
                                    <Column title="Name" dataIndex="name" key="name" />
                                    <Column title="External Id" dataIndex="externalId" key="externalId"
                                        render={(text, record) => (
                                            <>
                                                {/* <center>  */}
                                                <CopyToClipboard text={text}>
                                                    <div className="clonediv">
                                                        <Tooltip title="click to copy">
                                                            <span style={{ cursor: "pointer" }}>
                                                                {makeShortServiceId(text)}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </CopyToClipboard>

                                                {/* <Popover
                                                        content={() => showContent(text)}
                                                        trigger="hover"
                                                        placement="bottom"
                                                    >
                                                        <EyeOutlined style={{ fontSize: "18px", color: "#008959", cursor: "pointer" }} />
                                                    </Popover></center> */}



                                            </>
                                        )}
                                    />
                                    <Column title="Limit" dataIndex="limit" key="limit" />
                                    <Column title="Api Key" dataIndex="apiKey" key="apiKey"
                                        render={(text, record) => (
                                            <>
                                                {/* <center> */}
                                                <CopyToClipboard text={text}>
                                                    <div className="clonediv">
                                                        <Tooltip title="click to copy">
                                                            <span style={{ cursor: "pointer" }}>
                                                                {makeShortServiceId(text)}

                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </CopyToClipboard>
                                                {/* <EyeOutlined style={{ fontSize: "18px", color: "#008959", cursor: "pointer" }} /></center> */}
                                            </>
                                        )}
                                    />
                                    <Column title="Secret Key" dataIndex="secret" key="secret"
                                        render={(text, record) => (
                                            <>
                                                {/* <center> */}
                                                <CopyToClipboard text={text}>
                                                    <div className="clonediv">
                                                        <Tooltip title="click to copy">
                                                            <span style={{ cursor: "pointer" }}>
                                                                {makeShortServiceId(text)}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </CopyToClipboard>
                                                {/* <EyeOutlined style={{ fontSize: "18px", color: "#008959", cursor: "pointer" }} /></center> */}
                                            </>
                                        )}
                                    />
                                    <Column
                                        title="Expire Date"
                                        dataIndex="expire"
                                        key="expire"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text && text !== undefined ? (
                                                    <Moment unix format="DD-MM-YYYY hh:mm a">
                                                        {text._seconds}
                                                    </Moment>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                    <Column title="Status" dataIndex="active" key="active"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text == true ? (
                                                    <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                                ) : (
                                                    <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                                )}
                                            </Space>
                                        )}
                                    />
                                    <Column
                                        title="Quick Actions"
                                        key="documentID"
                                        render={(documentID, value) => (
                                            <Space size="middle">
                                                <LoginOutlined onClick={() => changeStatus(documentID)} title="Change Service Status" style={{ fontSize: "18px", color: "#08c" }} />
                                                <EditTwoTone onClick={() => showDrawer(documentID)} style={{ fontSize: "18px", color: "#08c" }} />
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>

                        <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={statusTheService}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>
                            ]}
                        >
                            Are you sure you want to Change the Service Status?
                        </Modal>

                        <Drawer title={drawertype} placement="right" onClose={onClose} visible={visible} size="small">
                            <Form style={{ marginTop: "10px" }} className="form-align-cls" form={form} layout="vertical" onFinish={onFinish}>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[{ required: true, message: 'Please input the name!' }]}
                                        >
                                            <Input placeholder="Enter Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            name="limit"
                                            label="Limit"
                                            rules={[{ required: true, message: 'Please input your limit!' }]}
                                        >
                                            <InputNumber style={{ width: "100%" }} placeholder="Enter Limit" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item name="expire" label="Expire Date" rules={[{ required: true, message: "Please select expire date!" }]}>
                                            <DatePicker
                                                showTime
                                                format="DD-MM-YYYY hh:mm a"
                                            />
                                        </Form.Item>
                                    </Col>

                                    {drawertype == "Edit Service" ?
                                        <Col span={24}>
                                            <Form.Item>
                                                <center><Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Update
                                                </Button></center>
                                            </Form.Item>
                                        </Col>
                                        : ""}

                                    {newKeys == null ?
                                        <Col span={24}>
                                            <Form.Item>

                                                <center><Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Save
                                                </Button></center>
                                            </Form.Item>

                                        </Col>
                                        : <Col span={24} style={{ marginTop: "10px" }}>
                                            <div style={{ padding: "0 12px 0 12px", border: "1px solid #e3e3e3", position: "relative" }}>
                                                <h6 style={{ color: "rgb(0, 136, 204)", marginTop: "1rem" }}>Api Key</h6>
                                                <p style={{ wordWrap: "break-word" }}>{newKeys.apiKey}</p>
                                                <CopyToClipboard text={newKeys.apiKey}>
                                                    <CopyOutlined style={{ cursor: "pointer", position: "absolute", top: "16px", right: "12px", fontSize: "20px", color: "rgb(0, 136, 204)" }} />
                                                </CopyToClipboard>
                                            </div>
                                            <div style={{ padding: "0 12px 0 12px", border: "1px solid #e3e3e3", marginTop: "10px", position: "relative" }}>
                                                <h6 style={{ color: "rgb(0, 136, 204)", marginTop: "1rem" }}>Secret Key</h6>
                                                <p style={{ wordWrap: "break-word" }}>{newKeys.secret}</p>
                                                <CopyToClipboard text={newKeys.secret}>
                                                    <CopyOutlined style={{ cursor: "pointer", position: "absolute", top: "16px", right: "12px", fontSize: "20px", color: "rgb(0, 136, 204)" }} />
                                                </CopyToClipboard>
                                            </div>
                                            <div style={{ padding: "0 12px 0 12px", border: "1px solid #e3e3e3", marginTop: "10px", position: "relative" }}>
                                                <h6 style={{ color: "rgb(0, 136, 204)", marginTop: "1rem" }}>External Id</h6>
                                                <p style={{ wordWrap: "break-word" }}>{newKeys.externalId}</p>
                                                <CopyToClipboard text={newKeys.externalId}>
                                                    <CopyOutlined style={{ cursor: "pointer", position: "absolute", top: "16px", right: "12px", fontSize: "20px", color: "rgb(0, 136, 204)" }} />
                                                </CopyToClipboard>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </Form>


                        </Drawer>

                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}

                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Service;
