import { collection, getDocs } from "firebase/firestore";
import { Select, Layout, Card, Col, Input, Table, Tag, Space, Drawer, PageHeader, Spin, List, Modal } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import localStore from "../utils/services";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function BetTransactions() {
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);

    //const [scheduleData, setScheduleData] = useState([]);
    const [transactionsData, setTransactionsData] = useState([]);
    const [transactionsBet, setTransactionsBet] = useState([]);
    const [transactionsDataDup, setTransactionsDetailsDup] = useState([]);
    const [tournamentValue, setTournamentsValue] = useState(currentTournamentDetails.shortName);
    const [seasonyearValue, setSeasonyearValue] = useState(2023);
    const [seasontypeValue, setSeasontypeValue] = useState("REGULAR");

    //const [isModalVisible, setIsModalVisible] = useState(false);
    //const [modaltitle, setModaltitle] = useState("");

    const [dbTournamentList, setDbTournamentList] = useState([]);
    const [dbSeasonList, setDbSeasonList] = useState([]);
    const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

    const [playersSquad, setPlayersSquad] = useState([]);
    const [matchPlayers, setMatchPlayers] = useState([]);

    const [transactionsDetails, setTransactionsDetails] = useState([]);

    const showDrawer = (matchid) => {
        setTransactionsBet(matchid.bets);
        setVisible(true);
    };

    const sportConfigSetting = () => {
        let config11 = localStorage.getItem("sportsList")
        let config12 = config11.split(",");
        setDbTournamentList(config12);
    
        let config21 = localStorage.getItem("sportYearsList")
        let config22 = config21.split(",");
        setDbSeasonList(config22);

        let config31 = localStorage.getItem("sportSeasonsList")
        let config32 = config31.split(",");
        setDbSeasonTypeList(config32);
      }

    const docDownload = (doc) => {
        if (doc.oldEndTs) {
            localStorage.setItem("oldEndTs", JSON.stringify(doc.oldEndTs));
            doc.endTs = JSON.parse(localStorage.getItem("oldEndTs"));
            delete doc.key;
            delete doc.oldEndTs;
            const json = JSON.stringify(doc);
            const blob = new Blob([json], { type: "application/json" });
            const a = document.createElement("a");
            a.download = doc.transId;
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
            doc.oldEndTs = JSON.parse(localStorage.getItem("oldEndTs"));
            let _time = String(doc.oldEndTs.seconds).padEnd(13, "0");
            doc.endTs = String(new Date(Number(_time)));
        } else {
            delete doc.key;
            const json = JSON.stringify(doc);
            const blob = new Blob([json], { type: "application/json" });
            const a = document.createElement("a");
            a.download = doc.transId;
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
        }
    }

    const fetchTransactionsDetails = async (tournamentValue1, seasonyearValue1, seasontypeValue1) => {
        // const _snapshot = await getDocs(_transactions);
        // const _resultArray = [];
        // let _tournaments = [];
        // let _year = [];
        // _snapshot.docs.forEach(function (item, index) {
        //     let _data = item.data();
        //     let _obj = { ..._data };
        //     if (_data && _data.matchId && _data.endTs) {
        //         _obj.oldEndTs = _data.endTs;
        //         let _time = String(_data.endTs.seconds).padEnd(13, "0");
        //         _obj.endTs = String(new Date(Number(_time)));
        //         _obj.key = index;
        //         _resultArray.push(_obj);

        //         if (_year.indexOf(_obj.year) === -1) _year.push(_obj.year);
        //         if (_tournaments.indexOf(_obj.tournament) === -1) _tournaments.push(_obj.tournament);
        //     } else {
        //         _obj.key = index;
        //         _resultArray.push(_obj);

        //         if (_year.indexOf(_obj.year) === -1) _year.push(_obj.year);
        //         if (_tournaments.indexOf(_obj.tournament) === -1) _tournaments.push(_obj.tournament);
        //     }
        // });
        // if (_tournaments.length > 1) {
        //     _tournaments = ["All Tournaments", ..._tournaments];
        // }
        // setDbTournamentList(_tournaments);
        // var filtered = _year.filter(function (x) {
        //     return x !== undefined;
        // });
        // setDbSeasonList(filtered);
        // setTransactionsData(_resultArray);
        // setTransactionsDetails(_resultArray);
        // setTransactionsDetailsDup(_resultArray);
        // setTournamentsValue("All Tournaments");
        // setSeasonyearValue("All Years");
        // setLoaded(false)


        const response = await axios({
            method: 'get',
            url: `${domain}/betops/bet/trans-list/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tournamentValue1}&season=${seasonyearValue1}&seasonType=${seasontypeValue1}`,
            headers: {"Authorization" : `Bearer ${localStorage.getItem("aT")}`}
          }).then((response) => {
            if (response.data.status === "SUCCESS") {
            setTransactionsData(response.data.data.transList);
            setTransactionsDetails(response.data.data.transList);
            setTransactionsDetailsDup(response.data.data.transList);
            }
            else {
              if(response.data.error.message == "Auth token is not valid") {
                Modal.error({
                  content: response.data.error.message,
                  onOk() {
                    logout();
                  }
                });
              } else {
                Modal.error({
                  content: response.data.error.message
                });
              }
            }
          })
            .catch((error) => {
              Modal.error({
                content: "Unable to process your Request, Please try again later."
              });
            })
          setLoaded(false);
    };


    const handleTournament = (e) => {
        if (e) {
            setTournamentsValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == e;
            });
            setTransactionsDetails(_filteredData);
            fetchTransactionsDetails(e, seasonyearValue, seasontypeValue);
            // let _year = [];
            // _filteredData.forEach(function (item) {
            //     if (_year.indexOf(item.year) === -1) {
            //         _year.push(item.year);
            //     }
            // });
            // if (_year.length > 1) {
            //     _year = ["All Years", ..._year];
            // }
            // var filtered = _year.filter(function (x) {
            //     return x !== undefined;
            // });
            // setDbSeasonList(filtered);
            // setSeasonyearValue(_year[0]);
        }
    };
    const handleSeasonYear = (e) => {
        if (e) {
            setSeasonyearValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == tournamentValue && item.season == e && item.seasonType == seasonyearValue;
            });
            setTransactionsDetails(_filteredData);
            fetchTransactionsDetails(tournamentValue, e, seasontypeValue);
        }
    };

    const handleSeasonType = (e) => {
        if (e) {
            setSeasontypeValue(e);
            const _filteredData = transactionsData.filter(function (item) {
                return item.tournament == tournamentValue && item.season == seasonyearValue && item.seasonType == e;
            });
            setTransactionsDetails(_filteredData);
            fetchTransactionsDetails(tournamentValue, seasonyearValue, e);
        }
    };

    const frameMatchPlayers = (obj) => {
        let _playersList = [];
        for (let i = 0; i < obj.playerlist.length; i++) {
            let _playersLists = playersSquad.filter((list) => list.mIdPId == obj.playerlist[i].mIdPId)
            if (_playersLists.length > 0) {
                _playersList.push(_playersLists[0])
            }
        }
        setMatchPlayers(_playersList);
    }
    const sortArray = (type) => {
        const types = {
            matchId: "matchId",
        };
        const sortProperty = types[type];
        const sorted = [...transactionsDetails].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });
        setTransactionsDetails(sorted);
    };
    const handleTableChange = (pagination, filters, sorter) => {
        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setTransactionsDetails(transactionsDetails.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setTransactionsDetails(transactionsDetails.reverse());
        }
    };
    const fetchSearch = async () => {
            const filteredData = transactionsDataDup
                .filter(function (item) {
                    return item.tournament == tournamentValue && item.season == seasonyearValue && item.seasonType == seasontypeValue;
                })
                .map(function (item) {
                    return item;
                });
            setTransactionsDetails(filteredData);
    };

    const onSearch = (e) => {

        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = transactionsDataDup.filter((item) => {
                return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
            });
            setTransactionsDetails(filteredData);
        } else {
            fetchSearch();
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);

    useEffect(async () => {
        let _config = await localStore.getItem();
        fetchTransactionsDetails(tournamentValue, seasonyearValue, seasontypeValue);
        sportConfigSetting();
        // fetchSchedule();
    }, [loaded]);

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="bet_transactions" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Bet Transactions"
                                    extra={
                                        <Space key="p1" direction="horizontal">
                                            <Search placeholder="Search by UID" onChange={onSearch} enterButton />{" "}
                                            <Select size="default" value={tournamentValue} style={{ width: "200px" }} onChange={(e) => handleTournament(e)}>
                                                {dbTournamentList.map((item, index) => (
                                                    <Option key={index} value={item}>
                                                        {item}{" "}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select size="default" value={seasonyearValue} style={{ width: "200px" }} onChange={(e) => handleSeasonYear(e)}>
                                                {dbSeasonList.map((name, pos) => {
                                                    return (
                                                        <Option key={pos} value={name}>
                                                            {name}{" "}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            <Select size="default" value={seasontypeValue} style={{ width: "200px" }} onChange={(e) => handleSeasonType(e)}>
                                                {dbSeasonTypeList.map((name, pos) => {
                                                    return (
                                                        <Option key={pos} value={name}>
                                                            {name}{" "}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Space>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={transactionsDetails}
                                    onChange={handleTableChange}
                                    scroll={{ y: "250" }}
                                >
                                    <Column title="Match ID" dataIndex="matchId" key="matchid" sorter="true" />
                                    <Column title="Tournament" dataIndex="tournament" key="tournament" sorter="true" />
                                    <Column title="Season" dataIndex="season" key="season" sorter="true" />
                                    <Column title="Season Type" dataIndex="seasonType" key="seasonType" sorter="true" />
                                    <Column title="UID" dataIndex="uid" key="uid" sorter="true" />
                                    <Column
                                        title="Quick Actions"
                                        key="matchId"
                                        render={(matchId, index) => (
                                            <Space key={index} size="middle">
                                                {matchId !== undefined ? (
                                                    <>
                                                        <a onClick={() => showDrawer(matchId)}>
                                                            <EyeOutlined style={{ fontSize: "18px", color: "#08c" }} />
                                                            {/* <span style={{ marginLeft: "5px", position: "relative", top: "2px" }}>Bets</span> */}
                                                        </a>

                                                        <a onClick={() => docDownload(matchId)}>
                                                            <DownloadOutlined style={{ fontSize: "18px", color: "#08c" }} />
                                                            <span style={{ marginLeft: "5px", position: "relative", top: "3px" }}>Download</span>
                                                        </a>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>

                        <Drawer title="Bet List" placement="right" onClose={onClose} visible={visible} size="large">

                            <Table locale="No Data" dataSource={transactionsBet} pagination={{ pageSize: 10 }} scroll={{ y: "250" }}>
                                <Column title="Bet ID" dataIndex="betId" key="betId" sorter="true" />
                                <Column title="Side Label" dataIndex="side" key="side" />
                                <Column
                                    title="Bet Results"
                                    key="result"
                                    dataIndex="result"
                                    render={(text, record) => (
                                        <Space size="middle">
                                            <span className={text == 1 ? "winlabel" : "losslabel"}>{text == 1 ? "WIN" : "LOSS"}</span>
                                        </Space>
                                    )}
                                />
                            </Table>
                        </Drawer>

                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default BetTransactions;
